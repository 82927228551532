const schema = {
  'traits.email': {
    type: 'string',
    required: true,
    label: 'Email'
  },
  'traits.username': {
    type: 'string',
    required: true,
    label: 'Username'
  },
  'email': {
    type: 'string',
    required: true,
    label: 'Email'
  },
  'identifier': {
    type: 'string',
    required: true,
    label: 'Email or Username'
  },
  'password': {
    required: true,
    minLength: 8,
    label: 'Password',
  },
  'traits.confirm_password': {
    required: true,
    minLength: 8,
    label: 'Confirm Password',
    compareWith: {
      field: 'password',
      title: 'Password'
    },
  },
  'traits.first_name': {
    required: true,
    type: 'string',
    label: 'First Name',
  },

  'traits.last_name': {
    required: true,
    type: 'string',
    label: 'Last Name',
  },

  'traits.display_name': {
    required: false,
    type: 'string',
    label: 'Diaplay Name'
  },

  'traits.city': {
    required: true,
    type: 'string',
    label: 'City',
    nullIdentifier: 'Select your city'
  },

  'traits.phone': {
    required: true,
    type: 'number',
    label: 'Phone'
  },

  'traits.birth_date': {
    required: true,
    label: 'Date of Birth'
  },
  'traits.picture': {
    required: false,
    label: 'Picture',
    type: 'string',
    skip: true,
  }

};


export const generateSchema = (fields = []) => {
  const buildSchema = {};
  fields.forEach(field => {
    if (schema[field]) {
      buildSchema[field] = schema[field];
    }
  })
  return buildSchema;
}

export const getAllfields = () => {
  const buildFields = {};
  Object.keys(schema).forEach(field => {
    buildFields[field] = field;
  })
  return buildFields;
}

export const fields = {
  'traits.birth_date': 'traits.birth_date',
  'traits.city': 'traits.city',
  'traits.email': 'traits.email',
  'traits.first_name': 'traits.first_name',
  'traits.last_name': 'traits.last_name',
  'traits.display_name': 'traits.display_name',
  'traits.phone': 'traits.phone',
  'password': 'password',
  'identifier': 'identifier',
  'email': 'email',
  'traits.username': 'traits.username',
  'traits.confirm_password': 'traits.confirm_password',
  'traits.picture': 'traits.picture',
}
export default schema;