import FieldWrapper from 'components/form/field-wrapper';
import { Form, Formik } from 'formik';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import adminActions from '../../actions/ory-admin-actions';
import schema, { fieldList } from '../../validation/tnc';
import Spinner from '../utils/spinner';
import languages from 'constants/languages';
import { withTranslation } from 'react-i18next';
import env from '../../api/ory/env';
import { HOME } from 'constants/routes';
import { ADMIN_HOME } from 'constants/routes';
import actions from '../../actions/auth-actions';

class Login extends Component {
  params = new URLSearchParams(this.props.location.search);
  flowId = this.params.get('flow');
  aal = this.params.get('aal');
  returnTo = this.params.get('return_to');
  refresh = this.params.get('refresh');

  initialValues = () => {
    const nodes = {};
    fieldList.forEach(field => {
      nodes[field] = schema.fields[field].spec?.default ?? ''
    });
    return nodes;
  };
  handleSubmit = async (formData) => {
    const {
      dispatch,
      user: { identity },
    } = this.props;
    const redirectOnSuccess = identity?.traits?.isAdmin ? ADMIN_HOME : HOME

    let payload = {
      traits: {
        ...identity?.traits,
      },
      schema_id: env.DEFAULT_SCHEMA_ID,
      state: identity?.state,
    };

    Object.keys(formData).forEach(data => {
      payload.traits[data] = formData[data];
    });

    await dispatch(
      adminActions.updateIdentity(identity?.id, payload, {
        redirectTo: redirectOnSuccess,
        showToast: false,
      })
    );
    await dispatch(actions.getSession());

  };

  render() {
    const { adminLoading, loading, t, user } = this.props;
    if (loading || adminLoading) {
      return <Spinner loading={loading} />;
    } else if (user?.identity?.traits?.isPrivacyPolicyAgreed && user?.identity?.traits?.isTncAgreed) {
      const path = user?.identity?.traits?.isAdmin ? ADMIN_HOME : HOME;
      return (<Redirect to={path} />)
    } else if (user) {
      return (
        <div className="min-h-screen py-6 flex flex-col justify-center relative overflow-hidden sm:py-12"
        >
          <div className="flex items-center xl:w-5/6 m-auto"
          >
            <div
              className="xl:mx-auto mx-4 flex p-2"
              style={{ boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)' }}
            >
              <section className="relative w-full pt-25">
                <div className="grid">
                  <div className="container mx-auto h-full">
                    <div className="flex content-center items-center justify-center h-full">
                      <div className="relative flex flex-col w-full mb-6 rounded-lg bg-white">
                        <div className="mb-0 px-5 pt-5">
                          <div className="text-center">
                            <h3 className="text-3xl p-2 font-bold">
                              {t('tnc.title.text')}
                            </h3>
                          </div>
                        </div>
                        <div className="text-center py-4 px-3">
                          {t('tnc.title.description')}
                        </div>
                        <div className='mt-4 xl:p-3 p-2 border overflow-y-scroll h-72 xl:mx-32 mx-2 m-auto'>

                          <span className='block font-extrabold my-2'>
                            {t('tnc.fields.tnc.title')}
                          </span>
                          <ol className='p-2 xl:px-6 px-4 list-decimal' start="1">
                            {[...Array(6).keys()].map(index => (
                              <li key={index} className='my-2'> {t(`tnc.fields.tnc.c${index + 1}`)} </li>
                            ))}
                          </ol>
                          <span className='block font-extrabold my-2 mt-6'>
                            {t('tnc.fields.privacy.title')}
                          </span>
                          <ol className='p-2 px-6 list-decimal' start="1">
                            <li className='my-2'>{t('tnc.fields.privacy.c1')}</li>
                            <li className='my-2'>{t('tnc.fields.privacy.c2')}</li>
                            <li className='my-2'>{t('tnc.fields.privacy.c3')}</li>
                            <li className='my-2'>{t('tnc.fields.privacy.c4')}
                              <ol className="m-4" style={{ listStyleType: 'lower-alpha' }}>
                                <li>{t('tnc.fields.privacy.c4_1')}
                                  <ul className='m-2' style={{ listStyle: 'square' }}>
                                    <li>
                                      {t('tnc.fields.privacy.c4_1_1')}
                                    </li>
                                  </ul>
                                </li>
                                <li> {t('tnc.fields.privacy.c4_2')}
                                  <ul className='m-2' style={{ listStyle: 'square' }}>
                                    <li>{t('tnc.fields.privacy.c4_2_1')}</li>
                                    <li>
                                      {t('tnc.fields.privacy.c4_2_2')}
                                    </li>
                                    <li>
                                      {t('tnc.fields.privacy.c4_2_3')}
                                    </li>
                                    <li>
                                      {t('tnc.fields.privacy.c4_2_4')}
                                    </li>
                                  </ul>
                                </li>
                                <li> {t('tnc.fields.privacy.c4_3')}
                                  <ul className='m-2' style={{ listStyle: 'square' }}>
                                    <li>
                                      {t('tnc.fields.privacy.c4_3_1')}
                                    </li>
                                    <li>
                                      {t('tnc.fields.privacy.c4_3_2')}
                                    </li>
                                    <li>
                                      {t('tnc.fields.privacy.c4_3_3')}
                                    </li>
                                  </ul>
                                </li>
                              </ol>
                              <li>
                                {t('tnc.fields.privacy.c5')}
                              </li>
                              <li>
                                {t('tnc.fields.privacy.c6')}
                              </li>
                              <li>
                                {t('tnc.fields.privacy.c7')}
                              </li>
                            </li>
                            {/* <li className='my-2'>Enschede.me has a system for users to facilitate the passage of information and communication.</li>
                            <li className='my-2'>Enschede.me only allows the residents of Enschede city to register in this application.</li> */}
                          </ol>
                        </div>
                        <div className="flex-auto pt-3 xl:mx-32 mx-auto">
                          <Formik
                            validationSchema={schema}
                            initialValues={this.initialValues()}
                            onSubmit={this.handleSubmit}
                            validateOnBlur={false}
                            validateOnChange={false}
                          >
                            {({
                              errors,
                              values,
                              handleChange,
                              setFieldValue,
                            }) => {
                              if (values) {
                                return (
                                  <Form>
                                    <select
                                      name="language.code"
                                      onChange={(e) => {
                                        handleChange(e);
                                        setFieldValue(
                                          'language.value',
                                          languages[e.target.value]
                                        );
                                        this.props.i18n.changeLanguage(e.target.value);
                                      }}
                                      value={values.language.code}
                                      className="appearance-none block w-full xl:w-9/12 bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                    >
                                      <option value="default" hidden>
                                        Select your Language
                                      </option>
                                      {Object.keys(languages)?.map((key) => (
                                        <option value={key} key={key}>
                                          {languages[key]}
                                        </option>
                                      ))}
                                    </select>
                                    <div className='float-right xl:px-0 px-3'>

                                      <FieldWrapper
                                        error={errors?.isTncAgreed}
                                        element="isTncAgreed"
                                        classes="mb-0"
                                      >
                                        <input
                                          className="mr-2 focus:radio-checked text-primary"
                                          type="checkbox"
                                          id="isTncAgreed"
                                          name="isTncAgreed"
                                          value={values.isTncAgreed}
                                          checked={values.isTncAgreed}
                                          onChange={handleChange}
                                        />
                                        <span className="text-gray md:text-sm text-xs">
                                          {' '}
                                          {t('tnc.fields.agree_tnc')}
                                        </span>

                                      </FieldWrapper>
                                      <FieldWrapper
                                        error={errors?.isPrivacyPolicyAgreed}
                                        element="isTncAgreed"
                                        classes="mb-0"
                                      >
                                        <input
                                          className="mr-2 focus:radio-checked text-primary"
                                          type="checkbox"
                                          id="isPrivacyPolicyAgreed"
                                          name="isPrivacyPolicyAgreed"
                                          value={values.isPrivacyPolicyAgreed}
                                          checked={values.isPrivacyPolicyAgreed}
                                          onChange={handleChange}

                                        />
                                        <span className="text-gray md:text-sm text-xs">
                                          {' '}
                                          {t('tnc.fields.agree_privacy_policy')}
                                        </span>
                                      </FieldWrapper>
                                      <FieldWrapper>
                                        <button
                                          type="submit"
                                          className="btn-primary shadow text-white py-2 px-4 rounded w-full hover:shadow-lg p-2 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
                                        >
                                          {t('common.buttons.continue')}
                                        </button>
                                      </FieldWrapper>
                                    </div>
                                  </Form>
                                );
                              }
                            }}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  adminLoading: state.oryAdmin.loading,
  user: state.auth.user
});

export default withTranslation()(withRouter(connect(mapStateToProps)(Login)));

