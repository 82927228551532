import * as yup from 'yup';
const schema = yup.object().shape({
  email: yup.string().required('Email is required').email('Invalid Email'),
  username: yup.string(),
  role: yup.string().required('Role is required')
});

const fields = () => {
  const field = {};
  Object.keys(schema.fields).forEach(
    (fieldName) => (field[fieldName] = fieldName)
  );
  return field;
};


export const fieldList = Object.keys(schema.fields);
export { fields };
export default schema;
