import env from '../../../api/ory/env';
import cities from 'constants/cities';
import { Form, Formik } from 'formik';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import bbaActions from '../../../actions/bba-actions';
import hydraActions from '../../../actions/hydra-actions';
import ketoActions from '../../../actions/keto-actions';
import actions from '../../../actions/ory-admin-actions';
import { IDENTITIES_LIST } from '../../../constants/routes';
import { cleanEmptyNodes, getNodesFromIdentity } from '../../../utils';

import schema, {
  fieldList,
  mappedIdentity,
} from '../../../validation/personal-info-fields';
import PersonalInfoFieldWrapper from '../../form/personal-info-form-wrapper';
import Spinner from '../../utils/spinner';
import AddBbaAccount from '../add-bba-account';
import AddWpUser from '../add-wp-user';
import { withTranslation } from 'react-i18next';

// const headers = [
//   'Display Name',
//   'Username',
//   'Wp user Role',
//   'Default Account Member',
// ];

class IdentitiesEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedSite: undefined,
      showAddWpUserPopup: false,
      showAddBbaAccountPopup: false,
    };
    this.handleSiteChange = this.handleSiteChange.bind(this);
  }

  handleSiteChange = async (event) => {
    const site = JSON.parse(event.target.value);
    const { identity, dispatch } = this.props;
    this.setState({
      selectedSite: site,
    });
    await dispatch(
      ketoActions.getRoles({
        object: site?.client_id,
      })
    );
    await dispatch(
      bbaActions.getConnectedUsers(identity?.id, site?.client_uri)
    );
  };

  componentDidMount = () => {
    const { dispatch, identity } = this.props;
    const id = this.props.match.params.id || null;
    if (!identity || identity.id !== id) {
      dispatch(actions.getIdentityById(id));
    }
    dispatch(hydraActions.getClients());
  };

  initialValues = () => {
    const { identity } = this.props;
    const emptyAddressObject = {};
    schema.fields?.address?._nodes?.forEach((field) => {
      emptyAddressObject[field] = '';
    });

    if (identity) {
      const nodes = getNodesFromIdentity(identity, fieldList, schema);
      if (!nodes?.address) {
        nodes.address = {
          ...emptyAddressObject,
        };
      }
      return nodes;
    }
  };

  handleSubmit = (formData) => {
    const { dispatch, identity } = this.props;
    let payload = {
      traits: {
        ...identity?.traits,
      },
      schema_id: env.DEFAULT_SCHEMA_ID,
      state: identity?.state,
    };

    Object.keys(formData).forEach((key) => {
      if (mappedIdentity[key]?.startsWith('traits')) {
        payload.traits[key] = formData[key] || undefined;
      } else {
        payload[mappedIdentity[key]] = formData[key] || undefined;
      }
    });
    payload = cleanEmptyNodes(payload);
    payload.traits.isAdmin = !!payload?.traits?.isAdmin;
    dispatch(actions.updateIdentity(identity?.id, payload));
  };

  openAddWpUserPopup = () => {
    this.setState({
      showAddWpUserPopup: true,
    });
  };
  closeAddWpUserPopup = () => {
    this.setState({
      showAddWpUserPopup: false,
    });
  };
  openAddBbaAccountPopup = () => {
    this.setState({
      showAddBbaAccountPopup: true,
    });
  };
  closeAddBbaAccountPopup = () => {
    this.setState({
      showAddBbaAccountPopup: false,
    });
  };

  generateAccountList = (fetchBusinessAccount = true) => {
    const { bbaAccounts } = this.props;
    const accounts = [];
    let payload = {};
    bbaAccounts.forEach((user) => {
      payload = {
        username: user.username,
        role: user.roles,
        userId: user.ID,
      };
      user.accounts?.forEach((account) => {
        payload = {
          ...payload,
          isDefault: account.default_account,
          isBusinessAccount: account.business_user,
          displayName: account.display_name || user.display_name,
          bbaId: account.bba_uuid,
        };
        if (payload.isBusinessAccount === fetchBusinessAccount) {
          accounts.push(payload);
        }
      });
    });
    return accounts;
  };

  setDefaultAccount = async (userId, bbaId) => {
    const { dispatch, identity } = this.props;
    const { selectedSite } = this.state;
    await dispatch(
      bbaActions.setDefaultAccount(userId, bbaId, selectedSite?.client_uri)
    );
    await dispatch(
      bbaActions.getConnectedUsers(identity?.id, selectedSite?.client_uri)
    );
  };

  render() {
    const { loading, identity, clientSites, ketoLoading, t } = this.props;
    const headers = [
      t('admin.identities_edit.table.fields.display_name'),
      t('admin.identities_edit.table.fields.username'),
      t('admin.identities_edit.table.fields.wp_role'),
      t('admin.identities_edit.table.fields.default_account')
    ];
    
    if (loading || ketoLoading) {
      return <Spinner loading={true} />;
    }
    if (identity) {
      const businessAccountList = this.generateAccountList();
      const personalAccountList = this.generateAccountList(false);
      const city = Object.keys(cities);
      const area = Object.keys(cities[city]);
      return (
        <div className="relative p-5 md:p-8 lg:p-10 w-full md:w-4/6 lg:w-4/5 xl:w-5/6">
          <div
            className="w-full border border-gray-200 rounded"
            style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)' }}
          >
            <div className="px-8 pt-8 mb-6 items-center">
              <Link
                to={IDENTITIES_LIST}
                className={
                  'weight-400 text-lg cursor-pointer letter-spacing line-height24 text-black'
                }
              >
                <i className="fas fa-arrow-left"></i>
              </Link>
              <span className="ml-3 text-2xl font-bold">{t('admin.identities_edit.title')}</span>
            </div>
            <div className="mx-2 mt-5 ">
              {/* {flow && <Flow flow={flow} type={FLOW_TYPES.SETTINGS} validationSchema={this.validationSchema} />} */}
              <Formik
                initialValues={this.initialValues()}
                onSubmit={this.handleSubmit}
                validationSchema={schema}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, values, handleChange, setFieldValue }) => {
                  if (values) {
                    return (
                      <Form>
                        <PersonalInfoFieldWrapper
                          error={errors.username}
                          required={true}
                          title={t('personal_info.basic_info.fields.username')}
                          element="username"
                        >
                          <input
                            value={values.username}
                            name="username"
                            type="text"
                            onChange={handleChange}
                            className="appearance-none block w-full md:w-9/12 bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          />
                        </PersonalInfoFieldWrapper>

                        <PersonalInfoFieldWrapper
                          error={errors.address?.area}
                          title={`${city} ${t('information_edit.address.fields.area')}`}
                          element="address.area"
                          required
                        >
                          <select
                            name="address.area"
                            onChange={(e) => {
                              setFieldValue('address.neighbourhood', '');
                              handleChange(e);
                            }}
                            value={values.address.area}
                            className="appearance-none block w-full md:w-9/12 bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          >
                            <option value="default" hidden>
                              {`Select ${city} Area`}
                            </option>
                            {area.map((option, key) => (
                              <option value={option} key={key}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </PersonalInfoFieldWrapper>

                        <PersonalInfoFieldWrapper
                          error={errors.address?.neighbourhood}
                          title={t('information_edit.address.fields.neighbourhood')}
                          element="address.neighbourhood"
                          required
                        >
                          <select
                            disabled={!values.address.area}
                            name="address.neighbourhood"
                            onChange={handleChange}
                            value={values.address.neighbourhood}
                            className="appearance-none block w-full md:w-9/12 bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          >
                            <option value="default" hidden>
                              Select Neighbourhood
                            </option>
                            {cities[city][values.address.area]?.map(
                              (option, key) => (
                                <option value={option} key={key}>
                                  {option}
                                </option>
                              )
                            )}
                          </select>
                        </PersonalInfoFieldWrapper>
                        <PersonalInfoFieldWrapper
                          error={errors.phone?.number}
                          title={t('personal_info.contact_info.fields.phone')}
                          element="phone.number"
                          required
                        >
                          <input
                            name="phone.number"
                            type="text"
                            value={`+${values.phone.dial_code} ${values.phone.number}`}
                            onChange={handleChange}
                            disabled
                            className="disable-input-background appearance-none block w-full md:w-9/12 bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          />
                        </PersonalInfoFieldWrapper>

                        <PersonalInfoFieldWrapper
                          error={errors.telegram?.number}
                          title={t('personal_info.contact_info.fields.telegram')}
                          element="telegram.number"
                          required
                        >
                          <input
                            value={`+${values.telegram.dial_code} ${values.telegram.number}`}
                            name="telegram.number"
                            type="text"
                            onChange={handleChange}
                            disabled
                            className="disable-input-background appearance-none block w-full md:w-9/12 bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          />
                        </PersonalInfoFieldWrapper>

                        <PersonalInfoFieldWrapper
                          error={errors.email}
                          required={true}
                          title={t('personal_info.contact_info.fields.email')}
                          element="email"
                        >
                          <input
                            value={values.email[0]}
                            name="email"
                            type="text"
                            onChange={handleChange}
                            disabled
                            className="disable-input-background appearance-none block w-full md:w-9/12 bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          />
                        </PersonalInfoFieldWrapper>
                        <PersonalInfoFieldWrapper
                          error={errors.isAdmin}
                          required={true}
                          title={t('personal_info.basic_info.fields.is_admin')}
                          element="isAdmin"
                        >
                          <input
                            type="checkbox"
                            name="isAdmin"
                            className="mr-3 align-text-bottom focus:radio-checked h-4 w-4 text-primary border-gray-300"
                            onChange={handleChange}
                            value={Boolean(values.isAdmin)}
                            checked={Boolean(values.isAdmin)}
                          />
                        </PersonalInfoFieldWrapper>

                        <PersonalInfoFieldWrapper
                          error={errors.isAdmin}
                          required={true}
                          title=""
                          element=""
                        >
                          <div className="mb-5 mt-3">
                            <div className="flex items-center">
                              <input
                                type="checkbox"
                                id="lightkyc"
                                name="kyc"
                                value="Light KYC Verified"
                              />
                              <label htmlFor="lightkyc" className="ml-3 block">
                                {t('admin.identities_edit.fields.light_kyc')}
                              </label>
                              <br />
                            </div>
                            <div className="flex items-center mt-2">
                              <input
                                type="checkbox"
                                id="heavykyc"
                                name="kyc"
                                value="Heavy KYC Verified"
                              />
                              <label htmlFor="heavykyc" className="ml-3 block">
                                {t('admin.identities_edit.fields.heavy_kyc')}
                              </label>
                              <br />
                            </div>
                          </div>
                        </PersonalInfoFieldWrapper>

                        <div className="px-8">
                          <div
                            className={
                              this.state.selectedSite !== ''
                                ? 'md:flex items-center justify-between'
                                : 'md:flex items-center justify-start'
                            }
                          >
                            <div className="w-full">
                              <select
                                name="site"
                                id="sites"
                                className="rounded w-full md:w-6/12 lg:w-4/12 my-5"
                                onChange={this.handleSiteChange}
                                value={JSON.stringify(this.state.selectedSite)}
                              >
                                <option value="default" hidden>
                                  Select Website
                                </option>
                                {clientSites?.map((client, key) => (
                                  <option
                                    key={key}
                                    value={JSON.stringify(client)}
                                  >
                                    {client?.client_name}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {this.state.selectedSite ? (
                              <div className="w-full flex justify-center mb-5 md:mb-0 md:justify-end">
                                <button
                                  type="button"
                                  className=" btn-primary text-white py-3 px-8 rounded p-2 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
                                  onClick={this.openAddWpUserPopup}
                                >
                                  {t('admin.identities_edit.buttons.add_wp_user')}
                                </button>
                                <button
                                  type="button"
                                  className=" btn-primary text-white py-3 px-8 rounded p-2 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
                                  onClick={this.openAddBbaAccountPopup}
                                >
                                  {t('admin.identities_edit.buttons.add_bba_account')}
                                </button>
                                <AddWpUser
                                  open={this.state.showAddWpUserPopup}
                                  closePopup={this.closeAddWpUserPopup}
                                  selectedSite={this.state.selectedSite}
                                />
                                <AddBbaAccount
                                  open={this.state.showAddBbaAccountPopup}
                                  closePopup={this.closeAddBbaAccountPopup}
                                  selectedSite={this.state.selectedSite}
                                />
                              </div>
                            ) : null}
                          </div>

                          <div>
                            {this.state.selectedSite &&
                              businessAccountList?.length > 0 ? (
                              <div className="block w-full overflow-x-auto mb-5">
                                <table className="items-center w-full bg-transparent border-collapse border border-slate-400">
                                  <thead className="table-backgroud">
                                    <tr className="border-b border-slate-400">
                                      <th
                                        colSpan={4}
                                        className="align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
                                      >
                                        <div className="flex items-center">
                                          <span
                                            className={
                                              'ml-3 weight-600 font-16 letter-spacing line-height24 text-gray'
                                            }
                                          >
                                          {t('admin.identities_edit.business_accounts.title')}
                                            
                                          </span>
                                        </div>
                                      </th>
                                      <th className="align-middle border-l border-slate-400 border-r-0 whitespace-nowrap p-4">
                                        <div className="flex items-center">
                                          <span
                                            className={
                                              'weight-600 font-16 letter-spacing line-height24 text-gray'
                                            }
                                          >
                                            {t('common.buttons.edit')}
                                          </span>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <thead>
                                    <tr className="border-b border-slate-400">
                                      {headers.map((prop, uniqueKey) => (
                                        <th
                                          key={uniqueKey}
                                          className="align-middle border-l-0 whitespace-nowrap p-4 border-r border-slate-400"
                                        >
                                          <div className="flex items-center">
                                            <span
                                              className={
                                                'ml-3 weight-600 font-16 letter-spacing line-height24 text-gray'
                                              }
                                            >
                                              {prop}
                                            </span>
                                          </div>
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {businessAccountList &&
                                      businessAccountList.map((Items) => {
                                        return (
                                          <tr
                                            className="border-r-0 border-l-0 border-b border-slate-400"
                                            key={Items.bbaId}
                                          >
                                            <td className=" align-middle border-l-0 border-r border-slate-400 text-xs whitespace-nowrap p-4">
                                              <div className="flex items-center">
                                                <span
                                                  className={
                                                    'ml-3 weight-600 font-semibold font-14 letter-spacing line-height24 text-gray'
                                                  }
                                                >
                                                  {Items.displayName}
                                                </span>
                                              </div>
                                            </td>
                                            <td className=" align-middle border-l-0 border-r border-slate-400 text-xs whitespace-nowrap p-4">
                                              <div className="flex items-center">
                                                <span
                                                  className={
                                                    'ml-3 weight-600 font-semibold font-14 letter-spacing line-height24 text-gray'
                                                  }
                                                >
                                                  {Items.username}
                                                </span>
                                              </div>
                                            </td>
                                            <td className=" align-middle border-l-0 border-r border-slate-400 text-xs whitespace-nowrap p-4">
                                              <div className="flex items-center justify-between">
                                                <span
                                                  className={
                                                    'ml-3 weight-600 font-semibold font-14 letter-spacing line-height24 text-gray'
                                                  }
                                                >
                                                  {Items.role}
                                                </span>
                                                <i className="fas fa-pen text-primary font-16 cursor-pointer"></i>
                                              </div>
                                            </td>
                                            <td className="px-3 align-middle border-l-0 border-r border-slate-400 text-xs whitespace-nowrap p-4">
                                              <div className="flex items-center justify-center">
                                                <span
                                                  className={
                                                    'weight-600 font-14 letter-spacing line-height24 text-gray'
                                                  }
                                                >
                                                  <input
                                                    type="radio"
                                                    name={`business_${Items.userId}`}
                                                    value={Items.isDefault}
                                                    checked={Items.isDefault}
                                                    onChange={async () =>
                                                      await this.setDefaultAccount(
                                                        Items.userId,
                                                        Items.bbaId
                                                      )
                                                    }
                                                  />
                                                </span>
                                              </div>
                                            </td>
                                            <td className="px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                              <div className="flex items-center justify-center">
                                                <span
                                                  className={
                                                    'weight-600 font-14 letter-spacing line-height24 text-gray btn-primary p-2 cursor-pointer rounded flex justify-center items-center'
                                                  }
                                                >
                                                  <i className="fas fa-edit text-white"></i>
                                                </span>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            ) : null}
                            {this.state.selectedSite &&
                              personalAccountList?.length > 0 ? (
                              <div className="block w-full overflow-x-auto mb-5">
                                <table className="items-center w-full bg-transparent border-collapse border border-slate-400">
                                  <thead className="table-backgroud">
                                    <tr className="border-b border-slate-400">
                                      <th
                                        colSpan={4}
                                        className="align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
                                      >
                                        <div className="flex items-center">
                                          <span
                                            className={
                                              'ml-3 weight-600 font-16 letter-spacing line-height24 text-gray'
                                            }
                                          >
                                          {t('admin.identities_edit.personal_accounts.title')}
                                          </span>
                                        </div>
                                      </th>
                                      <th className="align-middle border-l border-slate-400 border-r-0 whitespace-nowrap p-4">
                                        <div className="flex items-center">
                                          <span
                                            className={
                                              'weight-600 font-16 letter-spacing line-height24 text-gray'
                                            }
                                          >
                                            {t('common.buttons.edit')}
                                          
                                          </span>
                                        </div>
                                      </th>
                                    </tr>
                                  </thead>
                                  <thead>
                                    <tr className="border-b border-slate-400">
                                      {headers.map((prop, uniqueKey) => (
                                        <th
                                          key={uniqueKey}
                                          className="align-middle border-l-0 whitespace-nowrap p-4 border-r border-slate-400"
                                        >
                                          <div className="flex items-center">
                                            <span
                                              className={
                                                'weight-600 font-16 letter-spacing line-height24 text-gray'
                                              }
                                            >
                                              {prop}
                                            </span>
                                          </div>
                                        </th>
                                      ))}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {personalAccountList &&
                                      personalAccountList.map((Items) => {
                                        return (
                                          <tr
                                            className="border-r-0 border-l-0 border-b border-slate-400"
                                            key={Items?.bbaId}
                                          >
                                            <td className=" align-middle border-l-0 border-r border-slate-400 text-xs whitespace-nowrap p-4">
                                              <div className="flex items-center">
                                                <span
                                                  className={
                                                    'ml-3 weight-600 font-semibold font-14 letter-spacing line-height24 text-gray'
                                                  }
                                                >
                                                  {Items.displayName}
                                                </span>
                                              </div>
                                            </td>
                                            <td className=" align-middle border-l-0 border-r border-slate-400 text-xs whitespace-nowrap p-4">
                                              <div className="flex items-center">
                                                <span
                                                  className={
                                                    'ml-3 weight-600 font-semibold font-14 letter-spacing line-height24 text-gray'
                                                  }
                                                >
                                                  {Items.username}
                                                </span>
                                              </div>
                                            </td>
                                            <td className=" align-middle border-l-0 border-r border-slate-400 text-xs whitespace-nowrap p-4">
                                              <div className="flex items-center justify-between">
                                                <span
                                                  className={
                                                    'ml-3 weight-600 font-semibold font-14 letter-spacing line-height24 text-gray'
                                                  }
                                                >
                                                  {Items.role}
                                                </span>
                                                <i className="fas fa-pen text-primary font-16 cursor-pointer"></i>
                                              </div>
                                            </td>
                                            <td className="px-3 align-middle border-l-0 border-r border-slate-400 text-xs whitespace-nowrap p-4">
                                              <div className="flex items-center justify-center">
                                                <span
                                                  className={
                                                    'weight-600 font-14 letter-spacing line-height24 text-gray'
                                                  }
                                                >
                                                  <input
                                                    type="radio"
                                                    name={`personal_${Items.userId}`}
                                                    value={Items.isDefault}
                                                    checked={Items.isDefault}
                                                    onChange={async () =>
                                                      await this.setDefaultAccount(
                                                        Items.userId,
                                                        Items.bbaId
                                                      )
                                                    }
                                                  />
                                                </span>
                                              </div>
                                            </td>
                                            <td className="px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                              <div className="flex items-center justify-center">
                                                <span
                                                  className={
                                                    'weight-600 font-14 letter-spacing line-height24 text-gray btn-primary p-2 cursor-pointer rounded flex justify-center items-center'
                                                  }
                                                >
                                                  <i className="fas fa-edit text-white"></i>
                                                </span>
                                              </div>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                  </tbody>
                                </table>
                              </div>
                            ) : null}
                          </div>
                        </div>
                        <hr />

                        <div className="flex items-center justify-end my-5 px-8">
                          <button
                            type="submit"
                            className="nav-bg-color text-white py-3 px-8 rounded  hover:shadow-lg p-2 mr-4 outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                          >
                          {t('common.buttons.save')}
                          </button>
                        </div>
                      </Form>
                    );
                  }
                }}
              </Formik>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  loading: state.oryAdmin.loading,
  user: state.auth.user,
  identity: state.oryAdmin.identity,
  clientSites: state.hydra.clients,
  siteLoading: state.hydra.loading,
  bbaAccounts: state.bba.users,
  bbaLoading: state.bba.loading,
  ketoLoading: state.keto.loading,
  roles: state.keto.roles,
  oryAdminLoading: state.oryAdmin.loading,
});

export default withTranslation()(withRouter(connect(mapStateToProps)(IdentitiesEdit)));

