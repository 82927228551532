import getStore from '../store';

const actions = {
  IS_OPEN: 'IS_OPEN',
  TOGGLE: 'TOGGLE',
  toggleSidebar: () => async (dispatch) => {
    const flag = !getStore().getState().sidebar.isOpen;
    dispatch({
      type: actions.TOGGLE,
      payload: flag,
    });
    // localStorage.setItem('isSidebarOpen', flag);
  },
};

export default actions;

