export const BUSINESS_TYPE = ['Dispensary', 'Processor', 'Grow'];
export const GOV_DOC_TYPE = [
  {
    label: 'Driver\'s License',
    id: 'license'
  },
  {
    label: 'State ID',
    id: 'id'
  },
  {
    label: 'Passport',
    id: 'passport'
  }
];

export const wpUserRoles = [
  'subscriber',
  'admin'
];