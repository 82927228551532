import React from 'react';
import languages from 'constants/languages';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';

class SelectLanguage extends React.Component {
    changeLanguage = (e) => {
        const { value } = e.target;
        const { i18n } = this.props;
        i18n.changeLanguage(value)
    }

    render() {
        const { i18n } = this.props;
        return (
            <div className="my-6 w-96 flex-col relative overflow-hidden">
                <div className="mb-2 w-full">
                    <div>
                        Select Language
                    </div>

                    <select
                        name="language_code"
                        onChange={this.changeLanguage}
                        className="appearance-none block w-full mt-2 bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        value={i18n.language}
                    >
                        {Object.keys(languages)?.map((key) => (
                            <option value={key} key={key}>
                                {languages[key]}
                            </option>
                        ))}
                    </select>

                </div>
            </div>

        );
    }
}
const mapStateToProps = (state) => ({
    user: state?.auth?.user
});
export default withTranslation()(connect(mapStateToProps)(SelectLanguage));

