import { getHistory } from 'store';
import actions from '../actions/auth-actions';
const initialState = {
  loginFlow: undefined,
  signupFlow: undefined,
  businessSignupFlow: undefined,
  loading: false,
  errorFlow: undefined,
  user: undefined,
  passwordRecoveryFlow: undefined,
  settingsFlow: undefined,
  logoutFlow: undefined,
  verificationFlow: undefined,
};
const routes = ['/reset-password'];
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.GET_IDENTITY_LIST_STARTED:
      return {
        ...state,
        loading: true,
        identities: [],
      };
    case actions.GET_IDENTITY_LIST_ERROR:
      return {
        ...state,
        loading: false,
        identities: [],
      };
    case actions.GET_IDENTITY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        identities: payload,
      };
    case actions.LOGIN_FLOW_STARTED:
      return {
        ...state,
        loading: true,
        loginFlow: undefined,
      };
    case actions.LOGIN_FLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        loginFlow: payload,
      };

    case actions.LOGIN_FLOW_ERROR:
    case actions.SIGNUP_FLOW_ERROR:
    case actions.SIGNUP_BUSINESS_FLOW_ERROR:
    case actions.ERROR_FLOW_ERROR:
    case actions.GET_USER_SESSION_ERROR:
    case actions.PASSWORD_RECOVERY_FLOW_ERROR:
    case actions.SETTINGS_FLOW_ERROR:
    case actions.LOGOUT_FLOW_ERROR:
    case actions.VERIFICATION_FLOW_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.SUBMIT_FLOW_ERROR:
      return {
        ...state,
        loading: false
      };
    case actions.LOGIN_FLOW_RESET:
      return {
        ...state,
        loading: false,
        loginFlow: undefined,
      };
    case actions.SIGNUP_FLOW_STARTED:
      return {
        ...state,
        loading: true,
        signupFlow: undefined,
      };
    case actions.SIGNUP_FLOW_SUCCESS:
      // console.log(payload);
      return {
        ...state,
        loading: false,
        signupFlow: payload,
      };
    case actions.SIGNUP_FLOW_RESET:
      return {
        ...state,
        loading: false,
        signupFlow: undefined,
      };
    case actions.SIGNUP_BUSINESS_FLOW_STARTED:
      return {
        ...state,
        loading: true,
        businessSignupFlow: undefined,
      };
    case actions.SIGNUP_BUSINESS_FLOW_SUCCESS:
      // console.log(payload);
      return {
        ...state,
        loading: false,
        businessSignupFlow: payload,
      };
    case actions.SIGNUP_BUSINESS_FLOW_RESET:
      return {
        ...state,
        loading: false,
        businessSignupFlow: undefined,
      };
    case actions.ERROR_FLOW_STARTED:
      return {
        ...state,
        loading: true,
        errorFlow: undefined,
      };
    case actions.ERROR_FLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        errorFlow: payload,
      };
    case actions.ERROR_FLOW_RESET:
      return {
        ...state,
        loading: false,
        errorFlow: undefined,
      };
    case actions.GET_USER_SESSION_STARTED:
      return {
        ...state,
        loading: true,
        user: undefined,
      };
    case actions.GET_USER_SESSION_SUCCESS:
      return {
        ...state,
        loading: false,
        user: payload,
      };
    case actions.PASSWORD_RECOVERY_FLOW_STARTED:
      return {
        ...state,
        loading: true,
        passwordRecoveryFlow: undefined,
      };
    case actions.PASSWORD_RECOVERY_FLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        passwordRecoveryFlow: payload,
      };
    case actions.SETTINGS_FLOW_STARTED:
      return {
        ...state,
        loading: true,
        settingsFlow: undefined,
      };
    case actions.SETTINGS_FLOW_SUCCESS:
      return {
        ...state,
        loading: false,
        settingsFlow: payload,
      };
    case actions.SUBMIT_FLOW_STARTED:
      return {
        ...state,
        loading: true,
      };
    case actions.LOGOUT_FLOW_STARTED:
      return {
        ...state, 
        loading: true,
      };
    case actions.LOGOUT_FLOW_SUCCESS:
      return {
        ...initialState,
        logoutFlow: payload,
      };
    case actions.SUBMIT_FLOW_SUCCESS:
      return {
        ...state,
        user: payload?.user || payload?.settingsFlow?.identities || state.user,
        passwordRecoveryFlow: payload?.passwordRecoveryFlow,
        settingsFlow: payload?.settingsFlow,
        loading: false,
      };
    case actions.VERIFICATION_FLOW_STARTED: {
      return {
        ...state,
        loading: true,
        verificationFlow: undefined,
      };
    }
    case actions.VERIFICATION_FLOW_SUCCESS: {
      return {
        ...state,
        loading: false,
        verificationFlow: payload,
      };
    }
    case actions.ROUTER_LOCATION_CHANGE:
      if (routes.includes(getHistory().location.pathname)) {
        return {
          ...state,
          loading: false,
          settingsFlow: undefined,
        };
      }
      return {
        ...state,
        loading: false,
      };
    default:
    // break;
  }
  return state;
};

