import { Form, Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { withRouter } from 'react-router';
import bbaActions from '../../actions/bba-actions';
import ketoActions from '../../actions/keto-actions';
import { siteAccessConfig } from '../../api/keto/config';

import { wpUserRoles } from '../../constants/constants';
import schema, { fieldList } from '../../validation/add-wp-user';
import FieldWrapper from '../form/field-wrapper';
import Spinner from '../utils/spinner';
class AddWpUser extends React.Component {
  initialValues = () => {
    const nodes = {};
    fieldList.forEach((field) => {
      nodes[field] = '';
    });
    return nodes;
  };

  handleSubmit = async (values) => {
    const { identity, dispatch, selectedSite, roles } = this.props;
    const payload = {
      id: identity?.id,
    };
    const traits = {
      is_business: true,
      email: values.email,
      username: values?.username || undefined,
      role: values.role,
    };
    if (traits.username == undefined) {
      delete traits.username;
    }
    payload.traits = traits;
    await dispatch(
      bbaActions.registerBusiness(payload, selectedSite?.client_uri)
    );
    const newSiteUsers = [];
    identity?.traits?.email?.forEach((email) => {
      const userExist = roles?.relation_tuples?.find(
        (tuple) => tuple.subject_id === email
      );
      if (!userExist) {
        const { namespace, relation } = siteAccessConfig;
        newSiteUsers.push({
          action: 'insert',
          relation_tuple: {
            namespace,
            relation,
            subject_id: email,
            object: selectedSite?.client_id,
          },
        });
      }
    });

    if (newSiteUsers.length) {
      await dispatch(ketoActions.createRole(newSiteUsers));
      await dispatch(
        ketoActions.getRoles({
          object: selectedSite?.client_id,
        })
      );
    }
    await dispatch(
      bbaActions.getConnectedUsers(identity?.id, selectedSite?.client_uri)
    );
  };

  style = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };

  render() {
    const {
      open,
      closePopup,
      selectedSite,
      bbaLoading,
      ketoLoading,
    } = this.props;
    if (bbaLoading || ketoLoading) {
      closePopup();
      return <Spinner loading={true} />;
    }
    return (
      <Modal open={open} onClose={closePopup} center>
        <h2 className="p-5">
          Add Wordpress User on Site{' '}
          <a
            className="link hover:link visited:link"
            target="_blank"
            rel="noreferrer"
            href={selectedSite?.client_uri}
          >
            {selectedSite?.client_uri || selectedSite?.client_name}
          </a>
        </h2>
        <hr />
        <div className="flex-auto px-4 py-9 pt-2">
          <Formik
            initialValues={this.initialValues()}
            onSubmit={this.handleSubmit}
            validationSchema={schema}
            validateOnBlur={false}
            validateOnChange={false}
          >
            {({ errors, values, handleChange }) => {
              if (values) {
                return (
                  <Form>
                    <FieldWrapper
                      error={errors.username}
                      required={false}
                      title="Username"
                      element="username"
                    >
                      <input
                        value={values.username}
                        name="username"
                        placeholder="You can leave the username blank"
                        type="text"
                        onChange={handleChange}
                        className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      error={errors.email}
                      required={true}
                      title="Email"
                      element="email"
                    >
                      <input
                        value={values.email}
                        name="email"
                        type="text"
                        placeholder="name@example.com"
                        onChange={handleChange}
                        className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      />
                    </FieldWrapper>
                    <FieldWrapper
                      error={errors.role}
                      title="Add user role"
                      element="role"
                      required
                    >
                      <select
                        name="role"
                        onChange={handleChange}
                        value={values.role}
                        className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                      >
                        <option value="default" hidden>
                          Select user role
                        </option>
                        {wpUserRoles.map((option, key) => (
                          <option value={option} key={key}>
                            {option}
                          </option>
                        ))}
                      </select>
                    </FieldWrapper>

                    <FieldWrapper>
                      <div className="flex">
                        <button
                          onClick={closePopup}
                          type="button"
                          className=" btn-gray shadow uppercase text-white py-2 px-4 rounded w-full hover:shadow-lg p-2 px-8 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className=" btn-primary shadow uppercase text-white py-2 px-4 rounded w-full hover:shadow-lg p-2 px-8 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
                        >
                          Add
                        </button>
                      </div>
                    </FieldWrapper>
                  </Form>
                );
              }
            }}
          </Formik>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.oryAdmin.loading,
  identity: state.oryAdmin.identity,
  bbaLoading: state.bba.loading,
  ketoLoading: state.keto.loading,
  roles: state.keto.roles,
});

export default withRouter(connect(mapStateToProps)(AddWpUser));

