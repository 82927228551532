import React from 'react';
class Pager extends React.Component {
  state = { pageNo: 1, itemsPerPage: 10 };
  componentDidMount = () => {
    const { initialPageNo = 1, itemsPerPage = 10 } = this.props;
    this.setState({ pageNo: initialPageNo, itemsPerPage: itemsPerPage });
  };

  calculateNoOfPages = () => {
    const { itemsCount, itemsPerPage } = this.props;
    return Math.ceil(itemsCount / itemsPerPage);
  };

  goNext = () => {
    const { itemsCount, itemsPerPage } = this.props;
    const { pageNo } = this.state;
    if (pageNo * itemsPerPage < itemsCount) {
      this.setState((prevState) => ({
        pageNo: prevState.pageNo + 1,
      }));
    }
  };
  goBack = () => {
    const { pageNo } = this.state;
    if (pageNo > 1) {
      this.setState((prevState) => ({
        pageNo: prevState.pageNo - 1,
      }));
    }
  };

  goto = (pageNo) => {
    const { itemsCount, itemsPerPage } = this.props;
    if (pageNo > 0 && pageNo - 1 * itemsPerPage < itemsCount) {
      this.setState({ pageNo: pageNo });
    }
  };

  componentDidUpdate = (_, prevState) => {
    if (prevState.pageNo !== this.state.pageNo) {
      const { onPageChange } = this.props;
      onPageChange(this.state.pageNo, this.state.itemsPerPage);
    }
  };
  render() {
    const { pageNo } = this.state;
    const { itemsCount, itemsPerPage } = this.props;
    const pageCount = this.calculateNoOfPages();
    if (pageCount > 1) {
      return (
        <div className="flex w-full justify-end gap-2 p-4">
          <button
            className="btn-primary shadow text-white py-1 rounded  hover:shadow-lg p-2   outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150 float-right disabled:bg-gray-400"
            onClick={this.goBack}
            disabled={this.state.pageNo <= 1}
          >
            <i className="fas fa-chevron-left text-lg"></i>
          </button>
          {Array.from(Array(pageCount).keys()).map((buttonIndex) => {
            const currentPage = buttonIndex + 1;
            let styleProps = 'text-black';
            if (currentPage === pageNo) {
              styleProps = 'btn-primary text-white';
            }
            return (
              <button
                className={`${styleProps} shadow hover:pagination theme-border rounded p-1 px-2 outline-none border focus:outline-none mb-1 ease-linear transition-all duration-150`}
                key={buttonIndex}
                onClick={() => {
                  this.goto(currentPage);
                }}
              >
                {currentPage}
              </button>
            );
          })}
          <button
            className="btn-primary shadow text-white py-1 rounded  hover:shadow-lg p-2 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150 float-right disabled:bg-gray-400"
            onClick={this.goNext}
            disabled={pageNo * itemsPerPage >= itemsCount}
          >
            <i className="fas fa-chevron-right text-lg"></i>
          </button>
        </div>
      );
    }
    return null;
  }
}

export default Pager;

