import actions from '../actions/sidebar-actions';

const initialState = {
  isOpen: (window?.screen?.width > 768)
  // isOpen: localStorage.getItem('isSidebarOpen') === 'true',

};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.TOGGLE:
      return { isOpen: action.payload };
    case actions.IS_OPEN:
      return Object.assign({}, state, {
        isOpen: localStorage.getItem('isSidebarOpen') === 'true',
      });
    default:
    //   return state;
  }
  return state;
};

