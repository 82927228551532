import FieldWrapper from 'components/form/field-wrapper';
import { Form, Formik } from 'formik';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import actions from '../../actions/auth-actions';
import { FLOW_TYPES } from '../../constants/flows';
import { FORGOT_PASS, LOGIN, REGISTER } from '../../constants/routes';
import { getNodesFromFlow } from '../../utils';
import schema, { fieldList, mappedIdentity } from '../../validation/login';
import Spinner from '../utils/spinner';
import env from '../../api/ory/env';
import { withTranslation } from 'react-i18next';
import SelectLanguage from 'components/shared/select-language';
import { createRef } from 'react';
import { config } from '../../constants/config';
class Login extends Component {
  params = new URLSearchParams(this.props.location.search);
  flowId = this.params.get('flow');
  aal = this.params.get('aal');
  returnTo = this.params.get('return_to');
  refresh = this.params.get('refresh');
  constructor(props) {
    super(props);
    this.passwordRef = createRef();
  }
  async init() {
    try {
      const { dispatch } = this.props;
      dispatch(
        actions.getLoginFlow(this.flowId, this.aal, this.returnTo, this.refresh)
      );
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount = async () => {
    await this.init();
  };

  initialValues = () => {
    if (this.props.flow) {
      const nodes = getNodesFromFlow(
        this.props.flow,
        fieldList,
        mappedIdentity
      );
      nodes['method'] = schema.fields['method']?.spec?.default;
      return nodes;
    }
  };

  toggleShowPassword = () => {
    const element = this.passwordRef.current;
    const types = ['password', 'text'];
    if (element) {
      const index = +!types.findIndex((e) => e === element.type);
      this.passwordRef.current.type = types[index];
    }
  };

  handleSubmit = (formData) => {
    const { dispatch } = this.props;
    const payload = {};
    Object.keys(formData).forEach((key) => {
      payload[mappedIdentity[key]] = formData[key];
    });
    if (payload.password) {
      payload['method'] = 'password';
    } else {
      payload['method'] = 'webauthn';
    }
    dispatch(actions.submitFlow(this.props?.flow, FLOW_TYPES.LOGIN, payload));
  };

  render() {
    const { flow, loading, t } = this.props;
    const webAuthnLoginHandler = flow?.ui?.nodes?.find(
      (node) => node?.attributes?.name === 'webauthn_login_trigger'
    );
    const oryAPI = env.ORY_API_URL;
    if (loading) {
      return <Spinner loading={loading} />;
    } else if (flow) {
      return (
        <div className="min-h-screen flex flex-col justify-center relative overflow-hidden">
          <div className="flex relative items-center m-auto pt-20">
            <div
              className="md:mx-auto flex p-6 mx-2"
              style={{ boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)' }}
            >
              <section className="relative w-full pt-25">
                <div className="grid">
                  <div className="container mx-auto h-full">
                    <div className="flex content-center items-center justify-center h-full">
                      <div className="relative flex flex-col w-full mb-6 rounded-lg bg-white">
                        <div className="mb-0 px-5 pt-5">
                          <div className="text-center">
                            <h3 className="text-3xl p-2 font-bold">
                              {t('login.title.text')}
                            </h3>
                          </div>
                        </div>
                        <div className="text-center py-4 px-3">
                          {webAuthnLoginHandler
                            ? t('login.title.webauthn.description')
                            : t('login.title.password.description')}
                        </div>
                        <div className="flex-auto px-4 pt-2">
                          {!webAuthnLoginHandler ? (
                            <Formik
                              initialValues={this.initialValues()}
                              onSubmit={this.handleSubmit}
                              validationSchema={schema}
                              validateOnBlur={false}
                              validateOnChange={false}
                            >
                              {({
                                errors,
                                values,
                                handleChange,
                                setFieldValue,
                              }) => {
                                if (values) {
                                  return (
                                    <Form>
                                      <FieldWrapper
                                        error={errors.email}
                                        required={true}
                                        title={t('login.fields.email')}
                                        element="email"
                                      >
                                        <input
                                          value={values.email}
                                          name="email"
                                          type="text"
                                          placeholder="name@example.com"
                                          onChange={handleChange}
                                          className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        />
                                      </FieldWrapper>
                                      <FieldWrapper
                                        error={errors.password}
                                        required={true}
                                        title={t('login.fields.password')}
                                        element="password"
                                      >
                                        <div className="appearance-none block w-full relative bg-white text-gray-700 border border-gray-200 rounded-lg  mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500">
                                          <input
                                            value={values['password']}
                                            name="password"
                                            placeholder="**********"
                                            type="password"
                                            onChange={handleChange}
                                            className="appearance-none outline-none py-3 w-full text-gray-700 border-transparent rounded-lg leading-tight focus:outline-none focus:bg-white"
                                            ref={this.passwordRef}
                                          />
                                          <i
                                            className="fa fa-eye rounded-lg appearance-none absolute -translate-x-1/2 -translate-y-1/2 top-1/2 right-1  text-gray-600"
                                            onClick={this.toggleShowPassword}
                                          />
                                        </div>
                                      </FieldWrapper>
                                      <div className="mt-3 mb-3">
                                        <input
                                          className="mr-2 focus:radio-checked text-primary"
                                          type="checkbox"
                                          id="rememberMe"
                                          name="rememberMe"
                                          value={true}
                                        />
                                        <span className="text-gray font-14">
                                          {' '}
                                          {t('login.fields.remember_me')}
                                        </span>
                                      </div>
                                      <FieldWrapper>
                                        <button
                                          disabled={!values.password}
                                          type="submit"
                                          className="btn-primary shadow text-white py-2 px-4 rounded w-full hover:shadow-lg p-2 px-8 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
                                        >
                                          {t('login.buttons.login')}
                                        </button>
                                      </FieldWrapper>
                                      <p className="text-center">OR</p>
                                      <FieldWrapper>
                                        <button
                                          onClick={() => {
                                            setFieldValue('password', '');
                                          }}
                                          type="submit"
                                          className="btn-primary shadow text-white py-2 px-4 rounded w-full hover:shadow-lg p-2 px-8 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
                                        >
                                          {t('login.buttons.login_with_key')}
                                        </button>
                                      </FieldWrapper>
                                    </Form>
                                  );
                                }
                              }}
                            </Formik>
                          ) : (
                            <form
                              action={`${oryAPI}/self-service/login?flow=${flow?.id}`}
                              method="POST"
                            >
                              {flow?.ui?.nodes?.map((node, key) => {
                                const { attributes, type } = node;
                                if (type === 'input') {
                                  switch (attributes?.type) {
                                    case 'hidden':
                                      return (
                                        <input
                                          key={key}
                                          type="hidden"
                                          value={attributes.value}
                                          name={attributes.name}
                                        />
                                      );
                                    case 'button':
                                      return (
                                        <button
                                          name={node?.attributes?.name}
                                          key={key}
                                          type="button"
                                          className="btn-primary w-full shadow text-white rounded p-3 px-8 outline-none mb-1 ease-linear transition-all duration-150"
                                          onClick={() => {
                                            eval(attributes?.onclick);
                                          }}
                                        >
                                          {node?.meta?.label?.text}
                                        </button>
                                      );
                                  }
                                }
                              })}
                              <input
                                type="hidden"
                                name="method"
                                value="webauthn"
                              />
                              <div className="text-center">
                                Go back to
                                <a href={LOGIN}>
                                  <span className="text-primary ml-1">
                                    login
                                  </span>
                                </a>
                              </div>
                            </form>
                          )}
                          {!webAuthnLoginHandler && (
                            <>
                              <div className="text-center">
                                <Link to={FORGOT_PASS}>
                                  <span className="text-primary">
                                    {t('login.links.forgot_password')}
                                  </span>
                                </Link>
                              </div>
                              <hr className="mt-4 mb-4" />
                              <div className="text-center">
                                <span className="">
                                  {t('login.links.dont_have_account')}
                                </span>
                              </div>
                              <div className="text-center mt-2">
                                <Link to={REGISTER}>
                                  <span className="text-primary">
                                    {t('login.links.create_account')}
                                  </span>
                                </Link>
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="flex absolute top-2 justify-center items-center mb-4 text-center w-full">
              <img src={config.SITE_LOGO_URL} alt="" className="w-72" />
            </div>
          </div>
          <div className="mx-auto flex sm:w-1/3 xl:w-1/4 w-2/3">
            <SelectLanguage />
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  flow: state.auth.loginFlow,
  loading: state.auth.loading,
});

export default withTranslation()(withRouter(connect(mapStateToProps)(Login)));
