import { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import actions from '../../actions/auth-actions';
import { FLOW_TYPES } from '../../constants/flows';
import { LOGIN } from '../../constants/routes';
import Spinner from '../utils/spinner';
import { getNodesFromFlow } from '../../utils';
import schema from 'validation/forgot-password';
import { Form, Formik } from 'formik';
import FieldWrapper from 'components/form/field-wrapper';
import { mappedIdentity } from 'validation/forgot-password';
import { fieldList } from 'validation/forgot-password';
import { withTranslation } from 'react-i18next';
import SelectLanguage from 'components/shared/select-language';

class ForgotPass extends Component {
  params = new URLSearchParams(this.props.location.search);
  flowId = this.params.get('flow');
  returnTo = this.params.get('return_to');
  email = this.params.get('email');
  async init() {
    try {
      const { dispatch } = this.props;
      dispatch(
        actions.getPassWordRecoveryFlow(
          this.flowId,
          this.returnTo,
          this.email
        )
      );
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount = async () => {
    await this.init();
  };

  initialValues = () => {
    if (this.props.flow) {
      const nodes = getNodesFromFlow(
        this.props.flow,
        fieldList,
        mappedIdentity
      );
      const findEmailNode = this.props?.flow?.ui?.nodes?.find(node => node?.attributes?.name === 'email');
      if (findEmailNode?.attributes?.value) {
        nodes['email'] = findEmailNode?.attributes?.value;
      }

      if (this.email) {
        nodes['email'] = this.email;
      }

      return nodes;
    }
  };

  handleSubmit = (formData) => {
    const { dispatch } = this.props;
    const payload = {};
    Object.keys(formData).forEach((key) => {
      payload[mappedIdentity[key]] = formData[key];
    });
    dispatch(
      actions.submitFlow(this.props?.flow, FLOW_TYPES.FORGOT_PASSWORD, payload)
    );
  };

  render() {

    const { flow, loading, t } = this.props;
    if (loading) {
      return <Spinner loading={loading} />;
    }
    if (flow) {
      return (
        <div className="min-h-screen py-6 flex flex-col justify-center relative overflow-hidden sm:py-12">
          <div className="flex items-center w-full">
            <div
              className="md:mx-auto flex p-6"
              style={{ boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)' }}
            >
              <section className="relative w-full pt-25">
                <div className="grid">
                  <div className="container mx-auto px-4 h-full">
                    <div className="flex content-center items-center justify-center h-full">
                      <div className="relative flex flex-col w-full mb-6 rounded-lg bg-white">
                        <div className="mb-0 px-5 py-6">
                          <div className="mb-3 text-center">
                            <h3 className="text-3xl p-2 font-bold">
                              {t('forgot_pass.title')}
                            </h3>
                          </div>
                          <div className="text-left py-4 font-14">
                            {t('forgot_pass.description')}
                          </div>
                        </div>
                        <div className="flex-auto px-4 pt-2">
                          <Formik
                            initialValues={this.initialValues()}
                            onSubmit={this.handleSubmit}
                            validationSchema={schema}
                            validateOnBlur={false}
                            validateOnChange={false}
                          >
                            {({ errors, values, handleChange }) => {
                              if (values) {
                                return (
                                  <Form>
                                    <FieldWrapper
                                      error={errors.email}
                                      required={true}
                                      title={t('forgot_pass.fields.email')}
                                      element="email"
                                    >
                                      <input
                                        value={values.email}
                                        name="email"
                                        type="text"
                                        placeholder="name@example.com"
                                        onChange={handleChange}
                                        className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                      />
                                    </FieldWrapper>

                                    <FieldWrapper>
                                      <button
                                        type="submit"
                                        className="btn-primary shadow text-white py-2 px-4 rounded w-full hover:shadow-lg p-2 px-8 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
                                      >
                                        {t('common.buttons.submit')}
                                      </button>
                                    </FieldWrapper>
                                  </Form>
                                );
                              }
                            }}
                          </Formik>
                          <hr className="mt-4 mb-4" />

                          <div className="text-center mt-2">
                            <Link to={LOGIN}>
                              <span className="text-primary">
                                {t('forgot_pass.links.login')}
                              </span>
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className='md:mx-auto flex'>
            <SelectLanguage />
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  flow: state.auth.passwordRecoveryFlow,
  loading: state.auth.loading,
});

export default withTranslation()(withRouter(connect(mapStateToProps)(ForgotPass)));

