const { Component } = require('react');

class Rejected extends Component {
  render() {
    return (<div className="min-h-screen py-6 flex flex-col justify-center relative overflow-hidden sm:py-12">
      <div className="flex items-center w-full">
        <div
          className="md:mx-auto flex p-6"
          style={{ boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)' }}
        >
          <section className="relative w-full pt-25">
            <div className="grid">
              <div className="container mx-auto  h-full">
                <div className="flex content-center items-center justify-center h-full">
                  <div className="relative flex flex-col w-full mb-6 rounded-lg bg-white">
                    <div className="mb-0">
                      <div className="flex justify-center m-auto mb-6">
                        <i className='fas fa-shield'></i>
                      </div>
                      <div className="mb-3 text-center">
                        <h3 className="text-3xl p-2 font-bold text-black">
                          Ooops!
                        </h3>
                      </div>

                      <div className="text-center text-black py-4 font-18 break-words m-auto" style={{ width: '660px' }} >
                        We haven&#39;t found your account, it looks like the account you entered is not suitable for accessing our site.
                      </div>
                    </div>
                    <button
                      type="submit"
                      className=" btn-primary shadow uppercase text-white py-2 px-4 rounded w-full hover:shadow-lg p-2 px-8 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>)
  }
}

export default Rejected;