import {
  ACCOUNT_LIST,
  ADMIN_HOME,
  IDENTITIES_LIST,
  PERSONAL_INFO,
  PERSONAL_INFO_VERIFICATION,
} from '../../constants/routes';
import { getHistory } from '../../store';
import { toast } from 'react-toastify'
import showToast from './toast';
const FLOW_ERRORS = [
  'self_service_flow_return_to_forbidden',
  'self_service_flow_expired',
  'security_csrf_violation',
  'security_identity_mismatch',
];
const refreshAuthSession = () => {
  const validRoutes = [
    PERSONAL_INFO,
    PERSONAL_INFO_VERIFICATION,
    ADMIN_HOME,
    IDENTITIES_LIST,
    ACCOUNT_LIST,
  ];
  let pathName = getHistory().location.pathname;
  if (!validRoutes.includes(pathName)) {
    pathName = null;
  }
  showToast(null, {
    text: 'Session is expired, please login again to continue',
    type: 'error',
  });
  console.log(pathName);
  getHistory().push({
    pathname: '/login',
    search: pathName
      ? `?${new URLSearchParams({ return_to: getHistory().location.pathname })}`
      : '',
  });
};

const refreshFlowSession = (flowType) => {
  getHistory().push({ pathname: flowType });
  showToast(null, {
    text: 'Session Expired please try again',
    type: 'warning',
  });
};

export const handleFlowError = async (
  err,
  flowType,
  action = undefined,
  dispatch = undefined
) => {


  if (err?.response?.data?.ui) {
    showToast(err?.response?.data);
  }

  switch (err?.response?.data?.error?.id) {
    case 'session_already_available':
      getHistory().push('/home');
      return;
    case 'session_aal2_required':
      getHistory().push(err.response?.data.redirect_browser_to);
      return;
    case 'session_refresh_required':
      window.location.href = err.response?.data.redirect_browser_to;
      return;
    case 'self_service_flow_return_to_forbidden':
      getHistory().push(flowType);
      return;
    case 'self_service_flow_expired':
      if (dispatch) {
        dispatch(action(err.response.data?.id));
      }
      refreshFlowSession(flowType);
      return;
    case 'security_csrf_violation':
      if (dispatch) {
        dispatch(action(err.response.data?.id));
      }
      refreshFlowSession(flowType);
      return;
    case 'security_identity_mismatch':
      getHistory().push(flowType);
      if (dispatch) {
        dispatch(action(err.response.data?.id));
      }
      return;
    case 'browser_location_change_required':
      window.location.href = err.response?.data.redirect_browser_to;
      return;
    case 'session_inactive':
      refreshAuthSession(action);
      return;
  }
  switch (err.response?.status) {
    case 410:
      getHistory().push(flowType);
      return;
    case 401:
      refreshAuthSession(action);
      return;
  }

  if (err?.response?.data?.id && dispatch && action) {
    await dispatch(action());
    getHistory().push(flowType);
  }
};

export const getFlowErrorCode = (err) => {
  return FLOW_ERRORS.find((error) => error === err?.response.data.error.id);
};

export class FlowError extends Error {
  constructor({ id, message }) {
    super(message);
    this.response = {
      data: {
        id: id
      }
    }

  }
}

export class ClientNotConfiguredError extends Error {
  constructor(message = 'No client site configured, please ask site administrator to create and configure one.', { showToast = true } = {}) {
    super(message);
    if (showToast) {
      toast.error(message);
    }
  }
}

export class MasterConnectionFailedError extends Error {
  constructor(message = 'Connection to master server has failed, please try again.', { showToast = true } = {}) {
    super(message);
    if (showToast) {
      toast.error(message);
    }
  }
}

export class MasterAuthenticationFailedError extends Error {
  constructor(message = 'Authentication to the  master server has failed, please try again.', { showToast = true } = {}) {
    super(message);
    if (showToast) {
      toast.error(message);
    }
  }
}