import * as yup from 'yup';
const passwordExp = /^(?=.*[0-9])(?=.*[!@#$%^&*?])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*?]{8,}$/;

const schema = yup.object().shape({
  email: yup.string().required('Email is required').email('Invalid email'),
  password: yup
    .string()
    .required('Password is required')
    .matches(
      passwordExp,
      'Password must be at least 8 characters long that include at least 1 lowercase character, 1 uppercase character, 1 number and 1 special character in (!@#$%^&*?)'
    ),
  confirm_password: yup
    .string()
    .required('Confirm password is required')
    .oneOf(
      [yup.ref('password'), null],
      'Password and confirm password did not match'
    ),
  first_name: yup.string(),
  last_name: yup.string(),
  birth_date: yup.date(),
  username: yup.string(),
  display_name: yup.string(),
  address: yup.object({
    area: yup.string(),
    neighbourhood: yup.string()
  }),
  phone: yup.object({
    verified: yup.boolean().default(false),
    number: yup.number(),
    country_code: yup.string(),
    dial_code: yup.string(),
  }),
  default_client: yup.object({
    client_id: yup.string(),
    client_name: yup.string(),
    client_uri: yup.string(),
    client_secret: yup.string(),
    scope: yup.string(),
    redirect_uris: yup.array()
  }),
  csrf_token: yup.string().required(),
  method: yup.string().required().default('password'),
  smsNotification: yup.boolean().default(false),
  isAdmin: yup.boolean().default(false),
});

const fields = () => {
  const field = {};
  Object.keys(schema.fields).forEach(
    (fieldName) => (field[fieldName] = fieldName)
  );
  return field;
};

export const mappedIdentity = {
  email: 'traits.email',
  password: 'password',
  confirm_password: 'traits.confirm_password',
  first_name: 'traits.first_name',
  last_name: 'traits.last_name',
  birth_date: 'traits.birth_date',
  display_name: 'traits.display_name',
  address: 'traits.address',
  phone: 'traits.phone',
  smsNotification: 'traits.sms_notification',
  csrf_token: 'csrf_token',
  method: 'method',
  username: 'traits.username',
  default_client: 'traits.default_client',
  isAdmin: 'traits.isAdmin'
};

export const fieldList = Object.keys(schema.fields);
export { fields };
export default schema;
