import FieldWrapper from 'components/form/field-wrapper';
import Spinner from 'components/utils/spinner';
import { PERSONAL_INFO } from 'constants/routes';
import { Formik, Form } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router';
import * as yup from 'yup';
import twilioActions from '../../actions/twilio-actions';

class VerifyOtp extends React.Component {
  schema = yup.object().shape({
    to: yup.string().required('Phone number not found'),
    code: yup.string().required('OTP is required'),
  });

  initialValues = () => {
    return {
      code: '',
      to: this.props.location.state?.phone,
    };
  };

  verifyOtp = async (formData) => {
    const { dispatch } = this.props;
    await dispatch(twilioActions.verifyOtp(formData, PERSONAL_INFO));
  };

  sendOtp = async (phone) => {
    try {
      const { dispatch } = this.props;
      dispatch(twilioActions.sendOtp(phone));
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { loading, verifyingOtp } = this.props;

    if (!this.props.location.state?.phone) {
      return <Redirect to={PERSONAL_INFO} />;
    }

    if (loading || verifyingOtp) {
      return <Spinner loading={true} />;
    } else {
      return (
        <div className="min-h-screen py-6 flex flex-col justify-center items-center relative overflow-hidden sm:py-12  w-full">
          <div className="flex  justify-center items-center w-full ">
            <div
              className="md:mx-auto md:w-1/3 mx-5 w-full flex p-6 "
              style={{ boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)' }}
            >
              <section className="relative w-full pt-25">
                <div className="grid">
                  <div className="container mx-auto h-full">
                    <div className="flex content-center items-center justify-center h-full">
                      <div className="relative flex flex-col w-full mb-6 rounded-lg bg-white">
                        <div className="mb-0 px-5 pt-5">
                          <div className="text-center">
                            <h3 className="text-3xl p-2 font-bold">
                              Enter OTP
                            </h3>
                          </div>
                        </div>
                        <div className="py-4 px-3 font-14 text-center">
                          Check your phone for the OTP
                        </div>
                        <div className="flex-auto px-4 pt-2">
                          <Formik
                            initialValues={this.initialValues()}
                            onSubmit={this.verifyOtp}
                            validateOnBlur={false}
                            validateOnChange={false}
                            validationSchema={this.schema}
                          >
                            {({ values, handleChange, errors }) => {
                              if (values) {
                                return (
                                  <Form>
                                    <FieldWrapper error={errors.code}>
                                      <input
                                        value={values.code}
                                        name="code"
                                        type="text"
                                        placeholder="6 Digit OTP"
                                        onChange={handleChange}
                                        className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                      />
                                    </FieldWrapper>
                                    <FieldWrapper>
                                      <button
                                        type="button"
                                        onClick={() => this.sendOtp(values?.to)}
                                        className="appearance-none block  text-primary   px-3  leading-tight focus:outline-none hover:text-gray-700 text-sm"
                                      >
                                        Resent Code
                                      </button>
                                    </FieldWrapper>

                                    <FieldWrapper>
                                      <button
                                        type="submit"
                                        className="btn-primary shadow text-white py-2 px-4 rounded w-full hover:shadow-lg p-2 px-8 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
                                      >
                                        Verify
                                      </button>
                                    </FieldWrapper>
                                  </Form>
                                );
                              }
                            }}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  verifyingOtp: state.twilio.loading,
  loading: state.oryAdmin.loading,
  user: state.auth.user,
});
export default withRouter(connect(mapStateToProps)(VerifyOtp));

