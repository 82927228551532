import { toast } from 'react-toastify';
import adminActions from './ory-admin-actions';
import oryEnv from '../api/ory/env';
import {
  getConnectedUsers,
  setDefaultAccount,
  uploadProfileImage,
  getDefaultAccount,
  getNetworkConfig,
  registerBusiness,
  getMasterConfig,
} from '../api/bba/service';
import { getHistory } from '../store';
import ketoActions from './keto-actions';
import { PERSONAL_INFO } from 'constants/routes';
import { siteAccessConfig, businessConfig, cultureConfig, publicBusinessConfig, publicCultureConfig } from '../api/keto/config';
import { REGISTER } from 'constants/routes';
import env from '../api/ory/env';
import actions from './auth-actions';
import { FlowError } from 'components/ory/errors';
import { ClientNotConfiguredError } from 'components/ory/errors';



const bbaActions = {
  REGISTER_BUSINESS_STARTED: 'REGISTER_BUSINESS_STARTED',
  REGISTER_BUSINESS_ERROR: 'REGISTER_BUSINESS_ERROR',
  REGISTER_BUSINESS_SUCCESS: 'REGISTER_BUSINESS_SUCCESS',
  GET_CONNECTED_USERS_STARTED: 'GET_CONNECTED_USERS_STARTED',
  GET_CONNECTED_USERS_SUCCESS: 'GET_CONNECTED_USERS_SUCCESS',
  GET_CONNECTED_USERS_FAILED: 'GET_CONNECTED_USERS_FAILED',
  PROFILE_PICTURE_UPLOAD_STARTED: 'PROFILE_PICTURE_UPLOAD_STARTED',
  PROFILE_PICTURE_UPLOAD_SUCCESS: 'PROFILE_PICTURE_UPLOAD_SUCCESS',
  PROFILE_PICTURE_UPLOAD_ERROR: 'PROFILE_PICTURE_UPLOAD_ERROR',
  GET_DEFAULT_ACCOUNT_STARTED: 'GET_DEFAULT_ACCOUNT_STARTED',
  GET_DEFAULT_ACCOUNT_SUCCESS: 'GET_DEFAULT_ACCOUNT_SUCCESS',
  GET_DEFAULT_ACCOUNT_ERROR: 'GET_DEFAULT_ACCOUNT_ERROR',
  SET_DEFAULT_ACCOUNT_STARTED: 'SET_DEFAULT_ACCOUNT_STARTED',
  SET_DEFAULT_ACCOUNT_SUCCESS: 'SET_DEFAULT_ACCOUNT_SUCCESS',
  SET_DEFAULT_ACCOUNT_ERROR: 'SET_DEFAULT_ACCOUNT_ERROR',
  GET_NETWORK_CONFIG_STARTED: 'GET_NETWORK_CONFIG_STARTED',
  GET_NETWORK_CONFIG_SUCCESS: 'GET_NETWORK_CONFIG_SUCCESS',
  GET_NETWORK_CONFIG_ERROR: 'GET_NETWORK_CONFIG_ERROR',
  GET_MASTER_CONFIG_STARTED: 'GET_MASTER_CONFIG_STARTED',
  GET_MASTER_CONFIG_SUCCESS: 'GET_MASTER_CONFIG_SUCCESS',
  GET_MASTER_CONFIG_ERROR: 'GET_MASTER_CONFIG_ERROR',

  registerBusiness: (
    identity,
    baseUrl,
    returnUrl = undefined,
    masterClientId = undefined
  ) => async (dispatch) => {

    try {
      if (!baseUrl) {
        getHistory().push({ pathname: REGISTER });
        throw new ClientNotConfiguredError();
      }

      dispatch({ type: bbaActions.REGISTER_BUSINESS_STARTED });
      const response = await registerBusiness(identity, baseUrl);
      if (response?.data?.success && response?.data?.user_id) {
        dispatch({
          type: bbaActions.REGISTER_BUSINESS_SUCCESS,
          payload: response?.data,
        });
        if (!masterClientId) {
          toast.success('New Business account created');
          return;
        }
        if (masterClientId && identity?.traits?.email) {
          const { namespace, relation } = siteAccessConfig;
          const ketoOptions = {
            showToast: false
          };
          dispatch(
            ketoActions.createRole([
              {
                action: 'insert',
                relation_tuple: {
                  namespace,
                  relation,
                  subject_id: identity?.traits?.email[0],
                  object: masterClientId,
                },
              },
              {
                action: 'insert',
                relation_tuple: {
                  ...cultureConfig,
                  subject_id: identity?.traits?.email[0],
                  object: masterClientId,
                },
              },
              {
                action: 'insert',
                relation_tuple: {
                  ...cultureConfig,
                  subject_id: identity?.traits?.email[0],
                  object: '7b585aa6-b3c9-4a41-ad97-cef536c18d90',
                },
              },
              {
                action: 'insert',
                relation_tuple: {
                  ...businessConfig,
                  subject_id: identity?.traits?.email[0],
                  object: masterClientId,
                },
              },
              {
                action: 'insert',
                relation_tuple: {
                  ...businessConfig,
                  subject_id: identity?.traits?.email[0],
                  object: 'aa45709d-8d89-4929-8060-2289d65104eb'
                },
              },
              {
                action: 'insert',
                relation_tuple: {
                  subject_id: identity?.traits?.email[0],
                  object: masterClientId,
                  namespace,
                  relation: 'readWrite'
                },
              }
            ], ketoOptions)
          );
        }
        let payload = {
          traits: {
            ...identity?.traits,
          },
          schema_id: env.DEFAULT_SCHEMA_ID,
          state: identity?.state,
        };
        payload.traits['wp_user_id'] = response?.data?.user_id;
        payload.traits['wp_username'] = response?.data?.username;

        const updateOptions = {
          redirectTo: returnUrl,
          message: {
            onSuccess: 'Account Created!'
          },
        };

        await dispatch(adminActions.updateIdentity(identity?.id, payload, updateOptions));
        await dispatch(actions.getSession());
      } else {
        throw Error(response?.data?.message);
      }

    } catch (error) {
      console.log(error?.message);
      toast.error(error?.message);
      await dispatch({ type: bbaActions.REGISTER_BUSINESS_ERROR });
      if (masterClientId) {
        await dispatch(adminActions.deleteIdentity(identity?.id, { showToast: false }));
        throw new FlowError({ id: 'flow_expired', message: 'create_business_account_error' });
      }
    }
  },

  getConnectedUsers: (ory_identity_id, baseUrl) => async (dispatch) => {
    try {
      dispatch({ type: bbaActions.GET_CONNECTED_USERS_STARTED });
      const response = await getConnectedUsers(ory_identity_id, baseUrl);
      dispatch({
        type: bbaActions.GET_CONNECTED_USERS_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({ type: bbaActions.GET_CONNECTED_USERS_FAILED });
    }
  },

  uploadProfilePicture: (user, image, baseUrl) => async (dispatch) => {
    try {
      const { identity } = user;
      dispatch({ type: bbaActions.PROFILE_PICTURE_UPLOAD_STARTED });
      const response = await uploadProfileImage(identity?.id, image, baseUrl);
      if (response.message === 'Listing successfully updated!') {
        let payload = {
          traits: {
            ...identity?.traits,
          },
          schema_id: oryEnv.DEFAULT_SCHEMA_ID,
          state: identity?.state,
        };
        payload.traits['picture'] = response?.imagePath;
        await dispatch(
          adminActions.updateIdentity(identity?.id, payload, {
            redirectTo: PERSONAL_INFO,
          })
        );
        dispatch({ type: bbaActions.PROFILE_PICTURE_UPLOAD_SUCCESS });
      } else {
        toast.error('Something went wrong while uploading profile picture');
      }
    } catch (error) {
      dispatch({ type: bbaActions.PROFILE_PICTURE_UPLOAD_ERROR });
    }
  },

  setDefaultAccount: (userId, bbaId, baseUrl) => async (dispatch) => {
    try {
      dispatch({
        type: bbaActions.SET_DEFAULT_ACCOUNT_STARTED,
      });
      const response = await setDefaultAccount(userId, bbaId, baseUrl);
      if (response.success) {
        toast.success(response.message);
        dispatch({
          type: bbaActions.SET_DEFAULT_ACCOUNT_SUCCESS,
        });
      } else {
        toast.error(response.message);
        dispatch({
          type: bbaActions.SET_DEFAULT_ACCOUNT_ERROR,
        });
      }
    } catch (error) {
      dispatch({
        type: bbaActions.SET_DEFAULT_ACCOUNT_ERROR,
      });
    }
  },
  getDefaultAccount: (userId, baseUrl) => async (dispatch) => {
    try {
      dispatch({ type: bbaActions.GET_DEFAULT_ACCOUNT_STARTED });
      const response = await getDefaultAccount(userId, baseUrl);
      console.log(response);
      dispatch({ type: bbaActions.GET_DEFAULT_ACCOUNT_SUCCESS });
    } catch (error) {
      dispatch({ type: bbaActions.GET_DEFAULT_ACCOUNT_ERROR });
    }
  },

  getNetworkConfig: () => async (dispatch) => {
    try {
      dispatch({
        type: bbaActions.GET_NETWORK_CONFIG_STARTED
      });
      const response = await getNetworkConfig();
      dispatch({ type: bbaActions.GET_NETWORK_CONFIG_SUCCESS, payload: response });

    } catch (error) {
      dispatch({
        type: bbaActions.GET_NETWORK_CONFIG_ERROR
      });
    }
  },

  getMasterConfig: () => async (dispatch) => {
    try {
      dispatch({
        type: bbaActions.GET_MASTER_CONFIG_STARTED
      });
      const response = await getMasterConfig();
      dispatch({
        type: bbaActions.GET_MASTER_CONFIG_SUCCESS,
        payload: response
      });
    } catch (error) {
      dispatch({
        type: bbaActions.GET_MASTER_CONFIG_ERROR
      });
    }
  }
};

export default bbaActions;

