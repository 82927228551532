import ory from '../api/ory/config';
import { getFlowErrorCode, handleFlowError } from '../components/ory/errors';
import showToast from '../components/ory/toast';
import { FLOW_TYPES } from '../constants/flows';
import { mappedIdentity } from 'validation/forgot-password';
import { fieldList } from 'validation/forgot-password';
import {
  ERRORS,
  FORGOT_PASS,
  HOME,
  LOGIN,
  PERSONAL_INFO,
  REGISTER,
  RESET_PASSWORD,
  TERMS_AND_CONDITIONS,
  VERIFY,
} from '../constants/routes';
import { getHistory } from '../store';
import bbaActions from './bba-actions';
import { getNodesFromFlow } from 'utils';
const prefix = 'AUTH';
const actions = {
  LOGIN_FLOW_STARTED: `${prefix}_LOGIN_FLOW_STARTED`,
  LOGIN_FLOW_ERROR: `${prefix}_LOGIN_FLOW_ERROR`,
  LOGIN_FLOW_SUCCESS: `${prefix}_LOGIN_FLOW_SUCCESS`,
  LOGIN_FLOW_RESET: `${prefix}_LOGIN_FLOW_RESET`,
  SIGNUP_FLOW_STARTED: `${prefix}_SIGNUP_FLOW_STARTED`,
  SIGNUP_FLOW_ERROR: `${prefix}_SIGNUP_FLOW_ERROR`,
  SIGNUP_FLOW_SUCCESS: `${prefix}_SIGNUP_FLOW_SUCCESS`,
  SIGNUP_FLOW_RESET: `${prefix}_SIGNUP_FLOW_RESET`,
  SIGNUP_BUSINESS_FLOW_STARTED: `${prefix}_SIGNUP_BUSINESS_FLOW_STARTED`,
  SIGNUP_BUSINESS_FLOW_ERROR: `${prefix}_SIGNUP_BUSINESS_FLOW_ERROR`,
  SIGNUP_BUSINESS_FLOW_SUCCESS: `${prefix}_SIGNUP_BUSINESS_FLOW_SUCCESS`,
  SIGNUP_BUSINESS_FLOW_RESET: `${prefix}_SIGNUP_BUSINESS_FLOW_RESET`,
  ERROR_FLOW_STARTED: `${prefix}_ERROR_FLOW_STARTED`,
  ERROR_FLOW_ERROR: `${prefix}_ERROR_FLOW_ERROR`,
  ERROR_FLOW_SUCCESS: `${prefix}_ERROR_FLOW_SUCCESS`,
  ERROR_FLOW_RESET: `${prefix}_ERROR_FLOW_RESET`,
  GET_USER_SESSION_STARTED: `${prefix}_GET_USER_SESSION_STARTED`,
  GET_USER_SESSION_SUCCESS: `${prefix}_GET_USER_SESSION_SUCCESS`,
  GET_USER_SESSION_ERROR: `${prefix}_GET_USER_SESSION_ERROR`,
  PASSWORD_RECOVERY_FLOW_STARTED: `${prefix}_PASSWORD_RECOVERY_FLOW_STARTED`,
  PASSWORD_RECOVERY_FLOW_SUCCESS: `${prefix}_PASSWORD_RECOVERY_FLOW_SUCCESS`,
  PASSWORD_RECOVERY_FLOW_ERROR: `${prefix}_PASSWORD_RECOVERY_FLOW_ERROR`,
  SETTINGS_FLOW_STARTED: `${prefix}_SETTINGS_FLOW_STARTED`,
  SETTINGS_FLOW_SUCCESS: `${prefix}_SETTINGS_FLOW_SUCCESS`,
  SETTINGS_FLOW_ERROR: `${prefix}_SETTINGS_FLOW_ERROR`,
  SUBMIT_FLOW_STARTED: `${prefix}_SUBMIT_FLOW_STARTED`,
  SUBMIT_FLOW_SUCCESS: `${prefix}_SUBMIT_FLOW_SUCCESS`,
  SUBMIT_FLOW_ERROR: `${prefix}_SUBMIT_FLOW_ERROR`,
  LOGOUT_FLOW_STARTED: `${prefix}_LOGOUT_FLOW_STARTED`,
  LOGOUT_FLOW_SUCCESS: `${prefix}_LOGOUT_FLOW_SUCCESS`,
  LOGOUT_FLOW_ERROR: `${prefix}_LOGOUT_FLOW_ERROR`,
  ROUTER_LOCATION_CHANGE: '@@router/LOCATION_CHANGE',
  VERIFICATION_FLOW_STARTED: `${prefix}_VERIFICATION_FLOW_STARTED`,
  VERIFICATION_FLOW_ERROR: `${prefix}_VERIFICATION_FLOW_ERROR`,
  VERIFICATION_FLOW_SUCCESS: `${prefix}_VERIFICATION_FLOW_SUCCESS`,

  /**
   * @summary Initialize OR Get Login Flow for APIs, Services, Apps, ...
   * @param {string} flowId The Flow ID  The value for this parameter comes from &#x60;request&#x60; URL Query parameter sent to your application (e.g. &#x60;/verification?flow&#x3D;abcde&#x60;).
   * @param {string} [aal] Request a Specific AuthenticationMethod Assurance Level  Use this parameter to upgrade an existing session\&#39;s authenticator assurance level (AAL). This allows you to ask for multi-factor authentication. When an identity sign in using e.g. username+password, the AAL is 1. If you wish to \&quot;upgrade\&quot; the session\&#39;s security by asking the user to perform TOTP / WebAuth/ ... you would set this to \&quot;aal2\&quot;.
   * @param {string} [returnTo] The URL to return the browser to after the flow was completed.
   * @param {boolean} [refresh] Refresh a login session  If set to true, this will refresh an existing login session by asking the user to sign in again. This will reset the authenticated_at time of the session.
   * @returns {SelfServiceLoginFlow} Returns SelfServiceLoginFlow
   */
  getLoginFlow: (flowId, aal, returnTo, refresh) => async (dispatch) => {
    try {
      dispatch({
        type: actions.LOGIN_FLOW_STARTED,
      });
      // Loads webauthn script
      const script = await ory.getWebAuthnJavaScript();
      eval(script?.data);
      let res;
      if (flowId) {
        res = await ory.getSelfServiceLoginFlow(String(flowId));
      } else {
        res = await ory.initializeSelfServiceLoginFlowForBrowsers(
          Boolean(refresh),
          aal ? String(aal) : undefined,
          returnTo ? String(returnTo) : undefined
        );
      }
      if (res && res.data) {
        showToast(res.data);
        dispatch({
          type: actions.LOGIN_FLOW_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      if (getFlowErrorCode(error)) {
        dispatch({
          type: actions.LOGIN_FLOW_RESET,
        });
      }
      dispatch({
        type: actions.LOGIN_FLOW_ERROR,
      });
      handleFlowError(error, LOGIN);
    }
  },

  /**
   * @summary Initialize OR Get Register Flow for APIs, Services, Apps, ...
   * @param {string} flowId The Flow ID  The value for this parameter comes from &#x60;request&#x60; URL Query parameter sent to your application (e.g. &#x60;/verification?flow&#x3D;abcde&#x60;).
   * @param {string} [returnTo] The URL to return the browser to after the flow was completed.
   * @returns {SelfServiceRegisterFlow} Returns SelfServiceRegisterFlow
   */
  getSignUpFlow: (flowId, returnTo) => async (dispatch) => {
    try {
      dispatch({
        type: actions.SIGNUP_FLOW_STARTED,
      });
      let res;
      if (flowId) {
        res = await ory.getSelfServiceRegistrationFlow(String(flowId));
      } else {
        res = await ory.initializeSelfServiceRegistrationFlowForBrowsers(
          returnTo ? String(returnTo) : undefined
        );
      }
      if (res && res.data) {
        showToast(res.data);
        await dispatch({
          type: actions.SIGNUP_FLOW_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      if (getFlowErrorCode(error)) {
        dispatch({
          type: actions.SIGNUP_FLOW_RESET,
        });
      }

      handleFlowError(error, REGISTER);

      dispatch({
        type: actions.SIGNUP_FLOW_ERROR,
      });
    }
  },

  getBusinessSignUpFlow: (flowId, returnTo) => async (dispatch) => {
    try {
      dispatch({
        type: actions.SIGNUP_BUSINESS_FLOW_STARTED,
      });
      let res;
      if (flowId) {
        res = await ory.getSelfServiceRegistrationFlow(String(flowId));
      } else {
        res = await ory.initializeSelfServiceRegistrationFlowForBrowsers(
          returnTo ? String(returnTo) : undefined
        );
      }
      if (res && res.data) {
        showToast(res.data);
        await dispatch({
          type: actions.SIGNUP_BUSINESS_FLOW_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      if (getFlowErrorCode(error)) {
        dispatch({
          type: actions.SIGNUP_BUSINESS_FLOW_RESET,
        });
      }

      handleFlowError(error, REGISTER);

      dispatch({
        type: actions.SIGNUP_BUSINESS_FLOW_ERROR,
      });
    }
  },

  /**
   * @summary Initialize OR Get Password Recovery Flow for APIs, Services, Apps, ...
   * @param {string} flowId The Flow ID  The value for this parameter comes from &#x60;request&#x60; URL Query parameter sent to your application (e.g. &#x60;/verification?flow&#x3D;abcde&#x60;).
   * @param {string} [returnTo] The URL to return the browser to after the flow was completed.
   * @returns {SelfServiceRecoveryFlow} Returns SelfServiceRecoveryFlow
   */
  getPassWordRecoveryFlow: (flowId, returnTo, email = undefined) => async (dispatch) => {
    try {
      dispatch({
        type: actions.PASSWORD_RECOVERY_FLOW_STARTED,
      });
      let res;
      if (flowId) {
        res = await ory.getSelfServiceRecoveryFlow(String(flowId));
      } else {
        res = await ory.initializeSelfServiceRecoveryFlowForBrowsers(
          returnTo ? String(returnTo) : undefined
        );
      }
      if (res && res.data) {
        showToast(res.data);
        dispatch({
          type: actions.PASSWORD_RECOVERY_FLOW_SUCCESS,
          payload: res.data,
        });
        if (email) {
          const payload = getNodesFromFlow(
            res?.data,
            fieldList,
            mappedIdentity
          );
          payload['email'] = email;
          await dispatch(actions.submitFlow(res.data, FLOW_TYPES.FORGOT_PASSWORD, payload))
        }
      }
    } catch (error) {
      if (error?.response?.data?.error?.id === 'session_already_available' && email) {
        try {
          dispatch({
            type: actions.LOGOUT_FLOW_STARTED,
          });
          const res = await ory.createSelfServiceLogoutFlowUrlForBrowsers();
          if (res.data.logout_token) {
            await ory.submitSelfServiceLogoutFlow(res?.data?.logout_token);
            window.location.reload();
            dispatch({
              type: actions.LOGOUT_FLOW_SUCCESS,
            });
          }
        } catch (error) {
          dispatch({
            type: actions.LOGOUT_FLOW_ERROR
          });
          handleFlowError(error, LOGIN);
        }
      } else {
        handleFlowError(error, FORGOT_PASS);
        dispatch({
          type: actions.PASSWORD_RECOVERY_FLOW_ERROR,
        });
      }
    }
  },

  /**
   * @summary Initialize OR Get Settings Flow for APIs, Services, Apps, ...
   * @param {string} flowId The Flow ID  The value for this parameter comes from the request, URL Query parameter sent to your application (e.g. &#x60;/verification?flow&#x3D;abcde&#x60;).
   * @param {string} [returnTo] The URL to return the browser to after the flow was completed.
   * @returns {SelfServiceSettingsFlow} Returns SelfServiceSettingsFlow
   */
  getSettingsFlow: (flowId, returnTo) => async (dispatch) => {
    try {
      dispatch({
        type: actions.SETTINGS_FLOW_STARTED,
      });
      // Loads webauthn script
      const script = await ory.getWebAuthnJavaScript();
      eval(script?.data);
      let res;
      if (flowId) {
        res = await ory.getSelfServiceSettingsFlow(String(flowId));
      } else {
        res = await ory.initializeSelfServiceSettingsFlowForBrowsers(
          returnTo ? String(returnTo) : undefined
        );
      }
      if (res && res.data) {
        showToast(res.data);
        dispatch({
          type: actions.SETTINGS_FLOW_SUCCESS,
          payload: res.data,
        });
      }
    } catch (error) {
      handleFlowError(error, PERSONAL_INFO);
      dispatch({
        type: actions.SETTINGS_FLOW_ERROR,
      });
    }
  },

  /**
   * @summary Get Self-Service Errors
   * @param {string} errorId errorId
   * @returns {SelfServiceError} Returns SelfService Error
   */
  getErrorFlow: (errorId) => async (dispatch) => {
    dispatch({
      type: actions.ERROR_FLOW_STARTED,
    });
    try {
      if (errorId) {
        const res = await ory.getSelfServiceError(String(errorId));
        if (res && res.data) {
          await dispatch({
            type: actions.ERROR_FLOW_SUCCESS,
            payload: res.data,
          });
        }
      }
    } catch (error) {
      if (getFlowErrorCode(error)) {
        dispatch({
          type: actions.ERROR_FLOW_RESET,
        });
      }

      handleFlowError(error, ERRORS);
      dispatch({
        type: actions.ERROR_FLOW_ERROR,
      });
    }
  },

  /**
   * @summary Initialize OR Get Settings Flow for APIs, Services, Apps, ...
   * @param {string} flowId The Flow ID  The value for this parameter comes from &#x60;request&#x60; URL Query parameter sent to your application (e.g. &#x60;/verification?flow&#x3D;abcde&#x60;).
   * @param {string} [returnTo] The URL to return the browser to after the flow was completed.
   * @returns {SelfServiceVerificationFlow} Returns SelfServiceVerificationFlow
   */
  getAccountVerficationFlow: (flowId, returnTo) => async (dispatch) => {
    dispatch({
      type: actions.VERIFICATION_FLOW_STARTED,
    });
    try {
      let res;
      if (flowId) {
        res = await ory.getSelfServiceVerificationFlow(String(flowId));
      } else {
        res = await ory.initializeSelfServiceVerificationFlowForBrowsers(
          returnTo ? String(returnTo) : undefined
        );
      }
      if (res && res.data) {
        showToast(res.data);
        dispatch({
          type: actions.VERIFICATION_FLOW_SUCCESS,
          payload: res.data,
        });
      }
      if (returnTo) {
        getHistory().push({ pathname: returnTo });
      }
    } catch (error) {
      handleFlowError(error, VERIFY);
      dispatch({
        type: actions.VERIFICATION_FLOW_ERROR,
      });
    }
  },

  /**
   * @summary Fetches the Sesssion of logged in user
   * @param {Session} session if session is provided, it returns the session to be set in a state by reducers
   * @returns {Session} Session of logged in user
   */
  getSession: (session = undefined) => async (dispatch) => {
    dispatch({
      type: actions.GET_USER_SESSION_STARTED,
    });

    try {
      if (session) {
        dispatch({
          type: actions.GET_USER_SESSION_SUCCESS,
          payload: session,
        });
        getHistory().push(HOME);
      } else {
        const session = await ory.toSession();
        if (session && session.data) {
          dispatch({
            type: actions.GET_USER_SESSION_SUCCESS,
            payload: session.data,
          });
        }
      }
    } catch (error) {
      handleFlowError(error, LOGIN);
      dispatch({
        type: actions.GET_USER_SESSION_ERROR,
      });
    }
  },

  /**
   * @summary Logs out currently logged in user.
   */
  logout: () => async (dispatch) => {
    try {
      dispatch({
        type: actions.LOGOUT_FLOW_STARTED,
      });
      const res = await ory.createSelfServiceLogoutFlowUrlForBrowsers();
      if (res.data.logout_token) {
        dispatch({
          type: actions.LOGOUT_FLOW_SUCCESS,
          payload: res.data,
        });
        await dispatch(
          actions.submitFlow(null, FLOW_TYPES.LOGOUT, res.data.logout_token)
        );
      }
    } catch (err) {
      handleFlowError(err, LOGIN);
    }
  },

  /**
   * @summary Submit handler for all flows
   * @param {flow} flow for which submit handler is invoked
   * @param {string} type type of the flow
   * @param {FormData} values data to be submitted
   * @returns
   */
  submitFlow: (flow, type, values, extraParams = {}) => async (dispatch) => {
    let response;
    let redirectOnError;
    let action;
    let redirectOnSuccess;
    let payload;
    dispatch({ type: actions.SUBMIT_FLOW_STARTED });
    try {
      switch (type) {
        case FLOW_TYPES.LOGIN:
          redirectOnError = LOGIN;
          action = actions.getLoginFlow;
          response = await ory.submitSelfServiceLoginFlow(flow.id, values);
          payload = { user: response?.data?.session };
          redirectOnSuccess = TERMS_AND_CONDITIONS;
          break;
        case FLOW_TYPES.REGISTER:
          redirectOnError = REGISTER
          response = undefined;
          action = actions.getSignUpFlow;
          response = await ory.submitSelfServiceRegistrationFlow(
            flow.id,
            values
          );

          redirectOnSuccess = undefined;
          if (response.status == 200) {
            await dispatch(
              bbaActions.registerBusiness(
                response.data.identity,
                response?.data?.session?.identity?.traits?.default_client?.client_uri,
                TERMS_AND_CONDITIONS,
                extraParams?.master_client_id
              )
            );
          }
          break;
        case FLOW_TYPES.FORGOT_PASSWORD:
          redirectOnError = FORGOT_PASS;
          action = actions.getPassWordRecoveryFlow;
          response = await ory.submitSelfServiceRecoveryFlow(flow.id, values);
          showToast(response.data);
          payload = { passwordRecoveryFlow: response.data };
          redirectOnSuccess = FORGOT_PASS;
          break;
        case FLOW_TYPES.CHANGE_PASSWORD:
          redirectOnError = RESET_PASSWORD;
          redirectOnSuccess = `${LOGIN}/?refresh=true`;
          action = actions.getSettingsFlow;
          response = await ory.submitSelfServiceSettingsFlow(flow.id, values);
          showToast(response.data);
          payload = { settingsFlow: response.data };
          break;
        case FLOW_TYPES.SETTINGS:
          redirectOnError = PERSONAL_INFO;
          redirectOnSuccess = PERSONAL_INFO;
          action = actions.getSettingsFlow;
          response = await ory.submitSelfServiceSettingsFlow(flow.id, values);
          showToast(response.data);
          payload = { settingsFlow: response.data };
          break;
        case FLOW_TYPES.LOGOUT:
          redirectOnError = LOGIN;
          response = await ory.submitSelfServiceLogoutFlow(values);
          showToast(null, {
            type: 'success',
            text: 'Logged out successfully!',
          });
          return getHistory().push(LOGIN);
        case FLOW_TYPES.VERIFY:
          redirectOnError = VERIFY;
          response = await ory.submitSelfServiceVerificationFlow(
            flow.id,
            values
          );
          showToast(response.data);
          redirectOnSuccess = PERSONAL_INFO;
          break;
      }

      if (flow.return_to) {
        window.location.href = flow.return_to;
      } else {
        if (redirectOnSuccess) {
          const splitURL = redirectOnSuccess?.split('?');
          if (splitURL.length > 1) {
            getHistory().push({ pathname: splitURL[0], search: splitURL[1] });
          } else {
            getHistory().push({ pathname: redirectOnSuccess });
          }
        }
      }

      dispatch({
        type: actions.SUBMIT_FLOW_SUCCESS,
        payload: payload,
      });
    } catch (error) {
      dispatch({ type: actions.SUBMIT_FLOW_ERROR });
      await handleFlowError(error, redirectOnError, action, dispatch);
    }
  },
};

export default actions;

