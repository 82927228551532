import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import actions from '../../actions/auth-actions';
import { LOGIN } from '../../constants/routes';

class Errors extends Component {
  componentDidMount = () => {
    const params = new URLSearchParams(this.props.location.search);
    const errorId = params.get('id');
    if (errorId) {
      const { dispatch } = this.props;
      dispatch(actions.getErrorFlow(errorId));
    }

  }
  render() {
    const { flow } = this.props;
    const { message } = flow?.error || {
      message: ''
    };
    return (
      message ?
        <div style={{ margin: 'auto', textAlign: 'center', fontSize: '25px' }}>
          <p style={{ color: 'red' }}>{message}</p>
          <Link to={LOGIN}> Return to home </Link>
        </div>
        : null
    );
  }
}
const mapStateToProps = state => ({
  flow: state.auth.errorFlow,
  loading: state.auth.loading
});

export default connect(
  mapStateToProps,
)(Errors)