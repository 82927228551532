
import { Component } from 'react';
import { connect } from 'react-redux';

class AccountRecoverStep2 extends Component {

  render() {
    return (
      <div className='min-h-screen py-6 flex flex-col justify-center relative overflow-hidden sm:py-12'>
        <div className='flex items-center w-full'>
          <div className="md:mx-auto flex p-8" style={{ boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)' }}>
            <section className="relative w-full pt-25">
              <div className='grid'>
                <div className="container mx-auto px-6 h-full">
                  <div className="flex content-center items-center justify-center h-full">
                    <div className="relative flex flex-col w-full mb-6 rounded-lg bg-white">
                      <div className="flex-auto px-4 py-9 pt-0">
                        <form>

                          <div className="flex flex-wrap -mx-3">
                            <div className="w-full">
                              <label className="block tracking-wide text-gray-700 font-bold mb-2" htmlFor="grid-password">
                                Email
                              </label>
                              <input className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-password" type="password" placeholder="Email or phone" />
                            </div>
                          </div>

                          <div className="flex flex-wrap -mx-3">
                            <div className="w-full">
                              <label className="block tracking-wide text-gray-700 font-bold mt-2 mb-2" htmlFor="grid-password">
                                In case you forgot your password
                              </label>
                              <div className="flex flex-wrap -mx-3">
                                <div className="w-full px-3">
                                  <div className="">
                                    <div className="mb-3 xl:w-96">
                                      <select className="form-select appearance-none block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300
                                                      rounded transition ease-in-out m-0 focus:text-gray-700 focus:backgroun-primary focus:outline-none"
                                        aria-label="Default select example">
                                        <option className="opt">{'What is your mother\'s maiden name?'}</option>
                                        <option className="opt"> {'What was your first car?'}</option>
                                        <option className="opt">What is the name of your first pet?</option>
                                        <option className="opt">What elementry school did you attend?</option>
                                        <option className="opt">What is the name of the town where you were born?</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-wrap -mx-3">
                            <div className="w-full">
                              <input className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-password" type="password" placeholder="Your Answer" />
                            </div>
                          </div>

                          <div className="flex flex-wrap -mx-3">
                            <div className="w-full">
                              <div className="flex flex-wrap -mx-3">
                                <div className="w-full px-3">
                                  <div className="">
                                    <div className="mb-3 xl:w-96">
                                      <select className="form-select appearance-none block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300
                                                      rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                                        aria-label="Default select example">
                                        <option> {'What was your first car?'}</option>
                                        <option>{'What is your mother\'s maiden name?'}</option>
                                        <option>What is the name of your first pet?</option>
                                        <option>What elementry school did you attend?</option>
                                        <option>What is the name of the town where you were born?</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-wrap -mx-3">
                            <div className="w-full">
                              <input className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-password" type="password" placeholder="Your Answer" />
                            </div>
                          </div>

                          <div className="flex flex-wrap -mx-3">
                            <div className="w-full">
                              <div className="flex flex-wrap -mx-3">
                                <div className="w-full px-3">
                                  <div className="">
                                    <div className="mb-3 xl:w-96">
                                      <select className="form-select appearance-none block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300
                                                      rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                                        aria-label="Default select example">
                                        <option>What is the name of your first pet?</option>
                                        <option> {'What was your first car?'}</option>
                                        <option>{'What is your mother\'s maiden name?'}</option>
                                        <option>What elementry school did you attend?</option>
                                        <option>What is the name of the town where you were born?</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-wrap -mx-3">
                            <div className="w-full">
                              <input className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-password" type="password" placeholder="Your Answer" />
                            </div>
                          </div>

                          <div className="flex flex-wrap mt-2 -mx-3">
                            <button className="btn-primary col uppercase text-white font-bold py-2 px-4 rounded w-full">
                              Submit
                            </button>
                          </div>

                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section >
          </div>
        </div>
      </div >
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,

)(AccountRecoverStep2);