import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import SidebarMenuItem from './SidebarMenuItem';
import { sidebarMenu } from './sidebarMenuList';
import sidebarActions from '../../actions/sidebar-actions';

function Sidebar(props) {
  const location = useLocation();
  const [activePage, setActivePage] = useState('');
  const [sidebarStyle, setSidebarStyle] = useState('');

  const { user } = props;

  useEffect(() => {
    const sidebarMenuActive = sidebarMenu.find((element) => {
      if (element.link === location.pathname) {
        return element;
      }
      return element.submenu.find(
        (element) => element.link === location.pathname
      );
    });
    if (sidebarMenuActive && sidebarMenuActive.title) {
      setActivePage(sidebarMenuActive.title);
    }
    const { sidebar } = props;
    if (sidebar.isOpen) {
      setSidebarStyle('ml-0 block');
    } else {
      setSidebarStyle('-ml-64');
    }
  });

  const toggleSidebar = () => {
    const { dispatch } = props;
    dispatch(sidebarActions.toggleSidebar());
  };

  if (user) {
    return (
      <nav
        style={{
          transition: 'all 0.5s ease',
        }}
        // className={`top-0 bottom-0 bg-white shadow-xl left-0 fixed nav-bg-color flex-row flex-nowrap md:z-10 z-9999 transform md:translate-x-0 ${sidebarStyle}`}
        className={`top-0 bottom-0 bg-white shadow-xl w-64  left-0 fixed nav-bg-color flex-row flex-nowrap md:z-10 z-9999  ${sidebarStyle}`}
      >
        <div className="md:hidden w-full flex justify-end items-center mx-2">
          <button
            className="text-white text-sm uppercase inline-block font-semibold w-6 mr-6 pt-5"
            onClick={toggleSidebar}
          >
            <i className="fas text-white fa-times text-xl"></i>
            {/* <img
              src={config.MENUICON}
              alt="cancoms"
              className="header-logo align-right w-full h-full"
            /> */}
          </button>
        </div>
        {/* {sidebar.isOpen && ( */}
        <div
          className="mt-8 md:mt-16"
          style={{
            transition: 'all 0.5s ease',
          }}
        >
          <div className="flex-col px-2 py-6 pt-3 min-h-full flex flex-wrap items-center justify-between w-full mx-auto">
            <div className="flex flex-col items-stretch opacity-100 relative h-auto z-40 items-center flex-1 rounded w-full">
              <div
                className="md:flex-col md:min-w-full flex flex-col list-none"
                style={{
                  transition: 'all 0.5s ease',
                }}
              >
                {sidebarMenu.map((index, key) => {
                  if (
                    !index.roles.includes('admin') ||
                    (index.roles.includes('admin') &&
                      user?.identity?.traits?.isAdmin)
                  ) {
                    return (
                      <div key={key}>
                        <SidebarMenuItem
                          activePage={activePage}
                          link={index.link}
                          title={index.title}
                          icon={index.icon}
                          submenu={index.submenu}
                          i18nkey={index.i18nkey}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          </div>
        </div>
        {/* )} */}
      </nav>
    );
  }
  return null;
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  errors: state.errors,
  sidebar: state.sidebar,
});

export default connect(mapStateToProps)(Sidebar);

