import { toast } from 'react-toastify';
import { createRole, getRoles } from '../api/keto/service';

const actions = {
  CREATE_ROLE_STARTED: 'CREATE_ROLE_STARTED',
  CREATE_ROLE_SUCCESS: 'CREATE_ROLE_SUCCESS',
  CREATE_ROLE_ERROR: 'CREATE_ROLE_ERROR',
  GET_ROLES_STARTED: 'GET_ROLES_STARTED',
  GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
  GET_ROLES_ERROR: 'GET_ROLES_ERROR',
  createRole: (payload, options = {}) => async (dispatch) => {
    try {
      dispatch({
        type: actions.CREATE_ROLE_STARTED,
      });
      const response = await createRole(payload);
      if (response.status === 204 && options?.showToast !== false) {
        toast.success(options?.message?.onSuccess || 'Role updated');
      }
      dispatch({
        type: actions.CREATE_ROLE_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: actions.CREATE_ROLE_ERROR,
      });
    }
  },

  getRoles: (options) => async (dispatch) => {
    try {
      dispatch({
        type: actions.GET_ROLES_STARTED,
      });
      const response = await getRoles(options);
      dispatch({
        type: actions.GET_ROLES_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: actions.GET_ROLES_ERROR,
      });
    }
  },
};

export default actions;

