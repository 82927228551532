import { connect } from 'react-redux';

function RecoveryEmail() {
  return (
    <div className="relative p-5 lg:p-10 w-full md:w-6/12 lg:w-4/12">
      <div>
        <p className="text-2xl font-bold mb-4">Recovery Email</p>
      </div>

      <div className="p-5">
        <div className="flex flex-wrap -mx-3">
          <label className="block font-14 text-sm text-gray-700 font-bold text-black">
            Email
          </label>
          <input
            type="email"
            name="email"
            className="border-1 mb-6 mt-3 focus:0 block w-full shadow-sm sm:text-sm border-gray-300 rounded"
            placeholder="johnys@example.com"
          />
        </div>

        <div className="flex flex-wrap -mx-3">
          <div className="w-full">
            <label
              className="block tracking-wide text-gray-700 font-bold mt-2 mb-2"
              htmlFor="grid-password"
            >
              In case you forgot your password
            </label>
            <div className="flex flex-wrap -mx-3">
              <div className="w-full px-3">
                <div className="">
                  <div className="mb-3">
                    <select
                      className="form-select appearance-none block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300
                                                      rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                      aria-label="Default select example"
                    >
                      <option>{'What is your mother\'s maiden name?'}</option>
                      <option> {'What was your first car?'}</option>
                      <option>What is the name of your first pet?</option>
                      <option>What elementry school did you attend?</option>
                      <option>
                        What is the name of the town where you were born?
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap -mx-3">
          <div className="w-full">
            <input
              className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-password"
              type="password"
              placeholder="Your Answer"
            />
          </div>
        </div>

        <div className="flex flex-wrap -mx-3">
          <div className="w-full">
            <div className="flex flex-wrap -mx-3">
              <div className="w-full px-3">
                <div className="">
                  <div className="mb-3">
                    <select
                      className="form-select appearance-none block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300
                                                      rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                      aria-label="Default select example"
                    >
                      <option> {'What was your first car?'}</option>
                      <option>{'What is your mother\'s maiden name?'}</option>
                      <option>What is the name of your first pet?</option>
                      <option>What elementry school did you attend?</option>
                      <option>
                        What is the name of the town where you were born?
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap -mx-3">
          <div className="w-full">
            <input
              autoComplete="new-password"
              className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-password"
              type="password"
              placeholder="Your Answer"
            />
          </div>
        </div>

        <div className="flex flex-wrap -mx-3">
          <div className="w-full">
            <div className="flex flex-wrap -mx-3">
              <div className="w-full px-3">
                <div className="">
                  <div className="mb-3">
                    <select
                      className="form-select appearance-none block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300
                                                      rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:outline-none"
                      aria-label="Default select example"
                    >
                      <option>What is the name of your first pet?</option>
                      <option> {'What was your first car?'}</option>
                      <option>{'What is your mother\'s maiden name?'}</option>
                      <option>What elementry school did you attend?</option>
                      <option>
                        What is the name of the town where you were born?
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-wrap -mx-3">
          <div className="w-full">
            <input
              className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
              id="grid-password"
              type="password"
              placeholder="Your Answer"
            />
          </div>
        </div>

        <div className="flex flex-wrap mt-2 -mx-3">
          <button className="btn-primary col uppercase text-white font-bold py-2 px-4 rounded w-full">
            Submit
          </button>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(RecoveryEmail);

