export default {
  // WP_USERS: '/wp/v2/users',
  // DEALS: '/tmp_deal',
  // BBA_ACCOUNT: '/bba/account',
  // CREATE_BUSINESS_ACCOUNT: '/bba/posts',
  GENERATE_TOKEN: '/wp-json/jwt-auth/v1/token',
  CREATE_WP_USER: '/wp-json/bba/create_user',
  // WP_PROVIDERS: '/wp/v2/providers',
  // BBA_POST: '/bba/posts',
  // SEARCH: '/bba/searchwp',
  // HEDERA_TRANSACTION: '/hadera/account-transaction',
  // VALIDATE_USERNAME: '/wp-json/bba/check_username_availability',
  GET_CONNECTED_USERS: '/wp-json/bba/connected_bba_accounts',
  POST: '/wp-json/bba/posts',
  SET_DEFAULT_ACCOUNT: '/wp-json/bba/set_default_account',
  GET_DEFAULT_ACCOUNT: '/wp-json/bba/get_default_accounts',
  GET_NETWORKS: '/wp-json/bba/get_nm_config',
  GET_MASTER: '/wp-json/bba/get_master_config'
};

