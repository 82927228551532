
import { Configuration, V0alpha2Api } from '@ory/kratos-client';
import axios from 'axios';
import environment from '../../constants/env';
import env from './env';

const baseURL = env.ORY_API_URL
const axiosOptions = {
  baseURL: env.ORY_ADMIN_API_URL
};

if (environment.ENV === 'production') {
  axiosOptions.headers = {
    Authorization: 'Bearer ' + env.ORY_ACCESS_TOKEN
  };
}

export default new V0alpha2Api(new Configuration({
  basePath: baseURL, baseOptions: {
    withCredentials: true
  }
}));


export const oryAdminAPI = axios.create(axiosOptions);