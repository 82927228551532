import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { config } from '../../../constants/config';
import { PERSONAL_INFO, STEP_VERIFICATION } from '../../../constants/routes';
import Spinner from '../../utils/spinner';
class AdminHome extends Component {
  render() {
    const { user, loading, t } = this.props;
    const { picture } = user?.identity?.traits || {
      display_name: '',
      picture: null,
    };

    if (loading) {
      return <Spinner loading={loading} />;
    }
    if (user) {
      return (
        <div className="relative">
          <div className="w-24 h-24 object-cover text-center mx-auto mt-8 mb-4">
            <img
              className="w-full h-full rounded-full"
              src={picture || config.PROFILE}
              alt=""
            />
          </div>
          <div>
            <h1 className="text-2xl text-center text-black mb-8">
              {t('admin.home.welcome')} Admin!
            </h1>
          </div>

          <div className="flex flex-wrap items-center justify-center overflow-x-auto home-container xl:justify-start">
            <div className="w-3/12  border rounded mr-5 home-card">
              <div className="p-6 flex items-center">
                <div className="w-9/12 mr-3">
                  <h3 className="block text-2xl text-black mb-2">{t('admin.home.wholesale.title')}</h3>
                  <p className="font-14">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc eget eros sed lacus maximus tempor at vel eros. Donec
                    vulputate semper scelerisque.
                  </p>
                </div>
                <div className="w-3/12 ml-4">
                  <i className="fa-4x fa-building fas" />
                </div>
              </div>
              <hr />
              <Link to={PERSONAL_INFO}>
                <div>
                  <p className="px-5 my-4 text-primary text-sm">
                    {t('admin.home.buttons.wholesale')}
                  </p>
                </div>
              </Link>
            </div>
            <div className="w-3/12 border rounded mr-5 home-card">
              <div className="p-6 flex items-center">
                <div className="w-9/12 mr-3">
                  <h3 className="block text-2xl text-black mb-2 font-medium">
                  {t('admin.home.retail.title')}
                  </h3>
                  <p className="font-14">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc eget eros sed lacus maximus tempor at vel eros. Donec
                    vulputate semper scelerisque.
                  </p>
                </div>
                <div className="w-3/12">
                  <i className="fa-4x fa-store fas" />
                </div>
              </div>
              <hr />
              <Link to={STEP_VERIFICATION}>
                <div>
                  <p className="px-5 my-4 text-primary text-sm">
                  {t('admin.home.buttons.retail')}
                  </p>
                </div>
              </Link>
            </div>
            <div className="w-3/12 border rounded mr-5 home-card">
              <div className="p-6 flex items-center">
                <div className="w-9/12 mr-3">
                  <h3 className="block text-2xl text-black mb-2 font-medium">
                  {t('admin.home.community.title')}
                   
                  </h3>
                  <p className="font-14">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc eget eros sed lacus maximus tempor at vel eros. Donec
                    vulputate semper scelerisque.
                  </p>
                </div>
                <div className="w-3/12">
                  <i className="fa-4x fa-store fas float-right" />
                </div>
              </div>
              <hr />
              <Link to={STEP_VERIFICATION}>
                <div>
                  <p className="px-5 my-4 text-primary text-sm">
                  {t('admin.home.buttons.community')} 
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
  // errors: state.errors
});


export default withTranslation()(withRouter(connect(mapStateToProps)(AdminHome)));

