import actions from '../actions/bba-actions';

const initialState = {
  loading: false,
  users: [],
  networkConfig: undefined,
  masterConfig: undefined
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.REGISTER_BUSINESS_STARTED:
      return {
        ...state,
        loading: true,
      };
    case actions.REGISTER_BUSINESS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.REGISTER_BUSINESS_ERROR:
      return initialState;
    case actions.GET_CONNECTED_USERS_STARTED:
      return {
        ...state,
        loading: true,
        users: [],
      };
    case actions.GET_CONNECTED_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: payload,
      };
    case actions.GET_CONNECTED_USERS_FAILED:
      return {
        ...state,
        loading: false,
        users: [],
      };
    case actions.PROFILE_PICTURE_UPLOAD_STARTED:
      return {
        ...state,
        loading: true,
      };
    case actions.PROFILE_PICTURE_UPLOAD_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.PROFILE_PICTURE_UPLOAD_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.SET_DEFAULT_ACCOUNT_STARTED:
      return {
        ...state,
        loading: true,
      };
    case actions.SET_DEFAULT_ACCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.SET_DEFAULT_ACCOUNT_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.GET_NETWORK_CONFIG_STARTED:
      return {
        ...state,
        networkConfig: undefined,
        loading: true
      }
    case actions.GET_NETWORK_CONFIG_SUCCESS:
      return {
        ...state,
        networkConfig: payload || {},
        loading: false
      }
    case actions.GET_NETWORK_CONFIG_ERROR:
      return {
        ...state,
        networkConfig: {},
        loading: false
      }
    case actions.GET_MASTER_CONFIG_STARTED:
      return {
        ...state,
        masterConfig: undefined,
        loading: true
      }
    case actions.GET_MASTER_CONFIG_SUCCESS:
      return {
        ...state,
        masterConfig: payload || {},
        loading: false
      }
    case actions.GET_MASTER_CONFIG_ERROR:
      return {
        ...state,
        masterConfig: {},
        loading: false
      }
    default:
      return state;
  }
};

