import 'intl-tel-input/build/css/intlTelInput.css';
import { Component, createRef } from 'react';
import TelInput from 'intl-tel-input';
import { PERSONAL_INFO } from 'constants/routes';
import { INFORMATION_SHOW } from 'constants/routes';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import { Form, Formik } from 'formik';
import cities from 'constants/cities';
import languages from 'constants/languages';
import env from '../../api/ory/env';
import schema, {
  fieldList,
  mappedIdentity,
} from '../../validation/personal-info-fields';
import { getNodesFromIdentity, cleanEmptyNodes } from '../../utils';
import actions from '../../actions/auth-actions';
import adminActions from '../../actions/ory-admin-actions';
import ketoActions from '../../actions/keto-actions';
import Spinner from 'components/utils/spinner';
import InfoFieldWrapper from 'components/form/info-field-wrapper';
import { config } from 'constants/config';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';
import bbaActions from '../../actions/bba-actions';
import bbaEnv from '../../api/bba/env';
import { siteAccessConfig } from 'api/keto/config';
import { withTranslation } from 'react-i18next';


class InformationEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      telInput: undefined,
      imagePath: '',
      croppedImage: '',
    };
    this.countryCodeRef = createRef();
    this.imageRef = createRef();
    this.fileSelectorRef = createRef();
  }

  initiateTelephoneCodes = () => {
    const field = this.props.location.state?.fields || {};
    setTimeout(() => {
      const initialCountry = field?.value?.country_code;
      if (this.countryCodeRef.current) {
        this.setState({
          telInput: TelInput(this.countryCodeRef.current, {
            allowDropdown: true,
            customContainer: 'w-full',
            separateDialCode: true,
            initialCountry: initialCountry,
          }),
        });
      }
    }, 50);
  };

  instantiateImageEditor = () => {
    if (this.imageRef.current) {
      const cropper = new Cropper(this.imageRef.current, {
        zoomable: false,
        scalable: true,
        rotatable: true,
        cropBoxMovable: true,
        cropBoxResizable: true,
        dragMode: 'none',
        guides: true,
        modal: true,
        aspectRatio: 1,
        zoomOnTouch: true,
        responsive: true,
        background: true,
        highlight: true,
        crop: () => {
          const canvas = cropper.getCroppedCanvas();
          this.setState({ croppedImage: canvas.toDataURL('image/png') });
        },
      });
    }
  };

  initiateImageUpload = async (image) => {
    const { fields } = this.props.location.state;
    if (fields?.title === 'Image') {
      const { dispatch, user } = this.props;
      await dispatch(
        bbaActions.uploadProfilePicture(user, image, bbaEnv.COMMUNITY_SITE_URL)
      );
      await dispatch(actions.getSession());
    }
  };

  componentDidMount = async () => {
    let { fields } = this.props.location.state;
    const { dispatch } = this.props;
    if (fields.title === 'Email') {
      await dispatch(
        ketoActions.getRoles({
          subject_id: fields.value[0],
        })
      );
    }
    if (fields.title === 'Passwordless') {
      await dispatch(actions.getSettingsFlow());
    }
    this.initiateTelephoneCodes();
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.imagePath !== this.state.imagePath) {
      this.instantiateImageEditor();
    }
  };
  componentWillUnmount = () => {
    this.setState = () => {
      return;
    };
  };

  initialValues = () => {
    const {
      user: { identity },
    } = this.props;
    const { fields } = this.props.location.state;
    const title = fields?.title?.toLowerCase();
    if (identity) {
      const nodes = getNodesFromIdentity(identity, fieldList, schema);
      Object.keys(nodes).forEach((field) => {
        if (title === field) {
          const fieldObj = schema?.fields[field];
          if (fieldObj.type === 'array') {
            const length = nodes[field]?.length;
            nodes[field][length] = '';
          }
        }
      });

      return nodes;
    }
  };

  handleSubmit = async (formData) => {
    const {
      dispatch,
      user: { identity },
    } = this.props;

    let payload = {
      traits: {
        ...identity?.traits,
      },
      schema_id: env.DEFAULT_SCHEMA_ID,
      state: identity?.state,
    };
    const { fields } = this.props.location.state;
    const title = fields?.title?.toLowerCase();

    Object.keys(formData).forEach((key) => {
      if (mappedIdentity[key]?.startsWith('traits')) {
        payload.traits[key] = formData[key] || undefined;
      } else {
        payload[mappedIdentity[key]] = formData[key] || undefined;
      }
    });
    payload.traits.isAdmin = !!payload?.traits?.isAdmin;
    if (['telegram', 'phone'].includes(title)) {
      payload.traits[
        title
      ].country_code = this.state.telInput?.selectedCountryData?.iso2;
      payload.traits[
        title
      ].dial_code = this.state.telInput?.selectedCountryData?.dialCode;
      payload.traits[title]['verified'] = false;
    }

    payload = cleanEmptyNodes(payload);

    if (fields.title === 'Email') {
      payload.traits.email = payload.traits.email.filter((email) => email);
    }
    await dispatch(
      adminActions.updateIdentity(identity?.id, payload, {
        redirectTo: PERSONAL_INFO,
      })
    );

    /**
     * Add keto record for new email for all existing accessible sites so that user can access those sites with this email too.
     */

    if (fields.title === 'Email') {
      const length = payload?.traits?.email?.length;
      const email = payload.traits.email[length - 1];
      const { relation, namespace } = siteAccessConfig;
      const { roles } = this.props;

      const users = [];
      roles?.relation_tuples?.forEach((tuple) => {
        users.push({
          action: 'insert',
          relation_tuple: {
            namespace,
            relation,
            subject_id: email,
            object: tuple?.object,
          },
        });
      });
      await dispatch(ketoActions.createRole(users));
    }
    await dispatch(actions.getSession());
  };

  handleFileSelect = () => {
    if (this.fileSelectorRef) {
      this.fileSelectorRef.current.click();
    }
  };

  handleFileSelectionChange = (e) => {
    const selectedFile = e.target.files[0];
    const localPath = window.URL.createObjectURL(selectedFile);
    this.setState({
      imagePath: localPath,
    });
  };

  clearImageSelection = () => {
    this.setState({
      imagePath: undefined,
      croppedImage: '',
    });
  };

  downloadImage = (image) => {
    const downloadLink = document.createElement('a');
    downloadLink.download = 'cropped_' + new Date().toLocaleDateString();
    downloadLink.href = image;
    downloadLink.click();
  };

  saveCroppedImage = async () => {
    this.setState({ imagePath: this.state.croppedImage });
    await this.initiateImageUpload(this.state.croppedImage);
    // this.downloadImage(this.state.croppedImage);
  };

  removeImage = async () => {
    await this.initiateImageUpload('');
  };

  renderProfileBlock = () => {
    const { imagePath } = this.state;
    const { user, t } = this.props;
    const uploadedImagePath = user?.identity?.traits?.picture;
    if (!imagePath && uploadedImagePath) {
      return (
        <div className="relative p-5 md:p-8 lg:p-10 w-full h-full ">
          <div className="flex w-fit mx-auto align-middle h-full m-auto justify-center items-center  shadow p-8 px-20  border border-gray-200 rounded mt-5">
            <div className="flex flex-col justify-center items-center">
              <div className="w-24 h-24 object-cover text -center">
                <img
                  className="w-full h-full rounded-full"
                  src={uploadedImagePath}
                  alt=""
                />
              </div>

              <div className="flex justify-center items-center mt-10">
                <button
                  onClick={() => {
                    this.setState({
                      imagePath: config.PROFILE,
                      croppedImage: '',
                    });
                  }}
                  type="button"
                  className="shadow btn-primary text-white theme-border rounded  mr-5 w-44 h-12 outline-none border focus:outline-none mb-1 ease-linear transition-all duration-150"
                >
                  {t('information_edit.photo.buttons.change')}
                </button>
                <button
                  type="button"
                  className="shadow text-black theme-border rounded  mr-5 w-44 h-12 outline-none border focus:outline-none mb-1 ease-linear transition-all duration-150"
                  onClick={this.removeImage}
                >
                  {t('information_edit.photo.buttons.remove')}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (imagePath && imagePath !== config.PROFILE) {
      const { fields } = this.props.location.state;
      return (
        <div className="relative md:p-8 lg:p-10 w-full h-full">
          <div className="flex w-fit mx-auto align-middle h-full m-auto justify-center items-center  shadow p-14 border border-gray-200 rounded mt-5">
            <div className="flex flex-col justify-center items-center">
              <div className="h-430-px w-450-px ">
                <img
                  className="object-contain h-full w-full"
                  src={imagePath}
                  alt=""
                  ref={this.imageRef}
                />
              </div>
              <p className="text-sm my-3">Rotate</p>

              <div className="flex justify-center items-center mt-4">
                <Link
                  to={{ pathname: INFORMATION_SHOW, state: { fields: fields } }}
                  className={
                    'weight-400 text-lg cursor-pointer letter-spacing line-height24 text-black'
                  }
                >
                  <button
                    type="button"
                    className="shadow text-black theme-border rounded  mr-5 w-48 h-12 outline-none border focus:outline-none mb-1 ease-linear transition-all duration-150"
                  >
                    {t('common.buttons.cancle')}
                  </button>
                </Link>
                <button
                  onClick={this.saveCroppedImage}
                  type="button"
                  className="shadow btn-primary text-white theme-border rounded  mr-5 w-48 h-12 outline-none border focus:outline-none mb-1 ease-linear transition-all duration-150"
                >
                  {t('information_edit.photo.buttons.save')}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (imagePath === config.PROFILE || !uploadedImagePath) {
      return (
        <div className="relative p-5 md:p-8 lg:p-10 w-full h-full">
          <div className="flex w-fit mx-auto align-middle h-full m-auto justify-center items-center  shadow p-14 border border-gray-200 rounded mt-5">
            <div className="flex flex-col justify-center items-center">
              <div className="w-20 h-20 object-cover text -center">
                <img className="" src={config.PROFILE} alt="" />
              </div>
              <p className="text-lg my-3">{t('information_edit.photo.fields.drag_a_photo')}</p>
              <span className="mb-3 text-gray-500">or</span>
              <div className="flex justify-center items-center">
                <button
                  type="button"
                  className="shadow text-primary theme-border rounded  mr-5  w-48 h-12 outline-none border focus:outline-none mb-1 ease-linear transition-all duration-150"
                  onClick={this.handleFileSelect}
                >
                  <input
                    type="file"
                    className="hidden"
                    ref={this.fileSelectorRef}
                    onChange={this.handleFileSelectionChange}
                  />
                  {t('information_edit.photo.fields.upload')}
                </button>

                <button
                  type="button"
                  className="shadow theme-border text-primary theme-border rounded  mr-5  w-48 h-12 outline-none border focus:outline-none mb-1 ease-linear transition-all duration-150"
                >
                  {t('information_edit.photo.fields.take_a_picture')}
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  render() {
    const { loading, adminLoading, bbaLoading, t } = this.props;
    if (loading || adminLoading || bbaLoading) {
      return <Spinner loading={true} />;
    }

    if (!this.props.location?.state?.fields) {
      return <Redirect to={PERSONAL_INFO} />;
    }
    if (this.props.location?.state?.fields && this.props.user) {
      let { fields } = this.props.location.state;
      if (fields.title === 'Email') {
        const filteredFields = fields?.value?.filter((value) => value);
        fields.value = filteredFields;
      }
      const { title, description, text } = fields;
      const city = Object.keys(cities);
      const area = Object.keys(cities[city]);

      if (fields.title === 'Passwordless' && this.props.settingsFlow?.ui) {
        const { settingsFlow } = this.props;
        const { nodes } = settingsFlow.ui;
        const webAuthnNodes = nodes?.filter(
          (node) =>
            (node.group == 'webauthn' && node?.type === 'input') ||
            node?.attributes?.name === 'csrf_token'
        );
        const oryAPI = env.ORY_API_URL;
        return (
          <div className="relative  p-5 md:p-8 lg:p-10 w-full xl:w-5/6">
            <div className="w-full xl:w-5/6 lg:mx-auto border-gray-200 rounded mt-4">
              <div className="mb-6 items-center">
                <Link
                  to={{ pathname: PERSONAL_INFO }}
                  className={
                    'weight-400 text-lg cursor-pointer letter-spacing line-height24 text-black'
                  }
                >
                  <i className="fas fa-arrow-left"></i>
                </Link>

                <span className="ml-3 text-2xl font-bold inline-block">
                  {text}
                </span>
                <p className="ml-7 mt-2 text-gray-500">{description}</p>
              </div>
              <div className="xl:w-10/12 w-full justify-start border-gray-200 rounded mt-8">
                <div className="w-full">
                  <div className="mb-2">
                    <form
                      action={`${oryAPI}/self-service/settings?flow=${settingsFlow?.id}`}
                      method="POST"
                    >
                      {webAuthnNodes?.map((node, key) => {
                        const type = node?.attributes?.type;
                        const label = node?.meta?.label?.text;
                        if (type === 'text') {
                          return (
                            <div key={key}>
                              <label>{label}</label>
                              <input
                                type={type}
                                name={node?.attributes?.name}
                                className="appearance-none block w-full md:w-8/10 text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                              />
                            </div>
                          );
                        }
                        if (type === 'button') {
                          return (
                            <button
                              name={node?.attributes?.name}
                              key={key}
                              type={type}
                              className="btn-primary shadow text-white rounded p-3 px-8 outline-none mb-1 ease-linear transition-all duration-150"
                              onClick={() => {
                                eval(node?.attributes?.onclick);
                              }}
                            >
                              {label}
                            </button>
                          );
                        }
                        return (
                          <input
                            type="hidden"
                            name={node?.attributes?.name}
                            key={key}
                            value={node?.attributes?.value}
                          />
                        );
                      })}
                      <input type="hidden" name="method" value="webauthn" />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
      return (
        <div className="relative  p-5 md:p-8 lg:p-10 w-full xl:w-5/6">
          <div className="w-full xl:w-5/6 lg:mx-auto border-gray-200 rounded mt-4">
            <div className="mb-6 items-center">
              <Link
                to={{ pathname: INFORMATION_SHOW, state: { fields: fields } }}
                className={
                  'weight-400 text-lg cursor-pointer letter-spacing line-height24 text-black'
                }
              >
                <i className="fas fa-arrow-left"></i>
              </Link>

              <span className="ml-3 text-2xl font-bold inline-block">
                {text}
              </span>
              <p className="ml-7 mt-2 text-gray-500">{description}</p>
            </div>
            <div className="xl:w-10/12 w-full justify-start border-gray-200 rounded mt-8">
              <div className="w-full">
                <div className="mb-2">
                  <Formik
                    initialValues={this.initialValues()}
                    onSubmit={this.handleSubmit}
                    validationSchema={schema}
                    validateOnBlur={false}
                    validateOnChange={false}
                  >
                    {({ errors, values, handleChange, setFieldValue }) => {
                      if (values) {
                        return (
                          <Form>
                            {title === 'Name' ? (
                              <>
                                <InfoFieldWrapper
                                  error={errors.display_name}
                                  title={text}
                                  element="display_name"
                                >
                                  <input
                                    value={values.display_name}
                                    name="display_name"
                                    type="text"
                                    onChange={handleChange}
                                    className="appearance-none block w-full md:w-8/10 text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  />
                                </InfoFieldWrapper>
                              </>
                            ) : null}
                            {title === 'Address' ? (
                              <>
                                <InfoFieldWrapper
                                  error={errors.address?.area}
                                  title={`${city} ${t('information_edit.address.fields.area')}`}
                                  element="address.area"
                                  required
                                >
                                  <select
                                    name="address.area"
                                    onChange={(e) => {
                                      setFieldValue(
                                        'address.neighbourhood',
                                        ''
                                      );
                                      handleChange(e);
                                    }}
                                    value={values.address.area}
                                    className="appearance-none block w-full md:w-9/12 bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  >
                                    <option value="default" hidden>
                                      {`Select ${city} Area`}
                                    </option>
                                    {area.map((option, key) => (
                                      <option value={option} key={key}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                </InfoFieldWrapper>

                                <InfoFieldWrapper
                                  error={errors.address?.neighbourhood}
                                  title={t('information_edit.address.fields.neighbourhood')}
                                  element="address.neighbourhood"
                                  required
                                >
                                  <select
                                    disabled={!values.address.area}
                                    name="address.neighbourhood"
                                    onChange={handleChange}
                                    value={values.address.neighbourhood}
                                    className="appearance-none block w-full md:w-9/12 bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  >
                                    <option value="default" hidden>
                                      Select Neighbourhood
                                    </option>
                                    {cities[city][values.address.area]?.map(
                                      (option, key) => (
                                        <option value={option} key={key}>
                                          {option}
                                        </option>
                                      )
                                    )}
                                  </select>
                                </InfoFieldWrapper>
                              </>
                            ) : null}
                            {title === 'Email' ? (
                              <>
                                <InfoFieldWrapper
                                  error={errors.email}
                                  title={text}
                                  element={`email.[${values.email?.length - 1
                                    }]`}
                                >
                                  <input
                                    value={
                                      values.email[values.email?.length - 1]
                                    }
                                    name={`email.[${values.email?.length - 1}]`}
                                    type="email"
                                    onChange={handleChange}
                                    className="appearance-none block w-full md:w-8/10 text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  />
                                </InfoFieldWrapper>
                              </>
                            ) : null}
                            {title === 'Phone' ? (
                              <>
                                <InfoFieldWrapper
                                  error={errors.phone}
                                  title={text}
                                  element="phone.number"
                                >
                                  <input
                                    type="number"
                                    value={values.phone.number}
                                    name="phone.number"
                                    ref={this.countryCodeRef}
                                    onChange={handleChange}
                                    className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-3"
                                  />
                                </InfoFieldWrapper>
                              </>
                            ) : null}
                            {title === 'Telegram' ? (
                              <>
                                <InfoFieldWrapper
                                  error={errors.telegram}
                                  title={text}
                                  element="telegram.number"
                                >
                                  <input
                                    type="number"
                                    value={values.telegram.number}
                                    name="telegram.number"
                                    ref={this.countryCodeRef}
                                    onChange={handleChange}
                                    className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-3"
                                  />
                                </InfoFieldWrapper>
                              </>
                            ) : null}
                            {title === 'Language' ? (
                              <>
                                <InfoFieldWrapper
                                  error={errors.language}
                                  title={text}
                                  element="language.code"
                                >
                                  <select
                                    name="language.code"
                                    onChange={(e) => {
                                      handleChange(e);
                                      setFieldValue(
                                        'language.value',
                                        languages[e.target.value]
                                      );
                                    }}
                                    value={values.language.code}
                                    className="appearance-none block w-full md:w-9/12 bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                  >
                                    <option value="default" hidden>
                                      Select your Language
                                    </option>
                                    {Object.keys(languages)?.map((key) => (
                                      <option value={key} key={key}>
                                        {languages[key]}
                                      </option>
                                    ))}
                                  </select>
                                </InfoFieldWrapper>
                              </>
                            ) : null}
                            {title === 'Privacy' ? (
                              <>
                                <InfoFieldWrapper
                                  error={errors.email}
                                  title="Privacy"
                                  element="phone"
                                >
                                  <div>
                                    <div className="flex items-center">
                                      <input
                                        type="radio"
                                        id="userName"
                                        name="displayName"
                                        value="HTML"
                                      />
                                      <label
                                        htmlFor="userName"
                                        className="ml-3 block"
                                      >
                                        Username ({values.username}){' '}
                                      </label>
                                      <br />
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        type="radio"
                                        id="displayName"
                                        name="displayName"
                                        value="CSS"
                                      />
                                      <label
                                        htmlFor="displayName"
                                        className="ml-3 block"
                                      >
                                        Display Name ({values.display_name})
                                      </label>
                                      <br />
                                    </div>
                                    <div className="flex items-center">
                                      <input
                                        type="radio"
                                        id="leganName"
                                        name="displayName"
                                        value="JavaScript"
                                      />
                                      <label
                                        htmlFor="leganName"
                                        className="ml-3 block"
                                      >
                                        Legal Name (
                                        {values.first_name +
                                          ' ' +
                                          values.last_name}
                                        )
                                      </label>
                                    </div>
                                  </div>
                                </InfoFieldWrapper>
                              </>
                            ) : null}
                            {fields.title !== 'Image' && (
                              <div className="w-full px-1 md:flex justify-end mt-5">
                                <Link
                                  to={{
                                    pathname: INFORMATION_SHOW,
                                    state: { fields: fields },
                                  }}
                                >
                                  <button
                                    type="button"
                                    className="shadow text-black theme-border rounded  mr-5 p-3 px-8 outline-none border focus:outline-none mb-1 ease-linear transition-all duration-150"
                                  >
                                    {t('common.buttons.back')}
                                  </button>
                                </Link>
                                <button
                                  type="submit"
                                  disabled={adminLoading}
                                  className="btn-primary shadow text-white rounded p-3 px-8 outline-none mb-1 ease-linear transition-all duration-150"
                                >
                                  {t('common.buttons.save')}
                                </button>
                              </div>
                            )}
                          </Form>
                        );
                      }
                    }}
                  </Formik>
                  {fields.title === 'Image' && <>{this.renderProfileBlock()}</>}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return <Spinner loading={true} />;
  }
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  user: state.auth.user,
  adminLoading: state.oryAdmin.loading,
  bbaLoading: state.bba.loading,
  roles: state.keto.roles,
  settingsFlow: state.auth.settingsFlow,
});

export default withTranslation()(withRouter(connect(mapStateToProps)(InformationEdit)));

