import { Component } from 'react';
import { DebounceInput } from 'react-debounce-input';

class AddFilter extends Component {
    constructor(props) {
        super(props);
    }
    state = {  }

    render() { 
        return ( <div className="my-4 md:flex items-center">
        <div>
          <select
            name="cars"
            id="sort"
            value={this.props.sortValue}
            className="rounded"
            onChange={(e) => {
              this.props.sortBy(e.target.value);
            }}
          >
            <option value="default" hidden>
              Sort By
            </option>
            <option value="asc">A-Z</option>
            <option value="desc">Z-A</option>
            <option value="time">Latest</option>
          </select>
        </div>
        <div className="mt-3 md:mt-0 md:ml-4 relative">
          <DebounceInput
            minLength={1}
            className="search"
            placeholder="Search"
            debounceTimeout={1000}
            value={this.props.searchValue}
            onChange={(e) => {
              this.props.onSearch(e.target.value);
            }}
          />
          {(this.props.searchValue.length > 0 || this.props.sortValue) ? (
            <>
              <i
                className="fas fa-times-circle absolute right-3 top-3 p-10-px min-w-40-px"
                onClick={() => this.props.reset()}
              ></i>
            </>
          ) : null}
        </div>
      </div>
    );
  }
}
 
export default AddFilter;