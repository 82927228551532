import actions from '../actions/ory-admin-actions';
const initialState = {
  loading: false,
  identities: [],
  identity: undefined,
  count: 0,
};
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.GET_IDENTITY_LIST_STARTED:
      return {
        ...state,
        loading: true,
        count: 0,
        identities: [],
      };
    case actions.GET_IDENTITY_LIST_ERROR:
      return {
        ...state,
        loading: false,
        identities: [],
        count: 0,
      };
    case actions.GET_IDENTITY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        identities: payload?.data,
        count: payload?.count,
      };
    case actions.GET_IDENTITY_BY_ID_STARTED:
      return {
        ...state,
        loading: true,
        identities: undefined,
        identity: undefined,
      };
    case actions.GET_IDENTITY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        identity: payload,
      };
    case actions.GET_IDENTITY_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        identity: undefined,
      };
    case actions.UPDATE_IDENTITY_STARTED:
      return {
        ...state,
        loading: true,
        identities: undefined,
        identity: undefined,
      };
    case actions.UPDATE_IDENTITY_SUCCESS:
      return {
        ...state,
        loading: false,
        identity: payload,
      };
    case actions.UPDATE_IDENTITY_ERROR:
      return {
        ...state,
        loading: false,
        identity: undefined,
      };
    case actions.DELETE_IDENTITY_BY_ID_STARTED:
      return {
        ...state,
        loading: true,
      };
    case actions.DELETE_IDENTITY_BY_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        identity: undefined,
        identities:
          state.identities?.filter(
            (identity) => identity?.id !== payload?.id
          ) || state.identities,
        count: state.count - 1,
      };
    case actions.DELETE_IDENTITY_BY_ID_ERROR:
      return {
        ...state,
        loading: false,
        identity: undefined,
      };
    case actions.FILTER_IDENTITY_LIST_STARTED:
      return {
        ...state,
        loading: true,
        count: 0,
        identities: [],
      };
    case actions.FILTER_IDENTITY_LIST_ERROR:
      return {
        ...state,
        loading: false,
        identities: [],
        count: 0,
      };
    case actions.FILTER_IDENTITY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        identities: payload?.data,
        count: payload?.count,
      };
    case actions.SORT_IDENTITY_LIST_STARTED:
      return {
        ...state,
        loading: true,
        count: 0,
        identities: [],
      };
    case actions.SORT_IDENTITY_LIST_ERROR:
      return {
        ...state,
        loading: false,
        identities: [],
        count: 0,
      };
    case actions.SORT_IDENTITY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        identities: payload?.data,
        count: payload?.count,
      };
    default:
    // break;
  }
  return state;
};

