import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Spinner from '../../utils/spinner';
import actions from '../../../actions/hydra-actions';
import { CLIENT_LIST } from 'constants/routes';
import { CLIENT_EDIT } from 'constants/routes';
import { withTranslation } from 'react-i18next';

class ClientInformation extends Component {
  state = {
    id: this.props.match.params?.id || null,
  };
  componentDidMount = () => {
    const { client, dispatch } = this.props;
    if (!client || client.client_id !== this.state.id) {
      dispatch(actions.getClientById(this.state.id));
    }
  };
  render() {
    const { loading, hydraLoading, client, t } = this.props;

    if (loading || hydraLoading) {
      return <Spinner loading={true} />;
    }
    if (client) {
      const { openIdConfig } = client;
      return (
        <div className="relative  p-5 md:p-8 lg:p-10 w-full md:w-4/6 lg:w-4/5 xl:w-5/6">
          <div
            className="w-full p-8 border border-gray-200 rounded"
            style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)' }}
          >
            <div className="pt-8 mb-6 items-center">
              <Link
                to={CLIENT_LIST}
                className={
                  'weight-400 text-lg cursor-pointer letter-spacing line-height24 text-black'
                }
              >
                <i className="fas fa-arrow-left"></i>
              </Link>
              <span className="ml-3 text-2xl font-bold">
                {t('admin.clients.info.title')}
              </span>
            </div>
            <div className="text-center font-20"></div>
            <div className="mt-8">
              <div className="w-full mt-8">
                <div className="flex">
                  <div className="w-3/12">
                    <p className="block mb-4">
                      {t('admin.clients.fields.client_id')}
                    </p>
                    <hr />
                  </div>
                  <div className="w-9/12">
                    <p className="block mb-4">{client?.client_id || '-'}</p>
                    <hr />
                    <p className="text-sm mt-1 text-gray-500">
                      {t('admin.clients.fields.description.client_id')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full mt-8">
                <div className="flex">
                  <div className="w-3/12">
                    <p className="block mb-4">
                      {t('admin.clients.fields.client_name')}
                    </p>
                    <hr />
                  </div>
                  <div className="w-9/12">
                    <p className="block mb-4">{client?.client_name || '-'}</p>
                    <hr />
                    <p className="text-sm mt-1 text-gray-500">
                      {t('admin.clients.fields.description.client_name')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full mt-8">
                <div className="flex">
                  <div className="w-3/12">
                    <p className="block mb-4">
                      {t('admin.clients.fields.client_uri')}
                    </p>
                    <hr />
                  </div>
                  <div className="w-9/12">
                    <p className="block mb-4">{client?.client_uri || '-'}</p>
                    <hr />
                    <p className="text-sm mt-1 text-gray-500">
                      {t('admin.clients.fields.description.client_uri')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full mt-8">
                <div className="flex">
                  <div className="w-3/12">
                    <p className="block mb-4">
                      {t('admin.clients.fields.redirect_uri')}
                    </p>
                    <hr />
                  </div>
                  <div className="w-9/12">
                    <p className="block mb-4">
                      {client?.redirect_uris?.join(', ') || '-'}
                    </p>
                    <hr />
                    <p className="text-sm mt-1 text-gray-500">
                      {t('admin.clients.fields.description.redirect_uri')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full mt-8">
                <div className="flex">
                  <div className="w-3/12">
                    <p className="block mb-4">
                      {t('admin.clients.fields.post_logout_redirect_uri')}
                    </p>
                    <hr />
                  </div>
                  <div className="w-9/12">
                    <p className="block mb-4">
                      {client?.post_logout_redirect_uris?.join(', ') || '-'}
                    </p>
                    <hr />
                    <p className="text-sm mt-1 text-gray-500">
                      {t('admin.clients.fields.description.post_logout_redirect_uri')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full mt-8">
                <div className="flex">
                  <div className="w-3/12">
                    <p className="block mb-4">
                      {t('admin.clients.fields.scope')}
                    </p>
                    <hr />
                  </div>
                  <div className="w-9/12">
                    <p className="block mb-4">{client?.scope || '-'}</p>
                    <hr />
                    <p className="text-sm mt-1 text-gray-500">
                      {t('admin.clients.fields.description.scope')}

                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full mt-8">
                <div className="flex">
                  <div className="w-3/12">
                    <p className="block mb-4">
                      {t('admin.clients.fields.logo_uri')}
                    </p>
                    <hr />
                  </div>
                  <div className="w-9/12">
                    <p className="block mb-4">{client?.logo_uri || '-'}</p>
                    <hr />
                    <p className="text-sm mt-1 text-gray-500">
                      {t('admin.clients.fields.description.logo_uri')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full mt-8">
                <div className="flex">
                  <div className="w-3/12">
                    <p className="block mb-4">
                      {t('admin.clients.fields.policy_uri')}
                    </p>
                    <hr />
                  </div>
                  <div className="w-9/12">
                    <p className="block mb-4">{client?.policy_uri || '-'}</p>
                    <hr />
                    <p className="text-sm mt-1 text-gray-500">
                      {t('admin.clients.fields.description.policy_uri')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full mt-8">
                <div className="flex">
                  <div className="w-3/12">
                    <p className="block mb-4">
                      {t('admin.clients.fields.tnc_uri')}
                    </p>
                    <hr />
                  </div>
                  <div className="w-9/12">
                    <p className="block mb-4">{client?.tos_uri || '-'}</p>
                    <hr />
                    <p className="text-sm mt-1 text-gray-500">
                      {t('admin.clients.fields.description.tnc_uri')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full mt-8">
                <div className="flex">
                  <div className="w-3/12">
                    <p className="block mb-4">
                      {t('admin.clients.fields.authorization_endpoint')}
                    </p>
                    <hr />
                  </div>
                  <div className="w-9/12">
                    <p className="block mb-4">
                      {openIdConfig?.authorization_endpoint || '-'}
                    </p>
                    <hr />
                    <p className="text-sm mt-1 text-gray-500">
                      {t('admin.clients.fields.description.authorization_endpoint')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full mt-8">
                <div className="flex">
                  <div className="w-3/12">
                    <p className="block mb-4">
                      {t('admin.clients.fields.token_endpoint')}
                    </p>
                    <hr />
                  </div>
                  <div className="w-9/12">
                    <p className="block mb-4">
                      {openIdConfig?.token_endpoint || '-'}
                    </p>
                    <hr />
                    <p className="text-sm mt-1 text-gray-500">
                      {t('admin.clients.fields.description.token_endpoint')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full mt-8">
                <div className="flex">
                  <div className="w-3/12">
                    <p className="block mb-4">
                      {t('admin.clients.fields.userinfo_endpoint')}
                    </p>
                    <hr />
                  </div>
                  <div className="w-9/12">
                    <p className="block mb-4">
                      {openIdConfig?.userinfo_endpoint || '-'}
                    </p>
                    <hr />
                    <p className="text-sm mt-1 text-gray-500">
                      {t('admin.clients.fields.description.userinfo_endpoint')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full mt-8">
                <div className="flex">
                  <div className="w-3/12">
                    <p className="block mb-4">
                      {t('admin.clients.fields.end_session_endpoint')}
                    </p>
                    <hr />
                  </div>
                  <div className="w-9/12">
                    <p className="block mb-4">
                      {openIdConfig?.end_session_endpoint || '-'}
                    </p>
                    <hr />
                    <p className="text-sm mt-1 text-gray-500">
                      {t('admin.clients.fields.description.end_session_endpoint')}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex justify-end mt-5 mb-3">
                <div className="align-center">
                  <Link
                    to={`${CLIENT_EDIT}/${this.state.id}`}
                    type="submit"
                    className=" btn-primary shadow text-white py-3 px-16 rounded hover:shadow-lg p-2 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
                  >
                    {t('common.buttons.edit')}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  user: state.auth.user,
  client: state.hydra.client,
  hydraLoading: state.hydra.loading,
});

export default withTranslation()(withRouter(connect(mapStateToProps)(ClientInformation)));

