import * as yup from 'yup';
const schema = yup.object().shape({
  // email: yup.string(),
  wpUser: yup.object({
    username: yup.string(),
    email: yup.string(),
  }),
  // username: yup.string().required('Username is required'),
  membership: yup.string(),
  displayName: yup.string().required('Display name is required'),
  isBusinessAccount: yup.boolean().default(true)
});


const fields = () => {
  const field = {};
  Object.keys(schema.fields).forEach(
    (fieldName) => (field[fieldName] = fieldName)
  );
  return field;
};


export const fieldList = Object.keys(schema.fields);
export { fields };
export default schema;
