import { sendOtp, verifyOtp } from '../api/twilio/service';
import { toast } from 'react-toastify';
import getStore, { getHistory } from '../store';
import env from '../api/ory/env';
import adminActions from './ory-admin-actions';
const actions = {
  SEND_OTP_STARTED: 'SEND_OTP_STARTED',
  SEND_OTP_ERROR: 'SEND_OTP_ERROR',
  SEND_OTP_SUCCESS: 'SEND_OTP_SUCCESS',
  VERIFY_OTP_STARTED: 'VERIFY_OTP_STARTED',
  VERIFY_OTP_ERROR: 'VERIFY_OTP_ERROR',
  VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
  sendOtp: (phone, returnTo) => async (dispatch) => {
    try {
      dispatch({
        type: actions.SEND_OTP_STARTED,
      });
      const response = await sendOtp(phone);
      if (response?.status === 201) {
        toast.success(
          'One time password (OTP) has been sent to your mobile number.'
        );
        dispatch({
          type: actions.SEND_OTP_SUCCESS,
        });
        if (returnTo) {
          getHistory().push({
            pathname: returnTo,
            state: { phone: phone },
          });
        }
      }
    } catch (error) {
      dispatch({
        type: actions.SEND_OTP_ERROR,
      });
    }
  },
  verifyOtp: (payload, returnTo = undefined) => async (dispatch) => {
    try {
      dispatch({
        type: actions.VERIFY_OTP_STARTED,
      });
      const response = await verifyOtp(payload);
      if (response?.data?.valid) {
        const user = getStore().getState().auth.user;
        let payload = {
          traits: {
            ...user?.identity?.traits,
          },
          schema_id: env.DEFAULT_SCHEMA_ID,
          state: user?.identity?.state,
        };
        if (payload?.traits?.phone) {
          payload.traits.phone['verified'] = true;
        }
        await dispatch(
          adminActions.updateIdentity(user?.identity?.id, payload, {
            redirectTo: returnTo,
          })
        );
      }
      dispatch({
        type: actions.VERIFY_OTP_SUCCESS,
      });
      if (returnTo) {
        getHistory().push(returnTo);
      }
    } catch (error) {
      dispatch({
        type: actions.VERIFY_OTP_ERROR,
      });
    }
  },
};

export default actions;

