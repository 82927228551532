import '@fortawesome/fontawesome-free/css/all.min.css';
import { lazy, Suspense } from 'react';
import 'assets/styles/docs.css';
import 'assets/styles/tailwind.min.css';
import 'assets/styles/theme.scss';
import { ConnectedRouter } from 'connected-react-router';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css'; 
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import App from './App';
import AccountRecoverStep1 from './components/auth/account-recovery/account-recover-step-1';
import AccountRecoverStep2 from './components/auth/account-recovery/account-recover-step-2';
import AccountRecoverStep3 from './components/auth/account-recovery/account-recover-step-3';
import AccountRecoverStep4 from './components/auth/account-recovery/account-recover-step-4';
import Forgotpass from './components/auth/forgotpass';
import Login from './components/auth/login';
import Accepted from './components/auth/moderation/accepted';
import Rejected from './components/auth/moderation/rejected';
import Warning from './components/auth/moderation/warning';
import Register from './components/auth/register/register';
import Errors from './components/ory/ErrorComponent';
import './i18n';
const AdminRoute = lazy(() => import('./auth-guard/admin-guard'));

import {
  ACCOUNT_ACCEPTED,
  ACCOUNT_LIST,
  ACCOUNT_RECOVERY_STEP_1,
  ACCOUNT_RECOVERY_STEP_2,
  ACCOUNT_RECOVERY_STEP_3,
  ACCOUNT_RECOVERY_STEP_4,
  ACCOUNT_REJECTED,
  ADMIN_HOME,
  CLIENT_CREATE,
  CLIENT_EDIT,
  CLIENT_INFORMATION,
  CLIENT_LIST,
  ERRORS,
  FORGOT_PASS,
  HOME,
  IDENTITIES_EDIT,
  IDENTITIES_INFORMATION,
  IDENTITIES_LIST,
  INFORMATION_EDIT,
  INFORMATION_SHOW,
  LOGIN,
  LOGOUT,
  MODERATION_WARNING,
  PERSONAL_INFO,
  PERSONAL_INFO_VERIFICATION,
  RECOVERY_EMAIL,
  REGISTER,
  RESET_PASSWORD,
  STEP_VERIFICATION,
  TERMS_AND_CONDITIONS,
  VERIFY,
  VERIFY_OTP,
} from './constants/routes';
import { configureStore, getHistory } from './store';
import Spinner from 'components/utils/spinner';
import verifyOtp from 'components/personal-info/verify-otp';
import termsAndConditions from 'components/auth/terms-and-conditions';
const store = configureStore();

ReactDOM.render(
  <>
    <Provider store={store}>
      <ConnectedRouter history={getHistory()}>
        <Suspense fallback={<Spinner loading={true} />}>
          <Switch>
            <Route path={LOGIN} component={Login} />
            <Route exact path={REGISTER} component={Register} />
            <Route exact path={FORGOT_PASS} component={Forgotpass} />
            <Route
              exact
              path={ACCOUNT_RECOVERY_STEP_1}
              component={AccountRecoverStep1}
            />
            <Route
              exact
              path={ACCOUNT_RECOVERY_STEP_2}
              component={AccountRecoverStep2}
            />
            <Route
              exact
              path={ACCOUNT_RECOVERY_STEP_3}
              component={AccountRecoverStep3}
            />
            <Route
              exact
              path={ACCOUNT_RECOVERY_STEP_4}
              component={AccountRecoverStep4}
            />
            <Route exact path={MODERATION_WARNING} component={Warning} />
            <Route exact path={ACCOUNT_REJECTED} component={Rejected} />
            <Route exact path={ACCOUNT_ACCEPTED} component={Accepted} />
            <Route exact path={VERIFY_OTP} component={verifyOtp} />
            <Route exact path={ERRORS} component={Errors} />
            <Route path={HOME} component={App} />
            <Route path={PERSONAL_INFO} component={App} />
            <Route path={INFORMATION_SHOW} component={App} />
            <Route path={INFORMATION_EDIT} component={App} />
            <Route path={PERSONAL_INFO_VERIFICATION} component={App} />
            <Route path={STEP_VERIFICATION} component={App} />
            <Route path={RESET_PASSWORD} component={App} />
            <Route path={RECOVERY_EMAIL} component={App} />
            <Route path={LOGOUT} exact component={App} />
            <Route path={VERIFY} exact component={App} />
            <Route path={TERMS_AND_CONDITIONS} exact component={termsAndConditions} />

            {/* Admin Routes */}
            <AdminRoute path={ADMIN_HOME} exact component={App} />
            <AdminRoute path={IDENTITIES_LIST} exact component={App} />
            <AdminRoute path={ACCOUNT_LIST} exact component={App} />
            <AdminRoute
              path={`${IDENTITIES_INFORMATION}/:id`}
              exact
              component={App}
            />
            <AdminRoute path={`${IDENTITIES_EDIT}/:id`} exact component={App} />
            <AdminRoute path={CLIENT_LIST} exact component={App} />
            <AdminRoute path={CLIENT_CREATE} exact component={App} />
            <AdminRoute
              path={`${CLIENT_INFORMATION}/:id`}
              exact
              component={App}
            />
            <AdminRoute path={`${CLIENT_EDIT}/:id`} exact component={App} />
            <Redirect exact from="*" to={LOGIN} />
          </Switch>
        </Suspense>
      </ConnectedRouter>
    </Provider>
    <ToastContainer
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      draggable
      pauseOnHover
      closeButton={true}
      toastStyle={{ fontSize: '15px' }}
    />
  </>,
  document.getElementById('root')
);

