import { toast } from 'react-toastify';
import client from './config';
import { SEND_OTP, VERIFY_OTP } from './routes';

export const sendOtp = async (phone) => {
  try {
    const formData = new FormData();
    formData.append('Channel', 'sms');
    formData.append('To', phone);
    const options = {
      url: SEND_OTP,
      data: formData,
      method: 'POST',
    };
    const response = await client(options);
    return response;
  } catch (error) {
    if ([60203, 20429].includes(error?.response?.data?.code)) {
      toast.error('Too many attempts! please try again later.');
    } else {
      toast.error(
        'An Error Occurred while sending the OTP, please try again later'
      );
    }
    throw error;
  }
};

export const verifyOtp = async (payload) => {
  try {
    const formData = new FormData();
    formData.append('Code', payload.code);
    formData.append('To', payload.to);
    const options = {
      url: VERIFY_OTP,
      data: formData,
      method: 'POST',
    };
    const response = await client(options);
    if (response?.data?.valid) {
      toast.success('Phone number verified successfully');
      return response;
    }
    toast.error('Invalid OTP');
    throw new Error('OTP_VERIFICATION_FAILED');
  } catch (error) {
    let message =
      'An error occurred while verifying the OTP, please try again later.';
    if (error?.response?.data?.code) {
      switch (error?.response?.data?.code) {
        case 20404:
          message = 'Invalid OTP';
          break;
        case 60202:
          message = 'Too many attempts! please try again later.';
          break;
      }
      toast.error(message);
    }

    throw error;
  }
};

