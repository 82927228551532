
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import actions from '../../actions/auth-actions';
import { FLOW_TYPES } from '../../constants/flows';
import Flow from '../ory/flow';
import Spinner from '../utils/spinner';
import { fields, generateSchema } from '../utils/validationSchema';
class Login extends Component {
  params = new URLSearchParams(this.props.location.search)
  flowId = this.params.get('flow');
  returnTo = this.params.get('return_to');
  async init() {
    try {
      const { dispatch } = this.props;
      dispatch(actions.getAccountVerficationFlow(this.flowId, this.returnTo));
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount = async () => {
    await this.init();
  }
  validationSchema = generateSchema([
    fields.email
  ]);

  render() {
    const { flow, loading } = this.props;
    if (loading) {
      return (<Spinner loading={loading} />)
    } else {

      return (
        <div className='min-h-screen py-6 flex flex-col justify-center relative overflow-hidden sm:py-12' >
          <div className='flex items-center w-full'>
            <div className="md:mx-auto flex p-6" style={{ boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)' }}>
              <section className="relative w-full pt-25">
                <div className='grid'>
                  <div className="container mx-auto px-4 h-full">
                    <div className="flex content-center items-center justify-center h-full">
                      <div className="relative flex flex-col w-full mb-6 rounded-lg bg-white">
                        <div className="mb-0 px-5 py-6">
                          <div className="mb-3 text-center">
                            <h3 className="text-3xl p-2 font-bold">Verify your account  </h3>
                          </div>

                          <div className="text-left py-4 font-14">
                          </div>
                        </div>
                        <div className="text-left py-4 font-14">
                          Thank you for getting back, please verify to your account by filling in this form
                        </div>
                        <div className="flex-auto px-4 py-9 pt-0">
                          {
                            flow && <Flow flow={flow} type={FLOW_TYPES.VERIFY} validationSchema={this.validationSchema} />
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section >
            </div>
          </div >
        </div >
      )
    }
  }
}

const mapStateToProps = state => ({
  flow: state.auth.verificationFlow,
  loading: state.auth.loading,
});

export default withRouter(connect(mapStateToProps)(Login));