import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import actions from '../../../actions/hydra-actions';
import { CLIENT_CREATE, CLIENT_INFORMATION } from '../../../constants/routes';
import Spinner from '../../utils/spinner';
import Column from 'components/shared/column';
import ConformationPopup from './conformation-popup';
import Pager from 'components/utils/pager';
import { withTranslation } from 'react-i18next';
import AddFilter from '../add-filter';

class ClientList extends Component {
  popoverRef = [];

  state = {
    showConfirmationPopUp: false,
    clientId: undefined,
    logoUri: undefined,
    pageNo: 1,
    itemsPerPage: 10,
    searchValue: '',
    sortValue: 'default',
  };

  componentDidMount = async () => {
    await this.getClients(this.state.pageNo, this.state.itemsPerPage);
  };

  componentDidUpdate = (_, prevState) => {
    if (this.state.pageNo !== prevState.pageNo) {
      this.getClients(this.state.pageNo, this.state.itemsPerPage);
    }
  };

  getClients = async (pageNo = 1, itemsPerPage=10) => {
    const { dispatch } = this.props;
    const offset = (pageNo - 1) * itemsPerPage + 1;

    dispatch(
      actions.getClients({
        limit: itemsPerPage,
        offset: offset,
      })
    );
    // dispatch(
      // actions.getClients()
    // );
  };

  getClientFields = (clients) => {
    return clients.map((client) => ({
      id: client?.client_id,
      name: client?.client_name,
      url: client?.client_uri,
      redirect_uri: client?.redirect_uris,
      logo_uri: client?.logo_uri,
    }));
  };

  setPopoverRef = (ref, index) => {
    this.popoverRef[index] = ref;
    return true;
  };

  showPopover = (refKey) => {
    if (this.popoverRef.length) {
      this.popoverRef.forEach((ref, refIndex) => {
        if (ref) {
          if (refKey === refIndex && ref.style.display !== 'flex') {
            ref.style.display = 'flex';
            return;
          }
          ref.style.display = 'none';
        }
      });
    }
    return true;
  };

  openConfirmationPopUp = (client) => {
    this.setState({
      showConfirmationPopUp: true,
      clientId: client?.id,
      logoUri: client?.logo_uri,
    });
  };

  closeConfirmationPopUp = () => {
    this.setState({ showConfirmationPopUp: false });
  };

  filterClient = (word) => {
    const { dispatch } = this.props;
    this.setState({ searchValue: word, sortValue: 'default' });
    if (word.trim().length) {
      dispatch(actions.filterClients(word));
      return;
    }
    dispatch(actions.getClients());
  };

  sortClient = (sortBy) => {
    const { dispatch } = this.props;
    this.setState({ sortValue: sortBy, searchValue: '' });
    dispatch(actions.sortClients(sortBy));
  };

  render() {
    const { loading, clients, count, t } = this.props;
    const headers = [
      t('admin.clients.fields.site_name'),
      t('admin.clients.fields.site_url'),
      t('admin.clients.fields.client_id'),
      t('admin.clients.fields.redirect_uri'),
      ''];

    if (loading) {
      return <Spinner loading={loading} />;
    }

    const clientList = this.getClientFields(clients);
    return (
      <div className="relative p-5">
        <div className="my-2 mb-5">
          <span className="font-24 font-bold">{t('admin.clients.title')}</span>
        </div>
        <div className="my-4 md:flex items-center justify-between">
          <div className="flex">
            <AddFilter
              onSearch={(value) => {
                this.filterClient(value);
              }}
              sortBy={(value) => {
                this.sortClient(value);
              }}
              searchValue={this.state.searchValue}
              sortValue={this.state.sortValue}
              reset={() => {
                this.setState({ searchValue: '' , pageNo: 1, sortValue: 'default'  });
                this.getClients(this.state.pageNo, this.state.itemsPerPage);
              }}
            />
          </div>
          <div>
            <Link
              to={CLIENT_CREATE}
              type="button"
              className="shadow text-black theme-border rounded  p-2 outline-none border focus:outline-none mb-1 ease-linear transition-all duration-150"
            >
              {t('admin.clients.buttons.add_new')}
            </Link>
          </div>
        </div>
        {clientList?.length > 0 ? (
          <div className="global-wrapper-div mx-auto w-full">
            <div className="block w-full overflow-x-auto">
              <table className="items-center w-full bg-transparent border-collapse table-borderd">
                <thead>
                  <tr>
                    {headers.map((prop, uniqueKey) => (
                      <th
                        key={uniqueKey}
                        className="align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
                      >
                        <div className="flex items-center">
                          <span
                            className={
                              'weight-600 font-14 letter-spacing line-height24 text-gray'
                            }
                          >
                            {prop}
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {clientList.map((items, tableKey) => {
                    return (
                      <tr className="border-r-0 border-l-0" key={items?.id}>
                        <Column>{items.name}</Column>
                        <Column>
                          <a
                            href={items.url}
                            target="_blank"
                            rel="noreferrer"
                            className="link hover:link visited:link"
                          >
                            {items.url}
                          </a>
                        </Column>
                        <Column>{items.id}</Column>
                        <Column>
                          {items.redirect_uri?.map((uri) => (
                            <div key={uri}>{uri}</div>
                          ))}
                        </Column>

                        <Column>
                          <button
                            onClick={() => this.showPopover(tableKey)}
                            className="relative h-10 w-10 p-4 rounded-full flex justify-center items-center text-center  hover:bg-slate-200  mr-4 outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                          >
                            <i className="fas fa-ellipsis-v"></i>
                            <div
                              className="popover shadow flex-col justify-center text-left  text-gray-500"
                              id={items.id}
                              ref={(e) => this.setPopoverRef(e, tableKey)}
                            >
                              <Link
                                to={{
                                  pathname: `${CLIENT_INFORMATION}/${items?.id}`,
                                }}
                              >
                                <div className="hover:bg-slate-100 p-2">
                                  <i className="fas fa-search px-2"></i>
                                  <span>{t('common.buttons.view')}</span>
                                </div>
                              </Link>
                              <div
                                className="hover:bg-slate-100 p-2"
                                onClick={() =>
                                  this.openConfirmationPopUp(items)
                                }
                              >
                                <i className="fas fa-trash-alt px-2"></i>
                                <span>{t('common.buttons.delete')}</span>
                              </div>
                            </div>
                          </button>
                        </Column>
                      </tr>
                    );
                  })}

                  <ConformationPopup
                    open={this.state.showConfirmationPopUp}
                    closePopup={this.closeConfirmationPopUp}
                    clientId={this.state.clientId}
                    clientLogo={this.state.logoUri}
                  />
                </tbody>
              </table>
              {/* <Pager
                initialPageNo={this.state.pageNo}
                itemsPerPage={this.state.itemsPerPage}
                itemsCount={count}
                onPageChange={(pageNo, itemsPerPage) => {
                  this.setState({ pageNo: pageNo, itemsPerPage: itemsPerPage });
                }}
              /> */}
            </div>
          </div>
        ) : (
          <div className="text-center pt-20">
            <i className="fas fa-search px-2"></i>
            <h3>No Data Found</h3>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.hydra.loading,
  clients: state.hydra.clients,
  count: state.hydra.count,
});

export default withTranslation()(
  withRouter(connect(mapStateToProps)(ClientList))
);
