import { toast } from 'react-toastify';
import { ketoReadClient, ketoWriteClient, siteAccessConfig } from './config';
import routes from './routes';

export const createRole = async (payload) => {
  try {
    const options = {
      url: routes.CREATE_ROLE,
      method: 'PATCH',
      data: payload,
    };
    const response = await ketoWriteClient(options);
    return response;
  } catch (error) {
    toast.error('Failed to update role');
    throw error;
  }
};

export const getRoles = async (filters) => {
  try {
    const { namespace, relation } = siteAccessConfig;
    if (!filters.namespace) {
      filters.namespace = namespace;
    }
    if (!filters.relation) {
      filters.relation = relation;
    }
    const queryParams = new URLSearchParams(filters).toString();
    const options = {
      url: `${routes.GET_ROLES}?${queryParams}`,
      method: 'GET',
    };
    const { data } = await ketoReadClient(options);
    return data;
  } catch (error) {
    toast.error('Failed to retrieve site users');
    throw error;
  }
};

