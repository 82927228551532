import * as yup from 'yup';
const schema = yup.object().shape({
  email: yup.string().required('Email is required').email('Invalid Email'),
  password: yup.string(),
  csrf_token: yup.string().required(),
  method: yup.string().required().default('password'),
});

const fields = () => {
  const field = {};
  Object.keys(schema.fields).forEach(
    (fieldName) => (field[fieldName] = fieldName)
  );
  return field;
};

export const mappedIdentity = {
  email: 'identifier',
  password: 'password',
  csrf_token: 'csrf_token',
  method: 'method',
};

export const fieldList = Object.keys(schema.fields);
export { fields };
export default schema;

