import actions from 'actions/auth-actions';
import bbaActions from 'actions/bba-actions';
import SelectLanguage from 'components/shared/select-language';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { LOGIN } from '../../../constants/routes';
import Spinner from '../../utils/spinner';
import { Form, Formik } from 'formik';
import { FLOW_TYPES } from '../../../constants/flows';
import { getNodesFromFlow } from '../../../utils';
import 'intl-tel-input/build/css/intlTelInput.css';
import schema, {
  fieldList,
  mappedIdentity,
} from '../../../validation/register-identity';
import FieldWrapper from '../../form/field-wrapper';


class Register extends Component {
  constructor(props) {
    super(props);
    this.passwordRef = [];
  }


  async init() {

    if (this.props.flow) {
      return;
    }
    const params = new URLSearchParams(this.props.location.search);
    const flowId = params.get('flow');
    const returnTo = params.get('return_to');
    try {
      const { dispatch } = this.props;
      dispatch(actions.getSignUpFlow(flowId, returnTo));
    } catch (error) {
      console.log(error);
    }
  }

  fetchNetwork = async () => {
    const { dispatch, networkConfig, bbaLoading } = this.props;
    if (!networkConfig && !bbaLoading) {
      await dispatch(bbaActions.getNetworkConfig());
    }
  }

  fetchMaster = async () => {
    const { dispatch, masterConfig, bbaLoading } = this.props;
    if (!masterConfig && !bbaLoading) {
      await dispatch(bbaActions.getMasterConfig());
    }
  }


  async componentDidMount() {
    await this.fetchNetwork();
    await this.fetchMaster();
    this.init();
  }

  async componentDidUpdate() {
    await this.fetchNetwork();
    await this.fetchMaster();
  }

  initialValues = () => {
    if (this.props.flow) {
      const nodes = getNodesFromFlow(
        this.props.flow,
        fieldList,
        mappedIdentity,
        schema
      );
      const defaultValue = schema.fields.smsNotification.spec?.default;
      nodes['smsNotification'] = !!defaultValue;
      nodes['isAdmin'] = !!schema.fields.isAdmin.spec?.default;
      nodes['phone']['verified'] = !!schema.fields.phone.fields.verified.spec
        ?.default;
      nodes['method'] = schema.fields.method.spec?.default;
      const { networkConfig, masterConfig } = this.props;
      nodes['default_client']['client_id'] = networkConfig?.general_client?.id;
      nodes['default_client']['client_name'] = networkConfig?.general_client?.name;
      nodes['default_client']['client_uri'] = networkConfig?.general_client?.url;
      nodes['default_client']['scope'] = 'openid profile email phone address offline';
      nodes['default_client']['client_secret'] = masterConfig?.client?.general_client?.secret;

      return nodes;
    }

  };

  handleSubmit = async (formData) => {
    const { dispatch, networkConfig } = this.props;

    if (formData.confirm_password) {
      delete formData.confirm_password;
    }

    const payload = {};
    Object.keys(formData).forEach((key) => {
      const schemaField = schema.fields[key];
      if (schemaField?.type === 'object') {
        Object.keys(schemaField?.fields).forEach((prop) => {
          const buildField = `${mappedIdentity[key]}.${prop}`;
          payload[buildField] =
            formData[key][prop] !== undefined ? formData[key][prop] : '';
        });
        return;
      }
      payload[mappedIdentity[key]] = formData[key];
    });

    await dispatch(
      actions.submitFlow(this.props?.flow, FLOW_TYPES.REGISTER, payload, { master_client_id: networkConfig?.master_client_id })
    );
  };

  toggleShowPassword = (refKey) => {
    const element = this.passwordRef[refKey];
    const types = ['password', 'text'];
    if (element) {
      const index = +!types.findIndex(e => e === element.type);
      this.passwordRef[refKey].type = types[index]
    }
  }

  render() {
    const { loading, bbaLoading, t, networkConfig, masterConfig } = this.props;
    if (loading || bbaLoading) {
      return <Spinner loading={true} />;
    }

    return (
      <div className="py-6 flex flex-col justify-center relative sm:py-12">
        <div className="flex items-center m-auto">
          <div
            className="md:mx-auto flex p-6 mx-2"
            style={{ boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)' }}
          >
            <section className="relative w-full pt-25">
              <div className="grid">
                <div className="container mx-auto px-4 h-full">
                  <div className="flex content-center items-center justify-center h-full">
                    <div className="relative flex flex-col w-full mb-6 rounded-lg bg-white">
                      <div className="mb-0 px-5 py-6">
                        <div className="mb-3 text-center">
                          <h3 className="text-3xl p-2 font-bold">
                            {t('signup.title.text')}
                          </h3>
                        </div>
                        <div className="text-left py-0 font-14">
                          {t('signup.title.description')}

                        </div>
                      </div>
                      <div className="flex-auto px-4 py-9 pt-0">
                        <Formik
                          initialValues={this.initialValues()}
                          onSubmit={this.handleSubmit}
                          validationSchema={schema}
                          validateOnBlur={false}
                          validateOnChange={false}
                        >
                          {({ errors, values, handleChange }) => {
                            if (values) {
                              return (
                                <Form>
                                  <FieldWrapper
                                    error={errors.email}
                                    required={true}
                                    title={t('signup.fields.email')}
                                    element="email"
                                  >
                                    <input
                                      value={values.email}
                                      name="email"
                                      type="email"
                                      onChange={handleChange}
                                      className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-1"
                                    />
                                  </FieldWrapper>
                                  <div className="sm:flex sm:gap-10 justify-center">
                                    <FieldWrapper
                                      error={errors.password}
                                      required={true}
                                      title={t('signup.fields.password')}
                                      element="password"
                                      classes="sm:w-full"
                                    >
                                      <div
                                        className="appearance-none block w-full relative bg-white text-gray-700  rounded-lg  mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                      >
                                        <input
                                          value={values['password']}
                                          name="password"
                                          type="password"
                                          onChange={handleChange}
                                          ref={e => this.passwordRef[0] = e}
                                          className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        />
                                        <i className='fa fa-eye rounded-lg appearance-none absolute -translate-x-1/2 -translate-y-1/2 top-1/2 right-1  text-gray-600'
                                          onClick={() => this.toggleShowPassword(0)}
                                        />
                                      </div>
                                    </FieldWrapper>

                                    <FieldWrapper
                                      error={errors.confirm_password}
                                      required={true}
                                      title={t('signup.fields.confirm_password')}
                                      classes="sm:w-full"
                                      element=" confirm_password"
                                    >
                                      <div
                                        className="appearance-none block w-full relative bg-white text-gray-700  rounded-lg  mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                      >
                                        <input
                                          value={values.confirm_password}
                                          name="confirm_password"
                                          type="password"
                                          onChange={handleChange}
                                          ref={e => this.passwordRef[1] = e}
                                          className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-1 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        />
                                        <i className='fa fa-eye rounded-lg appearance-none absolute -translate-x-1/2 -translate-y-1/2 top-1/2 right-1  text-gray-600'
                                          onClick={() => this.toggleShowPassword(1)}
                                        />
                                      </div>
                                    </FieldWrapper>
                                  </div>
                                  <FieldWrapper>
                                    <button
                                      type="submit"
                                      className="btn-primary shadow text-white py-2 disabled:btn-disabled rounded w-full hover:shadow-lg p-2 px-8 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
                                      disabled={!networkConfig?.general_client || !masterConfig?.client?.general_client?.secret}
                                    >
                                      {t('signup.buttons.register')}
                                    </button>
                                  </FieldWrapper>
                                </Form>
                              );
                            }
                          }}
                        </Formik>
                        <div className="text-center mt-4">
                          <span className="">{t('signup.links.already_user')} </span>
                          <Link to={LOGIN}>
                            <span className="text-primary">{t('signup.links.login')}</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
        <div className='mx-auto flex sm:w-1/3 xl:w-1/4 w-2/3'>
          <SelectLanguage />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  bbaLoading: state.bba.loading,
  networkConfig: state.bba.networkConfig,
  masterConfig: state.bba.masterConfig,
  adminLoading: state.oryAdmin.loading,
  flow: state.auth.signupFlow,
});

export default withTranslation()(withRouter(connect(mapStateToProps)(Register)));
