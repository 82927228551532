const getNode = (nodes, field, mappedIdentity) =>
  nodes.find((node) => {
    const identity = mappedIdentity[field];
    return node?.attributes?.name === identity;
  });

export const getNodesFromFlow = (
  flow,
  fieldList,
  mappedIdentity,
  schema = undefined
) => {
  const nodes = {};
  fieldList.forEach((field) => {
    const fieldObj = schema?.fields[field];
    if (fieldObj?.type === 'array') {
      nodes[field] = [];
      let arrayProps = Object.keys(fieldObj?.innerType?.fields || {});
      let index = 1;
      let emptyObj = {};
      while (index) {
        nodes[field][index - 1] = {};
        arrayProps?.forEach((prop) => {
          if (index) {
            const buildField = `${mappedIdentity[field]}.${index - 1}.${prop}`;
            const node = flow?.ui?.nodes.find(
              (node) => node?.attributes.name === buildField
            );

            if (!node) {
              // to get last empty object in array to save new values
              nodes[field][index - 1] = emptyObj;
              index = 0;
              return;
            }
            nodes[field][index - 1][prop] =
              node?.attributes?.value !== undefined
                ? node?.attributes?.value
                : '';
            emptyObj[prop] = '';
          }
        });
        if (index) {
          index = index + 1;
        }
      }
      return;
    }
    if (fieldObj?.type === 'object') {
      nodes[field] = {};
      Object.keys(fieldObj?.fields).forEach((key) => {
        const buildField = `${mappedIdentity[field]}.${key}`;
        const node = flow?.ui?.nodes.find((node) => {
          return node?.attributes?.name === buildField;
        });
        nodes[field][key] =
          node?.attributes?.value !== undefined ? node?.attributes?.value : '';
      });
      return;
    }

    const node = getNode(flow?.ui?.nodes, field, mappedIdentity);
    nodes[field] =
      node?.attributes?.value !== undefined ? node?.attributes?.value : '';
  });
  return nodes;
};

export const constructFlowPayloadFromArray = (
  field,
  arr = [],
  defaults = {}
) => {
  const payload = {};
  arr.forEach((item, index) => {
    Object.keys(item).forEach((prop) => {
      const buildField = `[${field}.${index}.${prop}]`;
      payload[buildField] = item[prop] || '';
      if (!payload[buildField] && defaults[prop] !== undefined) {
        payload[buildField] = defaults[prop];
      }
    });
  });
  return payload;
};

/**
 * Fetched data from identity and save it to appropriate field in fieldList and returns constructed object.
 * if field not found in identity then saves field value as empty string in constructed object.
 * if field type is object then recursively performs the same steps again.
 * @param {*} identity
 * @param {*} fieldList
 * @param {*} schema
 * @returns
 */

export const getNodesFromIdentity = (
  identity,
  fieldList,
  schema = undefined
) => {
  const nodes = {};
  fieldList.forEach((field) => {
    const fieldObj = schema?.fields[field];
    if (fieldObj?.type === 'object' && identity?.traits[field]) {
      const obj = {
        traits: {},
      };
      const keys = Object.keys(fieldObj?.fields);
      keys.forEach((key) => {
        obj.traits[key] = identity?.traits[field][key] || '';
      });
      nodes[field] = getNodesFromIdentity(obj, keys, fieldObj);
      return;
    }
    nodes[field] = identity?.traits[field] || '';
  });
  return nodes;
};

export const cleanEmptyNodes = (obj) => {
  const emptyState = [null, undefined, ''];
  return JSON.parse(
    JSON.stringify(obj, (key, value) => {
      return emptyState.includes(value) ? undefined : value;
    })
  );
};
