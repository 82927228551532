import { default as PropTypes, default as propTypes } from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import sidebarActions from '../../actions/sidebar-actions';

function SidebarMenuItem({ activePage, link, icon, title, submenu, dispatch,t, i18nkey }) {
  const location = useLocation();
  const toggleSidebar = (toggle) => {
    if (!toggle && window?.screen?.width <= 768) {
      dispatch(sidebarActions.toggleSidebar());
    }
  };
  return (
    <div onClick={() => toggleSidebar(submenu.length)}>
      <Link
        to={link}
        className={`font-18 p-2 font-medium text-white block rounded ${
          activePage === title
            ? 'bg-emerald-sidebar-custom  outline outline-1 outline-black'
            : ''
        }`}
      >
        <div>
          {' '}
          {icon !== '' ? <i className={'fas mr-5 fa-' + icon}></i> : null}
          {t(i18nkey)}
          <span className="nav-icon-color float-right">
            {submenu.length > 0 ? (
              <i
                className={
                  'fas fa-' +
                  (submenu.length > 0 && activePage === title
                    ? 'chevron-up'
                    : 'chevron-down')
                }
              ></i>
            ) : null}
          </span>
        </div>
      </Link>
      {submenu.length > 0 ? (
        <div
          onClick={() => toggleSidebar(false)}
          className={
            'transition-all duration-300 ease-in-out relative grid grid-cols-1 gap-4 text-white pr-4 pl-10 ' +
            (submenu.length > 0 && activePage === title ? '' : 'hidden')
          }
        >
          <div className="relative flex gap-4 pb-3">
            <div className="flex flex-col w-full">
              <div className="justify-between">
                {submenu.map((index, key) => (
                  <li key={key} className="mt-2">
                    <Link
                      to={index.link}
                      className={
                        'font-16 ml-2 block p-0 ' +
                        (index.link === location.pathname
                          ? 'font-bold'
                          : 'text-white')
                      }
                    >
                      {' '}
                      {t(index.i18nkey)}
                    </Link>
                  </li>
                ))}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
}

SidebarMenuItem.defaultProps = {
  activePage: '',
  icon: '',
  link: '',
  title: '',
  submenu: '',
  i18nkey:'',
};

SidebarMenuItem.propTypes = {
  activePage: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
  icon: PropTypes.string,
  submenu: propTypes.any,
  i18nkey:propTypes.any
};
const mapStateToProps = (state) => ({
  sidebar: state.sidebar,
});
export default withTranslation()(connect(mapStateToProps)(SidebarMenuItem));
