import * as yup from 'yup';
const passwordExp = /^(?=.*[0-9])(?=.*[!@#$%^&*?])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*?]{8,}$/;

const schema = yup.object().shape({
    password: yup
        .string()
        .required('Password is required')
        .matches(
            passwordExp,
            'Password must be at least 8 characters long that include at least 1 lowercase character, 1 uppercase character, 1 number and 1 special character in (!@#$%^&*?)'
        ),
    confirm_password: yup
        .string()
        .required('Confirm password is required')
        .oneOf(
            [yup.ref('password'), null],
            'Password and confirm password did not match'
        ),
    csrf_token: yup.string().required(),
    method: yup.string().required().default('password'),
});

const fields = () => {
    const field = {};
    Object.keys(schema.fields).forEach(
        (fieldName) => (field[fieldName] = fieldName)
    );
    return field;
};

export const mappedIdentity = {
    password: 'password',
    csrf_token: 'csrf_token',
    method: 'method',
};

export const fieldList = Object.keys(schema.fields);
export { fields };
export default schema;

