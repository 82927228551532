import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const getMessageContext = (flow = null, defaultText = null) => {
  const nodeErrors = [];
  let context = [
    {
      text: defaultText?.text,
      type: defaultText?.type !== undefined ? defaultText.type : 'info',
    },
  ];
  if (flow && flow.ui && flow.ui.messages) {
    context = flow.ui.messages.map((message) => {
      return {
        text: message.text,
        type: message.type,
      };
    });
  }
  if (flow && flow.ui && flow.ui.nodes[0]) {
    flow.ui.nodes.forEach((node) => {
      node.messages.forEach((message) => {
        const messageContext = {
          text: message.text,
          type: message.type,
        };
        nodeErrors.push(messageContext);
      });
    });
  }

  return [...context, ...nodeErrors];
};

export default (flow, defaultText) =>
  getMessageContext(flow, defaultText).forEach(({ text, type }) => {
    switch (type) {
      case 'error':
        return toast.error(text);
      case 'success':
        return toast.success(text);
      case 'warning':
        return toast.warning(text);
      default:
        return toast.success(text);
    }
  });

