import env from './env';
import axios from 'axios';
const credentials = `${env.TWILIO_ACCOUNT_SID}:${env.TWILIO_API_KEY}`;
export default axios.create({
  headers: {
    Authorization: `Basic ${Buffer.from(credentials).toString('base64')}`,
    'Content-Type': 'application/x-www-form-urlencoded ',
  },
});

