import * as yup from 'yup';
const schema = yup.object().shape({
  email: yup
    .array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(yup.string().email(({ value }) => `${value} is not a valid email`)),
  first_name: yup.string(),
  last_name: yup.string(),
  birth_date: yup.date(),
  address: yup.object({
    area: yup.string(),
    neighbourhood: yup.string(),
  }),

  phone: yup.object({
    verified: yup.boolean().default(false),
    number: yup.number(),
    country_code: yup.string(),
    dial_code: yup.string(),
  }),
  telegram: yup.object({
    connected: yup.boolean().default(false),
    number: yup.number(),
    country_code: yup.string(),
    dial_code: yup.string(),
  }),
  display_name: yup.string(),
  username: yup.string(),
  csrf_token: yup.string(),
  method: yup.string().default('password'),
  isAdmin: yup.boolean().default(false),
  language: yup.object({
    code: yup.string(),
    value: yup.string(),
  }),
});

// phone: yup.array([
//   yup.object(  {
//     country_code: yup.number(),
//     number: yup.number(),
//     isDefault: yup.boolean(),
//     isVerified: yup.boolean()
//   })
// ])

const fields = () => {
  const field = {};
  Object.keys(schema.fields).forEach(
    (fieldName) => (field[fieldName] = fieldName)
  );
  return field;
};

export const mappedIdentity = {
  email: 'traits.email',
  first_name: 'traits.first_name',
  last_name: 'traits.last_name',
  username: 'traits.username',
  birth_date: 'traits.birth_date',
  display_name: 'traits.display_name',
  city: 'traits.city',
  phone: 'traits.phone',
  phone_verified: 'traits.phone_verified',
  telegram_verified: 'traits.telegram_verified',
  telegram: 'traits.telegram',
  address: 'traits.address',
  state: 'traits.state',
  zipcode: 'traits.zipcode',
  csrf_token: 'csrf_token',
  method: 'method',
  isAdmin: 'traits.isAdmin',
  country_code: 'traits.country_code',
  language: 'traits.language',
  // emails: 'traits.emails',
};

export const fieldList = Object.keys(schema.fields);
export { fields };
export default schema;

