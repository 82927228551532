/* auth pages */
export const LOGIN = '/login';
export const REGISTER = '/register';
export const LOGOUT = '/logout';
export const FORGOT_PASS = '/forgot-password';
export const ACCOUNT_RECOVERY_STEP_1 = '/account-recovery-step-1';
export const ACCOUNT_RECOVERY_STEP_2 = '/account-recovery-step-2';
export const ACCOUNT_RECOVERY_STEP_3 = '/account-recovery-step-3';
export const ACCOUNT_RECOVERY_STEP_4 = '/account-recovery-step-4';
export const MODERATION_WARNING = '/account-moderation-warning';
export const ACCOUNT_ACCEPTED = '/account-accepted';
export const ACCOUNT_REJECTED = '/account-rejected';

/* main pages */
export const HOME = '/home';
export const PERSONAL_INFO = '/personal-info';
export const INFORMATION_SHOW = '/information-show';
export const INFORMATION_EDIT = '/information-edit';
export const PERSONAL_INFO_VERIFICATION = '/personal-info-verification';
export const STEP_VERIFICATION = '/step-verification';
export const RESET_PASSWORD = '/reset-password';
export const RECOVERY_EMAIL = '/recovery-email';
export const VOULT = '/vault';
export const BUSINESS_INFO = '/business-info';
export const ACCOUNT_LIST = '/admin/account-list';
export const ADMIN_HOME = '/admin/home';
export const IDENTITIES_LIST = '/admin/identities-list';
export const IDENTITIES_EDIT = '/admin/identities-edit';
export const IDENTITIES_INFORMATION = '/admin/identities-information';
export const ERRORS = '/errors';
export const VERIFY = '/verify-account';
export const CLIENT_LIST = '/admin/client-list';
export const CLIENT_CREATE = '/admin/client-create';
export const CLIENT_INFORMATION = '/admin/client-information';
export const CLIENT_EDIT = '/admin/client-edit';
export const VERIFY_OTP = '/verify-otp';
export const TERMS_AND_CONDITIONS = '/terms-and-conditions';

