
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import actions from '../../../actions/auth-actions';
import { FLOW_TYPES } from '../../../constants/flows';
import { LOGIN } from '../../../constants/routes';
import Flow from '../../ory/flow';
import Spinner from '../../utils/spinner';

class AccountRecoverStep1 extends Component {
  async init(params) {
    const flowId = params.get('flow');
    const returnTo = params.get('return_to');
    try {
      const { dispatch } = this.props;
      dispatch(actions.getSettingsFlow(flowId, returnTo));
    } catch (error) {
      console.log(error);
    }
  }

  extractChangePassWordUi() {
    const { flow } = this.props;
    const groups = ['default', 'password'];
    if (flow?.ui?.nodes) {
      const nodes = flow.ui.nodes.filter(node => groups.includes(node.group));
      flow.ui.nodes = nodes;
    }
  }

  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search)
    this.init(params);
  }

  render() {
    const { flow, loading } = this.props;
    if (loading) {
      return (<Spinner loading={loading} />)
    }
    else {
      this.extractChangePassWordUi()
      return (
        <div className='min-h-screen py-6 flex flex-col justify-center relative overflow-hidden sm:py-12'>
          <div className='flex items-center w-full'>
            <div className="md:mx-auto flex p-8" style={{ boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)' }}>
              <section className="relative w-full pt-25">
                <div className='grid'>
                  <div className="container mx-auto px-6 h-full">
                    <div className="flex content-center items-center justify-center h-full">
                      <div className="relative flex flex-col w-full mb-6 rounded-lg bg-white">
                        <div className="mb-0 md:m-5 px-5 px-6">
                          <div className="text-center">
                            <h3 className="text-3xl p-2 font-bold">Account Recovery</h3>
                          </div>
                          <div className="text-center font-20">
                            Change your password
                          </div>
                        </div>
                        <div className="flex-auto px-4 py-9 pt-0">
                          {flow && <Flow flow={flow} type={FLOW_TYPES.CHANGE_PASSWORD} />}
                          {/* <form>


                          <div className="flex flex-wrap -mx-3">
                            <div className="w-full">
                              <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                                Email or Phone
                              </label>
                              <input className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                id="grid-password" type="password" placeholder="Email or phone" />
                            </div>
                          </div>

                          <div className="container mx-auto mt-3 ">
                            <div className="flex flex-wrap">
                              <div className="w-full flex-1 block m-auto">
                                <span className='text-primary col'> Forgot Email ?</span>
                              </div>
                              <div className="">
                                <button className="bg-green-400 col uppercase text-white font-bold py-2 px-4 rounded">
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        </form> */}
                          <div className='text-center mt-4'>
                            <span className=''>Go back to </span><Link to={LOGIN}><span className='text-primary'>Login</span></Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section >
            </div>
          </div>
        </div >
      )
    }
  }
}

const mapStateToProps = state => ({
  flow: state.auth.settingsFlow,
  loading: state.auth.loading,
  user: state.auth.user,
});

export default connect(
  mapStateToProps,

)(AccountRecoverStep1);