import { connect } from 'react-redux';
import { config } from '../../constants/config';

const headers = [
  '',
  'Username',
  'First Name',
  'Last Name',
  'Date of Birth',
  'Email',
  'Phone',
  'Score',
  'Status',
  'Membership Level',
  '',
  '',
  '',
  '',
];

const accountDataList = [
  {
    profile: config.PROFILE,
    username: 'Johny',
    first_name: 'JohnyS',
    last_name: 'Sebastian',
    dob: 'June 3, 1999',
    email: 'johnnys@example.com',
    phone: '13 00 00',
    score: '95/100',
    status: 'approved',
    membership: 'Business user',
  },
  {
    profile:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRB_mpdTkAOjNZ8_N9SNFkzq7XCHXP81acoXPoHCRNi1o7IQk3EMPXwcA7kEVjw9jMHXos&usqp=CAU',
    username: 'Jack',
    first_name: 'Jsck',
    last_name: 'Sebastian',
    dob: 'June 3, 1999',
    email: 'johnnys@example.com',
    phone: '13 00 00',
    score: '95/100',
    status: 'approved',
    membership: 'Business user',
  },
  {
    profile:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRB_mpdTkAOjNZ8_N9SNFkzq7XCHXP81acoXPoHCRNi1o7IQk3EMPXwcA7kEVjw9jMHXos&usqp=CAU',
    username: 'Joice',
    first_name: 'Joice',
    last_name: 'Sebastian',
    dob: 'June 3, 1999',
    email: 'johnnys@example.com',
    phone: '13 00 00',
    score: '95/100',
    status: 'approved',
    membership: 'Business user',
  },
  {
    profile: config.PROFILE,
    username: 'Johny',
    first_name: 'JohnyS',
    last_name: 'Sebastian',
    dob: 'June 3, 1999',
    email: 'johnnys@example.com',
    phone: '13 00 00',
    score: '95/100',
    status: 'approved',
    membership: 'Business user',
  },
  {
    profile:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRB_mpdTkAOjNZ8_N9SNFkzq7XCHXP81acoXPoHCRNi1o7IQk3EMPXwcA7kEVjw9jMHXos&usqp=CAU',
    username: 'Jack',
    first_name: 'Jsck',
    last_name: 'Sebastian',
    dob: 'June 3, 1999',
    email: 'johnnys@example.com',
    phone: '13 00 00',
    score: '95/100',
    status: 'approved',
    membership: 'Business user',
  },
  {
    profile:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRB_mpdTkAOjNZ8_N9SNFkzq7XCHXP81acoXPoHCRNi1o7IQk3EMPXwcA7kEVjw9jMHXos&usqp=CAU',
    username: 'Joice',
    first_name: 'Joice',
    last_name: 'Sebastian',
    dob: 'June 3, 1999',
    email: 'johnnys@example.com',
    phone: '13 00 00',
    score: '95/100',
    status: 'approved',
    membership: 'Business user',
  },
];

function AccountList() {
  return (
    <div className="relative p-5">
      <div className="my-2">
        <span className="font-24 font-bold">Account List</span>
      </div>
      <div className="global-wrapper-div mx-auto w-full">
        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse table-borderd">
            <thead>
              <tr>
                {headers.map((prop, uniqueKey) => (
                  <th
                    key={uniqueKey}
                    className="align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
                  >
                    <div className="flex items-center">
                      <span
                        className={
                          'weight-600 font-14 letter-spacing line-height24 text-gray'
                        }
                      >
                        {prop}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {accountDataList.map((Items, tableKey) => {
                return (
                  <tr className="border-r-0 border-l-0" key={tableKey}>
                    <td className="px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="header-align flex items-center">
                        <img
                          src={Items.profile}
                          className="w-1/2 image-center rounded-full"
                          alt=""
                        />
                      </div>
                    </td>
                    <td className=" align-middle border-l-0  border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex items-center">
                        <span
                          className={
                            'weight-400 font-16 letter-spacing line-height24 text-black'
                          }
                        >
                          {Items.username}
                        </span>
                      </div>
                    </td>
                    <td className=" align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex items-center">
                        <span
                          className={
                            'weight-400 font-16 letter-spacing line-height24 text-black'
                          }
                        >
                          {Items.first_name}
                        </span>
                      </div>
                    </td>
                    <td className=" align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex items-center">
                        <span
                          className={
                            'weight-400 font-16 letter-spacing line-height24 text-black'
                          }
                        >
                          {Items.last_name}
                        </span>
                      </div>
                    </td>
                    <td className=" align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex items-center">
                        <span
                          className={
                            'ml-3 weight-400 font-16 letter-spacing line-height24 text-black'
                          }
                        >
                          {Items.dob}
                        </span>
                      </div>
                    </td>
                    <td className=" align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex items-center">
                        <span
                          className={
                            'weight-400 font-16 letter-spacing line-height24 text-black'
                          }
                        >
                          {Items.email}
                        </span>
                      </div>
                    </td>
                    <td className=" align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex items-center">
                        <span
                          className={
                            'weight-400 font-16 letter-spacing line-height24 text-black'
                          }
                        >
                          {Items.phone}
                        </span>
                      </div>
                    </td>
                    <td className=" align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex items-center">
                        <span
                          className={
                            'weight-400 font-16 letter-spacing line-height24 text-black'
                          }
                        >
                          {Items.score}
                        </span>
                      </div>
                    </td>
                    <td className=" align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex items-center">
                        <span
                          className={
                            'weight-400 font-16 letter-spacing line-height24 text-black'
                          }
                        >
                          {Items.status}
                        </span>
                      </div>
                    </td>
                    <td className="px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex items-center">
                        <span
                          className={
                            'weight-400 font-16 letter-spacing line-height24 text-black'
                          }
                        >
                          {Items.membership}
                        </span>
                      </div>
                    </td>
                    <td className="px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex items-center">
                        <span
                          className={
                            'weight-400 font-16 letter-spacing line-height24 text-black'
                          }
                        >
                          <i className="fas fa-check text-primary"></i>
                        </span>
                      </div>
                    </td>
                    <td className="px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex items-center">
                        <span
                          className={
                            'weight-400 font-16 letter-spacing line-height24 text-black'
                          }
                        >
                          <i className="fas fa-times text-red"></i>
                        </span>
                      </div>
                    </td>
                    <td className="px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex items-center">
                        <span
                          className={
                            'weight-400 font-16 letter-spacing line-height24 text-black'
                          }
                        >
                          <i className="fas fa-eye weight-400"></i>
                        </span>
                      </div>
                    </td>
                    <td className="px-3 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex items-center">
                        <span
                          className={
                            'weight-400 font-16 letter-spacing line-height24 text-black'
                          }
                        >
                          <i className="fas fa-ellipsis-v"></i>
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(AccountList);

