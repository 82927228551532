import React from 'react';
import cities from '../../constants/cities';
import { buttonProps, buttonText, defaultProps } from '../../constants/flows';
const getStyleByContext = (message) => {
  const style = {
    color: 'green',
    fontSize: '1em',
    marginLeft: '5px'
  };
  switch (message?.type) {
    case 'error':
      style.color = 'red';
      break;
    case 'warning':
      style.color = 'yellow';
      break;
    case 'info':
      style.color = 'green';
      break;
  }
  return style;
}

const Message = (node) => {
  const { messages } = node;
  return (
    messages && messages.map((message, key) => (
      <p key={key} style={getStyleByContext(message)}>{message.text}</p >
    ))
  );
}

class InputElement extends React.Component {

  getPlaceholderAndTitleFromNode = (node) => {
    let label = node && node.meta && node.meta.label && node.meta.label.text;
    const nodeAttributes = {
      title: label || node.attributes.name,
      placeholder: '',
    }
    if (label && label.startsWith('{') && label.endsWith('}')) {
      label = JSON.parse(label);
      nodeAttributes.title = label.title;
      nodeAttributes.placeholder = label.placeholder;
    }
    return nodeAttributes;
  }

  render() {
    const { node, value, onChange, validator } = this.props;
    const { placeholder, title } = this.getPlaceholderAndTitleFromNode(node);
    return (
      <div className="flex flex-wrap -mx-3 mb-2">
        <div className="w-full px-1">
          <label className="flex tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
            {title}{validator?.required && <span className='text-red ml-2'>*</span>}
            {Message(node)}
          </label>
          <input name={node.attributes.name} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
            type={node.attributes.type} placeholder={placeholder} value={value || ''} onChange={(e) => onChange(e.target.value, node.attributes.name)} />
        </div>
      </div >
    );
  }
}

// class PersonalInputElement extends React.Component {

//   getPlaceholderAndTitleFromNode = (node) => {
//     let label = node && node.meta && node.meta.label && node.meta.label.text;
//     const nodeAttributes = {
//       title: label || node.attributes.name,
//       placeholder: '',
//     }
//     if (label && label.startsWith('{') && label.endsWith('}')) {
//       label = JSON.parse(label);
//       nodeAttributes.title = label.title;
//       nodeAttributes.placeholder = label.placeholder;
//     }
//     return nodeAttributes;
//   }

//   render() {
//     const { node, value, onChange, validator } = this.props;
//     const { placeholder, title } = this.getPlaceholderAndTitleFromNode(node);
//     return (
//       <div className="flex flex-wrap -mx-3 mb-3">
//         <div className="w-full px-1 flex">
//           <label className="w-3/12 flex tracking-wide text-gray-700 text-lg font-bold mb-2" htmlFor="grid-password">
//             {title}
//             {/* {validator?.required && <span className='text-red ml-2'>*</span>} */}
//             {Message(node)}
//           </label>
//           <input name={node.attributes.name} className="appearance-none block w-9/12 bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
//             type={node.attributes.type} placeholder={placeholder} value={value || ''} onChange={(e) => onChange(e.target.value, node.attributes.name)} />
//         </div>
//       </div >
//     );
//   }
// }

class HiddenElement extends React.Component {
  render() {
    const { node, value } = this.props;
    return (
      <input name={node.attributes.name} type="hidden" value={value} />
    );
  }
}

class ButtonElement extends React.Component {
  render() {
    const { node, value, flowType } = this.props;
    const label = buttonText[flowType] || node.meta.label.text;
    const props = buttonProps[flowType] || defaultProps;
    const { group } = node;
    return (
      <div className="flex flex-wrap -mx-3 my-2">
        <div className="w-full px-1">
          <button name={node.attributes.name} {...props}
            type={node.attributes.type} value={value} formNoValidate={group === 'oidc'}>
            {label}
          </button>
          {Message(node)}
        </div>
      </div >
    )
  }
}

class DropDownElement extends React.Component {

  getPlaceholderAndTitleFromNode = (node) => {
    let label = node && node.meta && node.meta.label && node.meta.label.text;
    const nodeAttributes = {
      title: label || node.attributes.name,
      placeholder: '',
    }
    if (label && label.startsWith('{') && label.endsWith('}')) {
      label = JSON.parse(label);
      nodeAttributes.title = label.title;
      nodeAttributes.placeholder = label.placeholder;
    }
    return nodeAttributes;
  }

  render() {
    const { node, value, onChange, validator, options } = this.props;
    const { placeholder, title } = this.getPlaceholderAndTitleFromNode(node);
    return (
      <div className="flex flex-wrap -mx-3 mb-2">
        <div className="w-full px-1">
          <label className="flex tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
            {title}{validator?.required && <span className='text-red ml-2'>*</span>}
            {Message(node)}
          </label>
          <select name={node.attributes.name} onChange={(e) => onChange(e.target.value, node.attributes.name)} value={value} className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500" >
            <option value={undefined}>{placeholder}</option>
            {
              options.map((option, key) => (
                <option value={option} key={key}>
                  {option}
                </option>
              ))
            }
          </select>
        </div>
      </div>)
  }
}

export default class Node extends React.Component {
  render() {
    const { node, validator } = this.props;
    if (this.props.node.attributes.name === 'identifier' && this.props?.node?.meta?.label) {
      this.props.node.meta.label.text = '{"title":"Email", "placeholder":"name@example.com"}'
    }
    if (this.props.node.attributes.name === 'password' && this.props?.node?.meta?.label) {
      this.props.node.meta.label.text = '{"title":"Password", "placeholder":"atleast 8 characters"}'
    }
    if (this.props.node.attributes.name === 'traits.confirm_password') {
      this.props.node.attributes.type = 'password';
    }
    switch (node.attributes.type) {
      case 'text':
      case 'password':
      case 'email':
      case 'number':
      case 'date':
        if (node.attributes.name === 'traits.city') {
          return (<DropDownElement options={cities} {...this.props} />)
        }
        if (validator?.skip) {
          return (<HiddenElement {...this.props} />);
        }
        return (<InputElement {...this.props} />);
      case 'hidden':
        return (<HiddenElement {...this.props} />);
      case 'submit':
        return (<ButtonElement {...this.props} />);
      // case 'date':
      //   return (<DateElement {...this.props} />)
      default:
        return null;
    }
  }
}