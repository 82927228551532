import { Form, Formik } from 'formik';
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import actions from '../../actions/auth-actions';
import { FLOW_TYPES } from '../../constants/flows';
import { getNodesFromFlow } from '../../utils';
import schema, {
  fieldList,
  mappedIdentity,
} from '../../validation/personal-info-fields';
import PersonalInfoFieldWrapper from '../form/personal-info-form-wrapper';
import Spinner from '../utils/spinner';
class PersonalInfoVerification extends Component {
  params = new URLSearchParams(this.props.location.search);
  flowId = this.params.get('flow');
  aal = this.params.get('aal');
  returnTo = this.params.get('return_to');
  refresh = this.params.get('refresh');
  async init() {
    try {
      const { dispatch } = this.props;
      dispatch(actions.getSettingsFlow(this.flowId, this.returnTo));
    } catch (error) {
      console.log(error);
    }
  }

  componentDidMount = async () => {
    await this.init();
  };

  initialValues = () => {
    if (this.props.flow) {
      const nodes = getNodesFromFlow(
        this.props.flow,
        fieldList,
        mappedIdentity
      );
      return nodes;
    }
  };

  handleSubmit = (formData) => {
    const { dispatch } = this.props;
    const payload = {};
    Object.keys(formData).forEach((key) => {
      payload[mappedIdentity[key]] = formData[key];
    });
    dispatch(
      actions.submitFlow(this.props?.flow, FLOW_TYPES.SETTINGS, payload)
    );
  };

  render() {
    const { flow, loading } = this.props;
    if (loading) {
      return <Spinner loading={loading} />;
    } else if (flow) {
      return (
        <div className="relative p-5 md:p-8 lg:p-10 w-full md:w-4/6 lg:w-4/5 xl:w-5/6">
          <div
            className="w-full border border-gray-200 rounded"
            style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)' }}
          >
            <div className="px-8 pt-8 mb-6">
              <span className="text-2xl font-bold">
                Additional Personal Account Information
              </span>
            </div>
            <div className="mx-2 mt-5 ">
              {/* {flow && <Flow flow={flow} type={FLOW_TYPES.SETTINGS} validationSchema={this.validationSchema} />} */}
              <Formik
                initialValues={this.initialValues()}
                onSubmit={this.handleSubmit}
                validationSchema={schema}
                validateOnBlur={false}
                validateOnChange={false}
              >
                {({ errors, values, handleChange }) => {
                  if (values) {
                    return (
                      <Form>
                        <PersonalInfoFieldWrapper
                          error={errors.first_name}
                          required={true}
                          title="First Name"
                          element="first_name"
                        >
                          <input
                            value={values.first_name}
                            name="first_name"
                            type="text"
                            onChange={handleChange}
                            disabled
                            className="appearance-none block w-full md:w-9/12 disable-input-background text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          />
                        </PersonalInfoFieldWrapper>
                        {/*
                          <input
                            value={values.display_name}
                            type="text"
                            onChange={handleChange}
                          className="appearance-none block w-full md:w-9/12 disable-input-background text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          /> */}

                        <PersonalInfoFieldWrapper
                          error={errors.last_name}
                          required={true}
                          title="Last Name"
                          element="last_name"
                        >
                          <input
                            value={values.last_name}
                            name="last_name"
                            type="text"
                            onChange={handleChange}
                            disabled
                            className="appearance-none block w-full md:w-9/12 disable-input-background  bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          />
                        </PersonalInfoFieldWrapper>

                        <PersonalInfoFieldWrapper
                          error={errors.birth_date}
                          required={true}
                          title="Date of birth"
                          element="birth_date"
                        >
                          <input
                            value={values.birth_date}
                            name="birth_date"
                            type="date"
                            onChange={handleChange}
                            className="appearance-none block w-full md:w-9/12 bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          />
                        </PersonalInfoFieldWrapper>

                        <PersonalInfoFieldWrapper
                          error={errors.address}
                          required={true}
                          title="Address"
                          element="address"
                        >
                          <input
                            value={values.address}
                            name="address"
                            type="text"
                            onChange={handleChange}
                            className="appearance-none block w-full md:w-9/12 bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          />
                        </PersonalInfoFieldWrapper>

                        <PersonalInfoFieldWrapper
                          error={errors.city}
                          title="City"
                          element="city"
                          required
                        >
                          <input
                            value={values.city}
                            name="city"
                            type="text"
                            onChange={handleChange}
                            className="appearance-none block w-full md:w-9/12 bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          />
                          {/* <select
                            name="city"
                            onChange={handleChange}
                            value={city}
                            className="appearance-none block w-full md:w-9/12 bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          >
                            <option value={undefined}>Select your city</option>
                            {cities.map((option, key) => (
                              <option value={option} key={key}>
                                {option}
                              </option>
                            ))}
                          </select> */}
                        </PersonalInfoFieldWrapper>

                        <PersonalInfoFieldWrapper
                          error={errors.state}
                          required={true}
                          title="State"
                          element="state"
                        >
                          <input
                            value={values.state}
                            name="state"
                            type="text"
                            onChange={handleChange}
                            className="appearance-none block w-full md:w-9/12 bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          />
                        </PersonalInfoFieldWrapper>

                        <PersonalInfoFieldWrapper
                          error={errors.zipcode}
                          required={true}
                          title="Zipcode"
                          element="zipcode"
                        >
                          <input
                            value={values.zipcode}
                            name="zipcode"
                            type="number"
                            onChange={handleChange}
                            className="appearance-none block w-full md:w-9/12 bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          />
                        </PersonalInfoFieldWrapper>

                        <PersonalInfoFieldWrapper
                          error={errors.phone}
                          title="Phone"
                          element="phone"
                          required
                        >
                          <input
                            value={values.phone}
                            name="phone"
                            type="text"
                            onChange={handleChange}
                            className="appearance-none block w-full md:w-9/12 bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          />
                        </PersonalInfoFieldWrapper>

                        <PersonalInfoFieldWrapper
                          error={errors.phone}
                          title="Telegram"
                          element="telegram"
                          required
                        >
                          <input
                            value={values.phone}
                            name="telegram"
                            type="text"
                            onChange={handleChange}
                            className="appearance-none block w-full md:w-9/12 bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          />
                        </PersonalInfoFieldWrapper>

                        <PersonalInfoFieldWrapper
                          error={errors.email}
                          required={true}
                          title="Email"
                          element="email"
                        >
                          <input
                            value={values.email}
                            name="email"
                            type="text"
                            onChange={handleChange}
                            className="appearance-none block w-full md:w-9/12 bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          />
                        </PersonalInfoFieldWrapper>

                        <PersonalInfoFieldWrapper
                          error={errors.id}
                          required={true}
                          title="Which type of Government ID Document will you use for verification?"
                          element="id"
                        >
                          <div>
                            <div className="flex items-center">
                              <input
                                type="radio"
                                id="driving"
                                name="verification"
                                value="HTML"
                              />
                              <label htmlFor="driving" className="ml-3 block">
                                Drivers License
                              </label>
                              <br />
                            </div>
                            <div className="flex items-center">
                              <input
                                type="radio"
                                id="State"
                                name="verification"
                                value="CSS"
                              />
                              <label htmlFor="State" className="ml-3 block">
                                State ID
                              </label>
                              <br />
                            </div>
                            <div className="flex items-center">
                              <input
                                type="radio"
                                id="Passport"
                                name="verification"
                                value="JavaScript"
                              />
                              <label htmlFor="Passport" className="ml-3 block">
                                Passport
                              </label>
                            </div>
                          </div>
                        </PersonalInfoFieldWrapper>

                        <PersonalInfoFieldWrapper
                          error={errors.selfie}
                          required={true}
                          title="Upload a selfie holding your ID"
                          element="selie"
                        >
                          <div className="flex flex-col">
                            <div className=" relative h-12 w-28 cursor-pointer ">
                              <label
                                htmlFor="file-input"
                                className="absolute left-0 right-0 text-lg h-12 px-5 leading-8 py-2 bg-white-dark text-center cursor-pointer rounded"
                              >
                                Browse..
                              </label>
                              <input
                                type="file"
                                id="file-input"
                                className="absolute h-12 px-5 left-0 right-0 opacity-0 cursor-pointer"
                              />
                            </div>
                            <label className="text-sm mt-2 text-gray-500">
                              Upload a selfie holding your ID.
                            </label>
                          </div>
                        </PersonalInfoFieldWrapper>

                        <PersonalInfoFieldWrapper
                          error={errors.identification}
                          required={true}
                          title="Upload State Issued Identification"
                          element="identification"
                        >
                          <div className="flex flex-col">
                            <div className=" relative h-12 w-28 cursor-pointer ">
                              <label
                                htmlFor="file-input"
                                className="absolute left-0 right-0 text-lg h-12 px-5 leading-8 py-2 bg-white-dark text-center cursor-pointer rounded"
                              >
                                Browse..
                              </label>
                              <input
                                type="file"
                                id="file-input"
                                className="absolute h-12 px-5 left-0 right-0 opacity-0 cursor-pointer"
                              />
                            </div>
                            <label className="text-sm mt-2 text-gray-500">
                              Upload photo of valid State Issued Identification.
                            </label>
                          </div>
                        </PersonalInfoFieldWrapper>

                        <PersonalInfoFieldWrapper
                          error={errors.verification}
                          required={true}
                          title="Patient Verification"
                          element="verification"
                        >
                          <div className="flex flex-col">
                            <div className=" relative h-12 w-28 cursor-pointer ">
                              <label
                                htmlFor="file-input"
                                className="absolute left-0 right-0 text-lg h-12 px-5 leading-8 py-2 bg-white-dark text-center cursor-pointer rounded"
                              >
                                Browse..
                              </label>
                              <input
                                type="file"
                                id="file-input"
                                className="absolute h-12 px-5 left-0 right-0 opacity-0 cursor-pointer"
                              />
                            </div>
                            <label className="text-sm mt-2 text-gray-500">
                              Upload photo of valid Patient Medical Card
                            </label>
                          </div>
                        </PersonalInfoFieldWrapper>
                        <hr />

                        <div className="flex items-center justify-end my-5 px-8">
                          <button
                            type="submit"
                            className="nav-bg-color text-white py-3 px-8 rounded  hover:shadow-lg p-2 mr-4 outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                          >
                            Save
                          </button>
                        </div>
                      </Form>
                    );
                  }
                }}
              </Formik>
            </div>
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  flow: state.auth.settingsFlow,
  loading: state.auth.loading,
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps)(PersonalInfoVerification));

