import { config } from 'constants/config';
import { HOME } from 'constants/routes';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import sidebarActions from '../../actions/sidebar-actions';

class Navbar extends Component {
  toggleSidebar = () => {
    const { dispatch } = this.props;
    dispatch(sidebarActions.toggleSidebar());
  };
  render() {
    return (
      <>
        <div className="fixed h-16 w-full pb-16 overflow-hidden shadow-md bg-white md:ml-0 z-10" >
          <nav style={{
            transition: 'width 1s ease'
          }} className="theme-top-nav absolute border-b border-slate-300 shadow top-0 left-0 w-full z-10 bg-white lg:flex-row lg:flex-nowrap lg:justify-start flex items-center px-4 lg:bg-transparent">
            <div className="w-full mx-aut0 items-center flex justify-start lg:flex-nowrap flex-wrap pb-3 mt-1">
              <button onClick={this.toggleSidebar} className="text-blueGray-800 lg:text-white text-sm uppercase inline-block font-semibold w-6 mr-4">
                <img
                  src={config.MENUICON}
                  alt="cancoms"
                  className="header-logo w-full h-full"
                />
              </button>
              <Link
                className="uppercase font-normal inline-block text-3xl header-logo text-red-600"
                to={HOME}
              >
                <img
                  src={config.SITE_LOGO}
                  alt="cancoms"
                  className="w-full h-full"
                />
                {/* Enschede.me */}
              </Link>
            </div>
          </nav>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  // auth: state.auth,
  // errors: state.errors,
  sidebar: state.sidebar,
});

export default connect(mapStateToProps)(Navbar);

