import axios from 'axios';
import env from './env';
import oryEnv from '../ory/env';

export const hydraAdminClient = axios.create({
  baseURL: env.HYDRA_ADMIN_API_URL,
  headers: {
    Authorization: 'Bearer ' + oryEnv.ORY_ACCESS_TOKEN,
  },
});

export const hydraPublicClient = axios.create({
  baseURL: env.HYDRA_PUBLIC_API_URL,
});

