import { IDENTITIES_INFORMATION } from 'constants/routes';
import showToast from '../components/ory/toast';
import { getHistory } from '../store';
import { getIdentityById } from 'api/ory/service';
import { getIdentities } from 'api/ory/service';
import { updateIdentityById } from 'api/ory/service';
import { deleteIdentityById } from 'api/ory/service';
import Fuse from 'fuse.js';

const prefix = 'ADMIN';
const actions = {
  GET_IDENTITY_LIST_STARTED: `${prefix}_GET_IDENTITY_LIST_STARTED`,
  GET_IDENTITY_LIST_SUCCESS: `${prefix}_GET_IDENTITY_LIST_SUCCESS`,
  GET_IDENTITY_LIST_ERROR: `${prefix}_GET_IDENTITY_LIST_ERROR`,
  UPDATE_IDENTITY_STARTED: `${prefix}_UPDATE_IDENTITY_STARTED`,
  UPDATE_IDENTITY_SUCCESS: `${prefix}_UPDATE_IDENTITY_SUCCESS`,
  UPDATE_IDENTITY_ERROR: `${prefix}_UPDATE_IDENTITY_ERROR`,
  GET_IDENTITY_BY_ID_STARTED: `${prefix}_GET_IDENTITY_BY_ID_STARTED`,
  GET_IDENTITY_BY_ID_SUCCESS: `${prefix}_GET_IDENTITY_BY_ID_SUCCESS`,
  GET_IDENTITY_BY_ID_ERROR: `${prefix}_GET_IDENTITY_BY_ID_ERROR`,
  DELETE_IDENTITY_BY_ID_STARTED: `${prefix}_DELETE_IDENTITY_BY_ID_STARTED`,
  DELETE_IDENTITY_BY_ID_SUCCESS: `${prefix}_DELETE_IDENTITY_BY_ID_SUCCESS`,
  DELETE_IDENTITY_BY_ID_ERROR: `${prefix}_DELETE_IDENTITY_BY_ID_ERROR`,
  FILTER_IDENTITY_LIST_STARTED: `${prefix}_FILTER_IDENTITY_LIST_STARTED`,
  FILTER_IDENTITY_LIST_SUCCESS: `${prefix}_FILTER_IDENTITY_LIST_SUCCESS`,
  FILTER_IDENTITY_LIST_ERROR: `${prefix}_FILTER_IDENTITY_LIST_ERROR`,
  SORT_IDENTITY_LIST_STARTED: `${prefix}_SORT_IDENTITY_LIST_STARTED`,
  SORT_IDENTITY_LIST_SUCCESS: `${prefix}_SORT_IDENTITY_LIST_SUCCESS`,
  SORT_IDENTITY_LIST_ERROR: `${prefix}_SORT_IDENTITY_LIST_ERROR`,

  getIdentitiesList: (pagination = {}) => async (dispatch) => {
    try {
      dispatch({
        type: actions.GET_IDENTITY_LIST_STARTED,
      });
      let data = await getIdentities(pagination);

      const allData = await getIdentities({
        page: 1,
        per_page: Number.MAX_VALUE,
      });
      data.count = allData.data.length;

      dispatch({
        type: actions.GET_IDENTITY_LIST_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: actions.GET_IDENTITY_LIST_ERROR,
      });
      console.error(error);
    }
  },

  filterIdentity: (word) => async (dispatch) => {
    try {
      dispatch({
        type: actions.FILTER_IDENTITY_LIST_STARTED,
      });
      const data = await getIdentities({});

      // console.log(' identities in filter', data.data[0]);
      let temp = [];
      if (word.length <= 2) {
        dispatch({
          type: actions.FILTER_IDENTITY_LIST_SUCCESS,
          payload: { data: temp },
        });
      }
      const searcher = new Fuse(data.data, {
        minMatchCharLength: 3,
        isCaseSensitive: true,
        includeScore: true,
        useExtendedSearch: true,
        keys: ['traits.email', 'traits.username'],
      });
      const result = searcher.search(`'${word}`);

      result.forEach((data) => {
        temp.push(data.item);
      });
      setTimeout(() => {
        dispatch({
          type: actions.FILTER_IDENTITY_LIST_SUCCESS,
          payload: { data: temp },
        });
      }, 1000);
    } catch (error) {
      dispatch({
        type: actions.FILTER_IDENTITY_LIST_ERROR,
      });
      console.error(error);
    }
  },

  sortIdentity: (sortBy) => async (dispatch) => {
    try {
      dispatch({
        type: actions.SORT_IDENTITY_LIST_STARTED,
      });
      const data = await getIdentities({});

      // console.log(' identities in SORT', data.data[0].traits.email[0]);
      let temp = data.data;
      temp.sort((a, b) => {
        let emailA = a.traits?.email[0]?.toLowerCase();
        let emailB = b.traits?.email[0]?.toLowerCase();
        if (sortBy === 'asc') {
          if (emailA < emailB) {
            return -1;
          } else if (emailA > emailB) {
            return 1;
          } else {
            return 0;
          }
        }
        if (sortBy === 'desc') {
          if (emailA > emailB) {
            return -1;
          } else if (emailA < emailB) {
            return 1;
          } else {
            return 0;
          }
        }
        if (sortBy === 'time') {
          let dateA = new Date(a.created_at),
            dateB = new Date(b.created_at);
          return dateB - dateA;
        }
      });

      dispatch({
        type: actions.SORT_IDENTITY_LIST_SUCCESS,
        payload: { data: temp },
      });
    } catch (error) {
      dispatch({
        type: actions.SORT_IDENTITY_LIST_ERROR,
      });
      console.error(error);
    }
  },

  updateIdentity: (id, data, options = {}) => async (dispatch) => {
    try {
      dispatch({
        type: actions.UPDATE_IDENTITY_STARTED,
      });
      const response = await updateIdentityById(id, data);
      if (response.status === 200) {
        if (options.showToast !== false) {
          showToast(null, {
            type: 'success',
            text:
              options?.message?.onSuccess ||
              'Your changes were saved successfully!',
          });
        }

        dispatch({
          type: actions.UPDATE_IDENTITY_SUCCESS,
          payload: response?.data,
        });

        if (options?.redirectTo) {
          const splitURL = options.redirectTo?.split('?');
          if (splitURL.length > 1) {
            getHistory().push({ pathname: splitURL[0], search: splitURL[1] });
          } else {
            getHistory().push({ pathname: options.redirectTo });
          }
        } else {
          getHistory().push(`${IDENTITIES_INFORMATION}/${response?.data?.id}`);
        }
      }
    } catch (error) {
      dispatch({
        type: actions.UPDATE_IDENTITY_ERROR,
      });
    }
  },

  getIdentityById: (id) => async (dispatch) => {
    try {
      dispatch({
        type: actions.GET_IDENTITY_BY_ID_STARTED,
      });
      const data = await getIdentityById(id);
      dispatch({
        type: actions.GET_IDENTITY_BY_ID_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: actions.GET_IDENTITY_BY_ID_ERROR,
      });
      console.error(error);
    }
  },

  deleteIdentity: (id, options = {}) => async (dispatch) => {
    try {
      dispatch({
        type: actions.DELETE_IDENTITY_BY_ID_STARTED,
      });

      const response = await deleteIdentityById(id, options);
      if (response.status === 204) {
        if (options.showToast !== false) {
          showToast(null, {
            type: 'success',
            text: options?.message?.onSuccess || 'Identity Deleted!',
          });
        }

        dispatch({
          type: actions.DELETE_IDENTITY_BY_ID_SUCCESS,
          payload: { id },
        });

        if (options?.redirectTo) {
          getHistory().push({ pathname: options.redirectTo });
        }
        //  else {
        //   getHistory().push(IDENTITIES_LIST);
        // }
      }
    } catch (error) {
      dispatch({
        type: actions.DELETE_IDENTITY_BY_ID_ERROR,
      });
    }
  },
};

export default actions;
