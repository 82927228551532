import { config } from 'constants/config';
import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { withRouter } from 'react-router';
import Spinner from 'components/utils/spinner';
import actions from '../../../actions/hydra-actions';
class ConfirmationPopup extends React.Component {
  deleteClient = async () => {
    const { dispatch, clientId, closePopup } = this.props;
    await dispatch(actions.deleteClient(clientId));
    closePopup();
  };

  render() {
    const { open, closePopup, loading, clientLogo } = this.props;
    if (loading) {
      closePopup();
      return <Spinner loading={true} />;
    }
    return (
      <Modal open={open} onClose={closePopup} center>
        <div className="relative  w-full h-full">
          <div className="flex w-fit mx-auto align-middle h-full m-auto justify-center items-center  p-10  border-gray-200 rounded mt-5">
            <div className="flex flex-col justify-center items-center">
              <div className="w-20 h-20 object-cover text -center">
                <img className="" src={clientLogo || config.PROFILE} alt="" />
              </div>
              <p className="text-lg my-3">Delete Client</p>
              <span className="mb-3 text-gray-500">
                Are you sre you want to delete this Client?
              </span>
              <div className="flex justify-center items-center">
                <button
                  onClick={closePopup}
                  type="button"
                  className="shadow text-black theme-border rounded  mr-5 p-3 px-10 outline-none border focus:outline-none mb-1 ease-linear transition-all duration-150"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="shadow btn-primary text-white theme-border rounded  mr-5 p-3 px-10 outline-none border focus:outline-none mb-1 ease-linear transition-all duration-150"
                  onClick={this.deleteClient}
                  disabled={loading}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.hydra.loading,
  user: state.auth.user,
});

export default withRouter(connect(mapStateToProps)(ConfirmationPopup));

