/* eslint-disable react/prop-types */
import { Component } from 'react';
import { connect } from 'react-redux';

class AccountRecoverStep4 extends Component {

  // eslint-disable-next-line constructor-super
  constructor() {
    super()
    this.state = { isSuccess: false }
  }

  componentDidMount() {
    console.log('sucsedd', this.props);
  }


  nextHandleClick(e) {
    e.preventDefault();
    this.setState({ isSuccess: !this.state.isSuccess });
    console.log(this.state.isSuccess);
  }

  render() {
    return (
      <div className='min-h-screen py-6 flex flex-col justify-center relative overflow-hidden sm:py-12'>
        {!this.props.isSuccess ? (
          <div className='flex items-center'>
            <div className="md:mx-auto flex p-8 w-450" style={{ boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)' }}>
              <section className="relative pt-25">
                <div className='grid'>
                  <div className="container mx-auto px-6 h-full">
                    <div className="flex content-center items-center justify-center h-full">
                      <div className="relative flex flex-col mb-6 rounded-lg bg-white">
                        <div className="mb-0 md:m-5">
                          <div className="text-center">
                            <h3 className="text-3xl p-8 font-bold">Success !</h3>
                          </div>
                          <div className="text-left mb-6 w-1/2">
                            <div className='mb-4'><span>Success you are signed in now.</span></div>
                            <div>You entered your current password, so you can continue to your
                              Account. You can still change your password if you want.</div>
                          </div>

                          <div>
                            <button className="btn-primary mt-6 uppercase text-white font-bold py-2 px-4 rounded w-full">
                              CONTINUE
                            </button>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section >
            </div >
          </div >
        ) : <div className='flex items-center w-full'>
          <div className="md:mx-auto flex p-8" style={{ boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)' }}>
            <section className="relative w-full pt-25">
              <div className='grid'>
                <div className="container mx-auto px-6 h-full">
                  <div className="flex content-center items-center justify-center h-full">
                    <div className="relative flex flex-col w-full mb-6 rounded-lg bg-white">
                      <div className="mb-0 md:m-5 px-5 px-6">
                        <div className="text-center">
                          <h3 className="text-3xl p-2 font-bold">Fail</h3>
                        </div>
                        <div className="text-center font-20">
                          <input className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-full  py-2 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                            type="password" placeholder="Your Answer" />
                        </div>
                      </div>
                      <div className="flex-auto px-4 py-9 pt-0">
                        <form>

                          <div className="flex flex-wrap -mx-3">
                            <div className="w-full">
                              <label className="block tracking-wide text-gray-700 text-xs font-bold mb-2" htmlFor="grid-password">
                                {'To continue first verify it\'s you'}
                              </label>
                              <input className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                type="password" placeholder="Enter your password" />
                            </div>
                          </div>

                          <div className='mt-3 mb-3'>
                            <input className='mr-2' type="checkbox" id="vehicle1" name="vehicle1" value="Bike" />
                            <span className='text-gray font-14'> Show password</span>
                          </div>

                          <div className="container mx-auto mt-3 ">
                            <div className="flex flex-wrap">
                              <div className="w-full flex-1 block m-auto">
                                <span className='text-primary col'>Try another way</span>
                              </div>
                              <div className="">
                                <button className="btn-primary col uppercase text-white font-bold py-2 px-4 rounded" onClick={(e) => this.nextHandleClick(e)}>
                                  Next
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section >
          </div >
        </div >}

      </div >
    )
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,

)(AccountRecoverStep4);