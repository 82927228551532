import { Component } from 'react';
import { createPortal } from 'react-dom';
import { ScaleLoader } from 'react-spinners';
import styled, { css } from 'styled-components';

export default class Spinner extends Component {
  render() {
    const DarkBackground = styled.div`
      display: none; /* Hidden by default */
      position: fixed; /* Stay in place */
      z-index: 999; /* Sit on top */
      left: 0;
      top: 0;
      width: 100%; /* Full width */
      height: 100%; /* Full height */
      overflow: auto; /* Enable scroll if needed */
      background-color: rgb(0, 0, 0); /* Fallback color */
      background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

      ${(props) =>
        props.disappear &&
        css`
          display: block; /* show */
        `}
    `;
    const { loading = false, color = '#2E4C6D', size = 20 } = this.props;
    return createPortal(
      <DarkBackground disappear={loading}>
        <div
          style={{
            bottom: '0',
            height: '100px',
            left: '50%',
            transform: 'translateX(-50%)',
            margin: 'auto',
            position: 'fixed',
            top: '0',
            width: '100px',
            zIndex: 1,
            color: color,
            textAlign: 'center',
          }}
        >
          <ScaleLoader loading={loading} color={color} size={size} />
        </div>
      </DarkBackground>,
      document.getElementById('spinner')
    );
  }
}

