
import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import actions from '../../actions/auth-actions';
import Spinner from '../utils/spinner';
class Logout extends Component {
  componentDidMount = async () => {
    const { dispatch } = this.props;
    try {
      dispatch(actions.logout());
    } catch (error) {
      console.log(error);
    }

  }

  render() {
    const { loading } = this.props;
    return (
      <Spinner loading={loading} />
    );
  }
}

const mapStateToProps = state => ({
  loading: state.auth.loading,
});

export default withRouter(connect(mapStateToProps)(Logout));