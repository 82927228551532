import { CLIENT_LIST } from 'constants/routes';
import { getHistory } from 'store';
import {
  createClient,
  deleteClient,
  editClient,
  getClients,
} from '../api/hydra/service';
import Fuse from 'fuse.js';
import env from '../constants/env';

const actions = {
  GET_CLIENT_SITES_STARTED: 'GET_CLIENT_SITES_STARTED',
  GET_CLIENT_SITES_SUCCESS: 'GET_CLIENT_SITES_SUCCESS',
  GET_CLIENT_SITES_ERROR: 'GET_CLIENT_SITES_ERROR',
  GET_CLIENT_SITE_BY_ID_STARTED: 'GET_CLIENT_SITE_BY_ID_STARTED',
  GET_CLIENT_SITE_BY_ID_SUCCESS: 'GET_CLIENT_SITE_BY_ID_SUCCESS',
  GET_CLIENT_SITE_BY_ID_ERROR: 'GET_CLIENT_SITE_BY_ID_ERROR',
  CREATE_CLIENT_STARTED: 'CREATE_CLIENT_STARTED',
  CREATE_CLIENT_SUCCESS: 'CREATE_CLIENT_SUCCESS',
  CREATE_CLIENT_ERROR: 'CREATE_CLIENT_ERROR',
  DELETE_CLIENT_STARTED: 'DELETE_CLIENT_STARTED',
  DELETE_CLIENT_SUCCESS: 'DELETE_CLIENT_SUCCESS',
  DELETE_CLIENT_ERROR: 'DELETE_CLIENT_ERROR',
  EDIT_CLIENT_STARTED: 'EDIT_CLIENT_STARTED',
  EDIT_CLIENT_SUCCESS: 'EDIT_CLIENT_SUCCESS',
  EDIT_CLIENT_ERROR: 'EDIT_CLIENT_ERROR',
  FILTER_CLIENT_SITES_STARTED: 'FILTER_CLIENT_SITES_STARTED',
  FILTER_CLIENT_SITES_SUCCESS: 'FILTER_CLIENT_SITES_SUCCESS',
  FILTER_CLIENT_SITES_ERROR: 'FILTER_CLIENT_SITES_ERROR',
  SORT_CLIENT_SITES_STARTED: 'SORT_CLIENT_SITES_STARTED',
  SORT_CLIENT_SITES_SUCCESS: 'SORT_CLIENT_SITES_SUCCESS',
  SORT_CLIENT_SITES_ERROR: 'SORT_CLIENT_SITES_ERROR',

  getClients: (pagination = {}) => async (dispatch) => {
    try {
      dispatch({
        type: actions.GET_CLIENT_SITES_STARTED,
      });
      if (env.ENV !== 'dev') {
        // pagination.page_size = 10;
        delete pagination.limit;
        delete pagination.offset;
      const response = await getClients(undefined, {});
        const allClients = await getClients(undefined, {});
        response.count = allClients.data.length;
        dispatch({
          type: actions.GET_CLIENT_SITES_SUCCESS,
          payload: response,
        });
        return;
      }
      const response = await getClients(undefined, pagination);
      const allClients = await getClients(undefined, {
        limit: Number.MAX_VALUE,
        offset: pagination?.offset,
      });
      response.count = allClients?.data?.length;
      dispatch({
        type: actions.GET_CLIENT_SITES_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: actions.GET_CLIENT_SITES_ERROR,
      });
    }
  },
  filterClients: (word) => async (dispatch) => {
    try {
      dispatch({
        type: actions.FILTER_CLIENT_SITES_STARTED,
      });
      const clients = await getClients(undefined, {});

      // console.log(' identities in filter', clients.data[0].client_name);
      let temp = [];
      if (word.length <= 2) {
        dispatch({
          type: actions.FILTER_CLIENT_SITES_SUCCESS,
          payload: { data: temp },
        });
      }
      const searcher = new Fuse(clients.data, {
        minMatchCharLength: 3,
        isCaseSensitive: true,
        includeScore: true,
        useExtendedSearch: true,
        keys: ['client_uri', 'client_name'],
      });
      const result = searcher.search(`'${word}`);

      result.forEach((data) => {
        temp.push(data.item);
      });

      dispatch({
        type: actions.FILTER_CLIENT_SITES_SUCCESS,
        payload: { data: temp },
      });
    } catch (error) {
      dispatch({
        type: actions.FILTER_CLIENT_SITES_ERROR,
      });
      console.error(error);
    }
  },

  sortClients: (sortBy) => async (dispatch) => {
    try {
      dispatch({
        type: actions.SORT_CLIENT_SITES_STARTED,
      });
      const client = await getClients(undefined, {});

      // console.log(' identities in SORT', data.data[0].traits.email[0]);
      let temp = client.data;
      temp.sort((a, b) => {
        let userNameA = a?.client_name?.toLowerCase();
        let usernameB = b?.client_name?.toLowerCase();
        if (sortBy === 'asc') {
          if (userNameA < usernameB) {
            return -1;
          } else if (userNameA > usernameB) {
            return 1;
          } else {
            return 0;
          }
        }
        if (sortBy === 'desc') {
          if (userNameA > usernameB) {
            return -1;
          } else if (userNameA < usernameB) {
            return 1;
          } else {
            return 0;
          }
        }
        if (sortBy === 'time') {
          let dateA = new Date(a.created_at),
            dateB = new Date(b.created_at);
          return dateB - dateA;
        }
      });

      dispatch({
        type: actions.SORT_CLIENT_SITES_SUCCESS,
        payload: { data: temp },
      });
    } catch (error) {
      dispatch({
        type: actions.SORT_CLIENT_SITES_ERROR,
      });
      console.error(error);
    }
  },

  createClient: (payload, redirectTo = undefined) => async (dispatch) => {
    try {
      dispatch({
        type: actions.CREATE_CLIENT_STARTED,
      });
      const response = await createClient(payload);
      dispatch({
        type: actions.CREATE_CLIENT_SUCCESS,
        payload: response,
      });
      if (redirectTo) {
        getHistory().push(redirectTo);
      }
    } catch (error) {
      dispatch({
        type: actions.CREATE_CLIENT_ERROR,
      });
    }
  },
  deleteClient: (clientId, redirectTo = undefined) => async (dispatch) => {
    try {
      dispatch({
        type: actions.DELETE_CLIENT_STARTED,
      });
      await deleteClient(clientId);
      dispatch({
        type: actions.DELETE_CLIENT_SUCCESS,
        payload: { clientId },
      });
      if (redirectTo) {
        getHistory().push(redirectTo);
      }
    } catch (error) {
      dispatch({
        type: actions.DELETE_CLIENT_ERROR,
      });
    }
  },
  getClientById: (id, redirectOnFailure) => async (dispatch) => {
    try {
      dispatch({
        type: actions.GET_CLIENT_SITE_BY_ID_STARTED,
      });
      const response = await getClients(id);
      dispatch({
        type: actions.GET_CLIENT_SITE_BY_ID_SUCCESS,
        payload: response,
      });
    } catch (error) {
      dispatch({
        type: actions.GET_CLIENT_SITE_BY_ID_ERROR,
      });
      if (redirectOnFailure) {
        getHistory().push(redirectOnFailure);
      }
      getHistory().push(CLIENT_LIST);
    }
  },
  editClient: (clientId, payload, redirectTo = undefined) => async (
    dispatch
  ) => {
    try {
      dispatch({
        type: actions.EDIT_CLIENT_STARTED,
      });
      const response = await editClient(clientId, payload);
      dispatch({
        type: actions.EDIT_CLIENT_SUCCESS,
        payload: response,
      });
      if (redirectTo) {
        getHistory().push(redirectTo);
      }
    } catch (error) {
      dispatch({
        type: actions.EDIT_CLIENT_ERROR,
      });
      if (redirectTo) {
        getHistory().push(redirectTo);
      }
    }
  },
};

export default actions;
