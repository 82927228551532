import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { IDENTITIES_EDIT } from '../../../constants/routes';
import Information from '../../shared/information';
import Spinner from '../../utils/spinner';
import actions from '../../../actions/ory-admin-actions';
import { withTranslation } from 'react-i18next';

class IdentitiesInformation extends Component {
  state = {
    id: this.props.match.params?.id || null,
  };
  componentDidMount = () => {
    const { identity, dispatch } = this.props;
    if (!identity || identity.id !== this.state.id) {
      dispatch(actions.getIdentityById(this.state.id));
    }
  };
  render() {
    const { loading, oryAdminLoading, t } = this.props;
    const { identity } = this.props;
    const fields = [
      'display_name',
      'username',
      'address',
      'phone',
      'telegram',
      'email',
    ];

    const traitsData = identity?.traits || {
      first_name: 'Johnny',
      last_name: 'Sebastian',
      email: 'johhnysebastian@gmail.com',
      picture: null,
    };

    if (loading || oryAdminLoading) {
      return <Spinner loading={true} />;
    }
    if (identity) {
      return (
        <div className="relative  p-5 md:p-8 lg:p-10 w-full md:w-4/6 lg:w-4/5 xl:w-5/6">
          <Information
            title={t('personal_info.title')}
            traitsData={traitsData}
            fields={fields}
            verifyPath={{ pathname: `${IDENTITIES_EDIT}/${this.state.id}` }}
          />
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  user: state.auth.user,
  identity: state.oryAdmin.identity,
  oryAdminLoading: state.oryAdmin.loading,
});

export default withTranslation()(withRouter(connect(mapStateToProps)(IdentitiesInformation)));

