import { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions/auth-actions';
import Spinner from '../utils/spinner';
import schema, { fieldList, mappedIdentity } from '../../validation/reset-password';
import { getNodesFromFlow } from '../../utils';
import { Form, Formik } from 'formik';
import FieldWrapper from '../../components/form/field-wrapper';
import { FLOW_TYPES } from '../../constants/flows';
import SelectLanguage from 'components/shared/select-language';
import { withTranslation } from 'react-i18next';


class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.passwordRef = [];
  }


  async init(params) {
    const flowId = params.get('flow');
    const returnTo = params.get('return_to');
    try {
      const { dispatch } = this.props;
      dispatch(actions.getSettingsFlow(flowId, returnTo));
    } catch (error) {
      console.log(error);
    }
  }

  initialValues = () => {

    if (this.props.flow) {
      const groups = ['default', 'password'];
      const flow = JSON.parse(JSON.stringify(this.props.flow));
      const filteredNodes = flow?.ui.nodes?.filter(node => groups.includes(node?.group));
      flow.ui.nodes = filteredNodes;
      const nodes = getNodesFromFlow(
        this.props.flow,
        fieldList,
        mappedIdentity
      );
      nodes['method'] = schema.fields['method']?.spec?.default;
      return nodes;

    }
  }


  componentDidMount() {
    const params = new URLSearchParams(this.props.location.search);
    this.init(params);
  }
  handleSubmit = (formData) => {
    const { dispatch, flow } = this.props;
    delete formData.confirm_password;
    dispatch(actions.submitFlow(flow, FLOW_TYPES.CHANGE_PASSWORD, formData));

  }

  componentWillUnmount() {
    if (this.props.user?.identity?.traits?.language?.code) {
      const lang = this.props.user?.identity?.traits?.language?.code;
      this.props.i18n.changeLanguage(lang);
    }
  }
  toggleShowPassword = (refKey) => {
    const element = this.passwordRef[refKey];
    const types = ['password', 'text'];
    if (element) {
      const index = +!types.findIndex(e => e === element.type);
      this.passwordRef[refKey].type = types[index]
    }
  }
  render() {
    this.initialValues();
    const { loading, t } = this.props;
    if (loading) {
      return <Spinner loading={loading} />;
    } else {
      return (

        <div className="min-h-screen py-6 flex flex-col justify-center relative overflow-hidden sm:py-12">
          <div className="flex items-center w-full">
            <div
              className="md:mx-auto flex p-6"
              style={{ boxShadow: '0px 0px 6px rgba(0, 0, 0, 0.25)' }}
            >
              <section className="relative w-full pt-25">
                <div className="grid">
                  <div className="container mx-auto h-full">
                    <div className="flex content-center items-center justify-center h-full">
                      <div className="relative flex flex-col w-full mb-6 rounded-lg bg-white">
                        <div className="mb-0 px-5 pt-5">
                          <div className="text-center">
                            <h3 className="text-3xl p-2 font-bold">
                              {t('reset_pass.title')}
                            </h3>
                          </div>
                        </div>
                        <div className="text-center py-4 px-3">
                          {t('reset_pass.description')}
                        </div>
                        <div className="flex-auto px-4 pt-2">
                          <Formik
                            initialValues={this.initialValues()}
                            onSubmit={this.handleSubmit}
                            validationSchema={schema}
                            validateOnBlur={false}
                            validateOnChange={false}
                          >
                            {({
                              errors,
                              values,
                              handleChange,
                            }) => {
                              if (values) {
                                return (
                                  <Form>
                                    <FieldWrapper
                                      error={errors.password}
                                      required={true}
                                      title={t('reset_pass.fields.password')}
                                      element="password"
                                    >
                                      <div
                                        className="appearance-none block w-full relative bg-white text-gray-700  rounded-lg  mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                      >
                                        <input
                                          value={values['password']}
                                          name="password"
                                          placeholder="**********"
                                          type="password"
                                          onChange={handleChange}
                                          ref={e => this.passwordRef[0] = e}
                                          className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        />
                                        <i className='fa fa-eye rounded-lg appearance-none absolute -translate-x-1/2 -translate-y-1/2 top-1/2 right-1  text-gray-600'
                                          onClick={() => this.toggleShowPassword(0)}
                                        />
                                      </div>
                                    </FieldWrapper>
                                    <FieldWrapper
                                      error={errors.confirm_password}
                                      required={true}
                                      title={t('reset_pass.fields.confirm_password')}
                                      element="password"
                                    >
                                      <div
                                        className="appearance-none block w-full relative bg-white text-gray-700 rounded-lg  mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                      >
                                        <input
                                          value={values['confirm_password']}
                                          name="confirm_password"
                                          placeholder="**********"
                                          ref={e => this.passwordRef[1] = e}
                                          type="password"
                                          onChange={handleChange}
                                          className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                                        />
                                        <i className='fa fa-eye rounded-lg appearance-none absolute -translate-x-1/2 -translate-y-1/2 top-1/2 right-1  text-gray-600'
                                          onClick={() => this.toggleShowPassword(1)}
                                        />
                                      </div>
                                    </FieldWrapper>
                                    <FieldWrapper>
                                      <button
                                        type="submit"
                                        className="btn-primary shadow text-white py-2 px-4 rounded w-full hover:shadow-lg p-2 px-8 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
                                      >
                                        Submit
                                      </button>
                                    </FieldWrapper>
                                  </Form>
                                );
                              }
                            }}
                          </Formik>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
          <div className='md:mx-auto flex'>
            <SelectLanguage />
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  flow: state.auth.settingsFlow,
  loading: state.auth.loading,
  user: state.auth.user,
});


export default withTranslation()(connect(mapStateToProps)(ResetPassword));

