import * as yup from 'yup';


const schema = yup.object().shape({
    language: yup.object({
        code: yup.string().default('en'),
        value: yup.string().default('English (United States)'),
    }).default({
        code: 'en',
        value: 'English (United States)'
    }),
    isTncAgreed: yup.boolean().default(false).test('required', 'Please agree the Terms and conditions', value =>  value),
    isPrivacyPolicyAgreed: yup.boolean().default(false).test('required', 'Please agree the Privacy Policy', value => value)
});

const fields = () => {
    const field = {};
    Object.keys(schema.fields).forEach(
        (fieldName) => (field[fieldName] = fieldName)
    );
    return field;
};



export const fieldList = Object.keys(schema.fields);
export { fields };
export default schema;
