import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const { Component } = require('react');

class Information extends Component {
  constructAddress = (addressObj) => {
    const address = [];
    const keys = ['area', 'neighbourhood'];
    keys.forEach((key) => {
      if (addressObj && addressObj[key]) {
        address.push(addressObj[key]);
      }
    });
    return address.join(', ');
  };

  render() {
    const { title, traitsData, fields, verifyPath, t } = this.props;
    const {
      username,
      email,
      birth_date,
      phone,
      address,
      telegram,
      display_name,
    } = traitsData;
    return (
      <div
        className="w-full p-8 border border-gray-200 rounded"
        style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)' }}
      >
        <div>
          <span className="text-2xl font-bold">{title}</span>
        </div>
        <div className="text-center font-20"></div>
        {/* <hr className='my-8' /> */}
        {/* {flow && <Flow flow={flow} type={FLOW_TYPES.SETTINGS} validationSchema={this.validationSchema} />} */}
        <div className="mt-8">
          {fields.includes('display_name') ? (
            <div className="w-full mt-8">
              <div className="flex">
                <div className="w-3/12">
                  <p className="block mb-4">{t('personal_info.basic_info.fields.display_name')}</p>
                  <hr />
                </div>
                <div className="w-9/12">
                  <p className="block mb-4">{display_name || '-'} </p>
                  <hr />
                  <p className="text-sm mt-1 text-gray-500">
                    {t('personal_info.basic_info.fields.description.display_name')}
                  </p>
                </div>
              </div>
            </div>
          ) : null}
          {fields.includes('username') ? (
            <div className="w-full mt-8">
              <div className="flex">
                <div className="w-3/12">
                  <p className="block mb-4">{t('personal_info.basic_info.fields.username')}</p>
                  <hr />
                </div>
                <div className="w-9/12">
                  <p className="block mb-4">{username || '-'} </p>
                  <hr />
                  <p className="text-sm mt-1 text-gray-500">
                    Use display name to manage your privacy, to replace your
                    real name on your account profile. Can be updated at any
                    time.
                  </p>
                </div>
              </div>
            </div>
          ) : null}
          {fields.includes('dateOfBirth') ? (
            <div className="w-full mt-8">
              <div className="flex">
                <div className="w-3/12">
                  <p className="block mb-4">Date of Birth</p>
                  <hr />
                </div>
                <div className="w-9/12">
                  <p className="block mb-4">{birth_date ? birth_date : '-'}</p>
                  <hr />
                  <p className="text-sm mt-1 text-gray-500">
                    Add Date of Birth
                  </p>
                </div>
              </div>
            </div>
          ) : null}
          {fields.includes('address') ? (
            <div className="w-full mt-8">
              <div className="flex">
                <div className="w-3/12">
                  <p className="block mb-4">{t('personal_info.basic_info.fields.address')}</p>

                  <hr />
                </div>
                <div className="w-9/12">
                  <p className="block mb-4">
                    {this.constructAddress(address) || '-'}
                  </p>
                  <hr />
                  <p className="text-sm mt-1 text-gray-500">Add address</p>
                </div>
              </div>
            </div>
          ) : null}
          {fields.includes('phone') ? (
            <div className="w-full mt-8">
              <div className="flex">
                <div className="w-3/12">
                  <p className="block mb-4">{t('personal_info.contact_info.fields.phone')}</p>

                  <hr />
                </div>
                <div className="w-9/12">
                  <div className="flex justify-between items-center">
                    <p className="block mb-4">{phone?.number || '-'}</p>
                    <div className="flex mb-4">
                      <button className="mr-2 lg:mr-8 underline text-primary">
                        {t('common.buttons.edit')}
                      </button>
                      <button className="mr-2 lg:mr-8 underline text-primary">
                      {t('common.buttons.verify')}
                      </button>
                    </div>
                  </div>
                  <hr />
                  <p className="text-sm mt-1 text-gray-500">Add phone number</p>
                </div>
              </div>
            </div>
          ) : null}
          {fields.includes('telegram') ? (
            <div className="w-full mt-8">
              <div className="flex">
                <div className="w-3/12">
                  <p className="block mb-4">{t('personal_info.contact_info.fields.telegram')}</p>
                  <hr />
                </div>
                <div className="w-9/12">
                  <div className="flex justify-between items-center">
                    <p className="block mb-4">{telegram?.number || '-'}</p>
                    <div className="flex mb-4">
                      <button className="mr-2 lg:mr-8 underline text-primary">
                        {t('common.buttons.edit')}
                      </button>
                      <button className="mr-2 lg:mr-8 underline text-primary">
                      {t('common.buttons.verify')}
                      </button>
                    </div>
                  </div>
                  <hr />
                  <p className="text-sm mt-1 text-gray-500">Add phone number</p>
                </div>
              </div>
            </div>
          ) : null}
          {fields.includes('email') ? (
            <div className="w-full mt-8">
              <div className="flex">
                <div className="w-3/12">
                  <p className="block mb-4">{t('personal_info.contact_info.fields.email')}</p>

                  <hr />
                </div>
                <div className="w-9/12">
                  <div className="flex justify-between">
                    <p className="block mb-4">{email ? email[0] : '-'}</p>
                    <div className="flex mb-4">
                      <button className="mr-2 lg:mr-8 underline text-primary">
                        {t('common.buttons.edit')}
                      </button>
                      <button className="mr-2 lg:mr-8 underline text-primary">
                      {t('common.buttons.verify')}
                      </button>
                    </div>
                  </div>
                  <hr />
                  <p className="text-sm mt-1 text-gray-500">Add email.</p>
                </div>
              </div>
            </div>
          ) : null}
          <div className="flex justify-end mt-5 mb-3">
            <div className="align-center">
              <Link
                to={verifyPath}
                type="submit"
                className=" btn-primary shadow text-white py-3 px-16 rounded hover:shadow-lg p-2 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
              >
                {t('common.buttons.edit')}

              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default withTranslation()(Information);

