import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import auth from './reducers/auth-reducers';
import bba from './reducers/bba-reducers';
import hydra from './reducers/hydra-reducers';
import keto from './reducers/keto-reducers';
import oryAdmin from './reducers/ory-admin-reducers';
import sidebar from 'reducers/sidebar-reducers';
import twilio from 'reducers/twilio-reducers';

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    auth,
    bba,
    oryAdmin,
    hydra,
    keto,
    sidebar,
    twilio,
  });

