import * as yup from 'yup';
const schema = yup.object().shape({
  client_secret: yup.string(),
  client_name: yup.string().required('Client name is required'),
  client_uri: yup.string().required('Client URI is required'),
  grant_types: yup
    .array()
    .default(['authorization_code', 'refresh_token', 'client_credentials']),
  logo_uri: yup.string(),
  policy_uri: yup.string(),
  tos_uri: yup.string(),
  post_logout_redirect_uris: yup
    .array()
    .required('Post Logout redirect URI is required')
    .default([''])
    .test(
      'required',
      'Post Logout Redirect URI is required',
      (value) => !!value[0]
    ),
  redirect_uris: yup
    .array()
    .required('Redirect URI is required')
    .default([''])
    .test('required', 'Redirect URI is required', (value) => !!value[0]),
  scope: yup.string().default('openid offline email'),
  token_endpoint_auth_method: yup.string().default('client_secret_post'),
});

export const fieldList = Object.keys(schema.fields);

export const fields = () => {
  const field = {};
  fieldList.forEach((fieldName) => (field[fieldName] = fieldName));
  return field;
};

export const extractDefaults = (clientObj = {}) => {
  const values = {};
  fieldList.forEach((fieldName) => {
    const field = schema.fields[fieldName];
    const value = clientObj[fieldName];
    values[fieldName] = value || field.spec?.default || '';
  });
  values['client_secret'] = crypto.randomUUID();
  return values;
};

export default schema;

