import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-locize-backend';
import LastUsed from 'locize-lastused';
import { locizePlugin } from 'locize';
import env from './constants/env';
const locizeOptions = {
  projectId: env.LOCIZ_PROJECT_ID,
  apiKey: env.LOCIZ_API_KEY,
  referenceLng: 'en',
};

i18n
  .use(Backend)
  .use(LastUsed)
  .use(locizePlugin)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: false,
    saveMissing: true,
    keySeparator: '.',
    backend: locizeOptions,
    locizeLastUsed: locizeOptions,
    react: {
      bindI18n: 'languageChanged editorSaved',
    },
  });

export default i18n;