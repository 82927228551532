// export default ['Enschede', 'Alderson', 'Cameron'];

export default {
  Enschede: {
    'Stadsdeel Centrum': [
      'Binnenstad',
      'Boddenkamp',
      'De Bothoven',
      '\'t Getfert',
      'Hogeland Noord',
      'Horstlanden-Veldkamp',
      'Laares, Lasonder-\'t Zeggelt',
    ],
    'Stadsdeel Noord': [
      'Lonneker',
      'Deppenbroek',
      'Bolhaar',
      'Mekkelholt',
      'Roombeek',
      'Twekkelerveld',
    ],
    'Stadsdeel Oost': [
      'Wooldrik',
      'Velve-Lindenhof',
      'De Eschmarke',
      '\'t Ribbelt',
      'Stokhorst',
      'Dolphia',
      '\'t Hogeland',
      'Glanerbrug',
    ],
    'Stadsdeel Zuid': ['Wesselerbrink', 'Helmerhoek', 'Stroinkslanden'],
    'Stadsdeel West': [
      'Boswinkel',
      'Ruwenbos',
      'Pathmos',
      'Stadsveld',
      'BruggerT',
      '\'t Zwering',
      '\'t Havengebied',
      'De Marssteden',
      'Boekelo, Usselo',
      'Twekkelo',
    ],
  },
};

