import {
  FORGOT_PASS,
  LOGIN,
  LOGOUT,
  PERSONAL_INFO,
  REGISTER,
  RESET_PASSWORD,
  TERMS_AND_CONDITIONS,
} from '../constants/routes';
import { getHistory, } from '../store';
import actions from './auth-actions';
export default async (store) => {
  const currentRoute = getHistory().location.pathname;
  if (![LOGIN, REGISTER, LOGOUT, FORGOT_PASS, RESET_PASSWORD, PERSONAL_INFO, '/'].includes(currentRoute)) {
    // eslint-disable-next-line no-async-promise-executor
   
    store.dispatch(actions.getSession()).then(() => {
      const user = store.getState().auth.user;
      if (user && user.identity) {
        const { traits } = user.identity;
        if ((traits.isPrivacyPolicyAgreed == true && traits.isTncAgreed === true) === false) {
          getHistory().push(TERMS_AND_CONDITIONS);
        }
      }
    });
  }
};

