import FieldWrapper from 'components/form/client-field-wrapper';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import schema, { extractDefaults } from '../../../validation/client-fields';
import Spinner from 'components/utils/spinner';
import { Form, Formik } from 'formik';
import { CLIENT_LIST } from 'constants/routes';
import { Link } from 'react-router-dom';
import actions from 'actions/hydra-actions';
import { withTranslation } from 'react-i18next';

class RegisterClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = { copyToClipboardText: 'Copy to clipboard' };
  }

  handleSubmit = async (formData) => {
    const { dispatch } = this.props;
    await dispatch(actions.createClient(formData, CLIENT_LIST));
  };
  setScope = (event, scopeList, setFieldCallback) => {
    const { value, checked } = event.target;
    let scopes = scopeList.split(' ');
    // Add to scopes if checkbox was checked
    if (checked) {
      if (!scopes.includes(value)) {
        scopes.push(value);
      }
    } else {
      // Remove from scopes if checkbox was unchecked
      scopes = scopes.filter((scope) => scope !== value);
    }
    setFieldCallback('scope', scopes.join(' '));
  };
  copyToClipboard = (text, key) => {
    navigator.clipboard.writeText(text);
    this.setState({
      copyToClipboardText: `${key} Copied`,
    });
  };

  resetCopyMessage = () => {
    this.setState({ copyToClipboardText: 'Copy to clipboard' });
  };

  render() {
    const { loading, hydraLoading, t } = this.props;
    if (loading || hydraLoading) {
      return <Spinner loading={true} />;
    }
    return (
      <div className="relative p-5 md:p-8 lg:p-10 w-full md:w-4/6 lg:w-4/5 xl:w-5/6">
        <div
          className="w-full border border-gray-200 rounded"
          style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)' }}
        >
          <div className="px-8 pt-8 mb-6 items-center">
            <Link
              to={CLIENT_LIST}
              className={
                'weight-400 text-lg cursor-pointer letter-spacing line-height24 text-black'
              }
            >
              <i className="fas fa-arrow-left"></i>
            </Link>
            <span className="ml-3 text-2xl font-bold">{t('admin.clients.new.title')}</span>
          </div>
          <div className="mx-2 mt-5 ">
            <Formik
              initialValues={extractDefaults()}
              onSubmit={this.handleSubmit}
              validationSchema={schema}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({ errors, values, handleChange, setFieldValue }) => {
                if (values) {
                  return (
                    <Form>
                      <FieldWrapper
                        error={errors.client_name}
                        required={true}
                        title={t('admin.clients.fields.client_name')}
                        element="client_name"
                      >
                        <input
                          value={values.client_name}
                          name="client_name"
                          type="text"
                          onChange={handleChange}
                          className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-1"
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        error={errors.client_uri}
                        required={true}
                        title={t('admin.clients.fields.client_uri')}
                        element="client_uri"
                      >
                        <input
                          value={values.client_uri}
                          name="client_uri"
                          type="url"
                          onChange={handleChange}
                          className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-1"
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        error={errors.redirect_uris}
                        required={true}
                        title={t('admin.clients.fields.redirect_uri')}
                        element="redirect_uris[0]"
                      >
                        <input
                          value={values.redirect_uris[0]}
                          name="redirect_uris[0]"
                          type="url"
                          onChange={handleChange}
                          className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-1"
                        />
                      </FieldWrapper>

                      <FieldWrapper
                        error={errors.post_logout_redirect_uris}
                        required={true}
                        title={t('admin.clients.fields.post_logout_redirect_uri')}
                        element="post_logout_redirect_uris[0]"
                      >
                        <input
                          value={values.post_logout_redirect_uris[0]}
                          name="post_logout_redirect_uris[0]"
                          type="url"
                          onChange={handleChange}
                          className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-1"
                        />
                      </FieldWrapper>

                      <FieldWrapper
                        error={errors.logo_uri}
                        element="logo_uri"
                        title={t('admin.clients.fields.logo_uri')}

                      >
                        <input
                          value={values.logo_uri}
                          name="logo_uri"
                          type="url"
                          onChange={handleChange}
                          className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-1"
                        />
                      </FieldWrapper>

                      <FieldWrapper
                        error={errors.policy_uri}
                        title={t('admin.clients.fields.policy_uri')}
                        element="policy_uri"
                      >
                        <input
                          value={values.policy_uri}
                          name="policy_uri"
                          type="url"
                          onChange={handleChange}
                          className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-1"
                        />
                      </FieldWrapper>

                      <FieldWrapper
                        error={errors.tos_uri}
                        title={t('admin.clients.fields.tnc_uri')}
                        element="tos_uri"
                      >
                        <input
                          value={values.tos_uri}
                          name="tos_uri"
                          type="url"
                          onChange={handleChange}
                          className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-1"
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        error={errors.scope}
                        title={t('admin.clients.fields.scope')}
                        element="scope"
                      >
                        <div className="flex gap-2">
                          <input
                            value="email"
                            name="scope"
                            type="checkbox"
                            checked
                            readOnly
                            disabled
                            className="appearance-none block  bg-white text-gray-700 border border-gray-200 rounded-full  py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-1"
                          />{' '}
                          Email
                        </div>

                        <div className="flex gap-2">
                          <input
                            value="profile"
                            name="scope"
                            type="checkbox"
                            onChange={(e) =>
                              this.setScope(e, values.scope, setFieldValue)
                            }
                            className="appearance-none block  bg-white text-gray-700 border border-gray-200 rounded-full  py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-1"
                          />{' '}
                          Profile
                        </div>
                        <div className="flex gap-2">
                          <input
                            value="address"
                            name="scope"
                            type="checkbox"
                            onChange={(e) =>
                              this.setScope(e, values.scope, setFieldValue)
                            }
                            className="appearance-none block  bg-white text-gray-700 border border-gray-200 rounded-full  py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-1"
                          />{' '}
                          Address
                        </div>
                        <div className="flex gap-2">
                          <input
                            value="phone"
                            name="scope"
                            type="checkbox"
                            onChange={(e) =>
                              this.setScope(e, values.scope, setFieldValue)
                            }
                            className="appearance-none block  bg-white text-gray-700 border border-gray-200 rounded-full  py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-1"
                          />{' '}
                          Phone
                        </div>
                      </FieldWrapper>

                      <FieldWrapper
                        error={errors.client_secret}
                        title={t('admin.clients.fields.client_secret')}
                        element="client_secret"
                      >
                        <div className="flex relative">
                          <input
                            value={values.client_secret}
                            name="client_secret"
                            type="text"
                            onChange={handleChange}
                            className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4  leading-tight focus:outline-none focus:bg-white focus:border-gray-500 mb-1"
                            readOnly
                            disabled
                          />

                          <button
                            type="button"
                            className="absolute right-0 border rounded-lg p-2.5 focus:outline-none hover:shadow-lg group bg-gray-100"
                            onClick={() =>
                              this.copyToClipboard(
                                values.client_secret,
                                'Client Secret'
                              )
                            }
                            onMouseLeave={this.resetCopyMessage}
                          >
                            <span className="absolute hidden group-hover:flex z-20 -left-10 -top-2 -translate-y-full w-32 px-2 py-1 bg-gray-700 rounded-lg text-center text-white text-sm after:content-[''] after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-gray-700">
                              {this.state.copyToClipboardText}
                            </span>

                            <i className="fas fa-copy text-gray-400"></i>
                          </button>
                        </div>
                        <div className="group relative text-sm rounded-md p-0.5 flex mr-1">
                          <span className="absolute flex left-32 -top-12 -translate-y-full w-64 px-2 py-1 bg-gray-700 rounded-lg text-center text-white text-sm after:content-[''] after:absolute after:left-1/2 after:top-[100%] after:-translate-x-1/2 after:border-8 after:border-x-transparent after:border-b-transparent after:border-t-gray-700">
                            {
                             t('admin.clients.fields.warning.client_secret')
                            }
                          </span>
                        </div>

                        {/* <p className="text-xs">
                          {
                            "Please save the client secret somewhere safe because after the registration process it's impossible to retrieve the secret key."
                          }
                        </p> */}
                      </FieldWrapper>

                      <FieldWrapper>
                        <button
                          type="submit"
                          className="btn-primary shadow text-white py-2 rounded  hover:shadow-lg p-2 px-8 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150 float-right"
                          disabled={hydraLoading}
                        >
                          {t('common.buttons.save')}
                        </button>
                      </FieldWrapper>
                    </Form>
                  );
                }
              }}
            </Formik>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  user: state.auth.user,
  hydraLoading: state.hydra.loading,
});
export default withTranslation()(withRouter(connect(mapStateToProps)(RegisterClient)));

