import { Component } from 'react';
import { connect } from 'react-redux';

class StepVerification extends Component {
  render() {
    return (
      <div className="relative p-5 lg:p-10 w-full md:w-6/12 lg:w-4/12">
        <div>
          <p className="text-2xl font-bold mb-4">2-Step Verification</p>
          <span className="font-14">
            For your account security, we now require two-factor authentication
          </span>
        </div>
        <div className="border border-gray-500 p-5 rounded w-full flex items-center mt-8">
          <div className="ml-5">
            <span className="block text-black font-14 font-semibold">
              Please select your authentication method
            </span>
            <div className="mt-6">
              <label className="text-sm font-medium text-gray-700 flex">
                <input
                  type="radio"
                  name="status"
                  className="mr-3 align-text-bottom focus:radio-checked h-4 w-4 text-primary border-gray-300"
                />
                <div>
                  <p className="block text-black font-14">Email verification</p>
                  <span className="text-gray">
                    We’II send a rendomized 6-digit code to name@example.com
                  </span>
                </div>
              </label>
              <label className="text-sm font-medium text-gray-700 flex mt-6">
                <input
                  type="radio"
                  name="status"
                  className="mr-3 align-text-bottom focus:radio-checked h-4 w-4 text-primary border-gray-300"
                />
                <div>
                  <p className="block text-black font-14">Authenticator app</p>
                  <span className="text-gray">
                    Set up an app like Google Authenticator or Due Mobile to
                    generate login codes
                  </span>
                </div>
              </label>
            </div>
            <div className="flex justify-end mt-6">
              <button
                className="text-white text-sm rounded shadow uppercase hover:shadow-lg p-2 px-8 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
                type="button"
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
});

export default connect(mapStateToProps)(StepVerification);

