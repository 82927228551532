import env from 'api/ory/env';
import { INFORMATION_EDIT } from 'constants/routes';
import { PERSONAL_INFO } from 'constants/routes';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';
import adminActions from '../../actions/ory-admin-actions';
import actions from '../../actions/auth-actions';
import { LOGIN } from 'constants/routes';
import Spinner from 'components/utils/spinner';
import { FLOW_TYPES } from 'constants/flows';
import { siteAccessConfig } from 'api/keto/config';
import ketoActions from '../../actions/keto-actions';
import twilioActions from '../../actions/twilio-actions';
import { VERIFY_OTP } from 'constants/routes';
import { withTranslation } from 'react-i18next';

class InformationShow extends Component {
  deleteEmail = async (email) => {
    const {
      user: { identity },
      dispatch,
    } = this.props;
    const payload = {
      traits: {
        ...identity?.traits,
      },
      schema_id: env.DEFAULT_SCHEMA_ID,
      state: identity?.state,
    };
    payload.traits.email = payload.traits.email?.filter(
      (currentEmail) => currentEmail !== email
    );
    await dispatch(
      adminActions.updateIdentity(identity?.id, payload, {
        redirectTo: `${LOGIN}?refresh=true&return_to=${PERSONAL_INFO}`,
      })
    );

    const { relation, namespace } = siteAccessConfig;
    await dispatch(
      ketoActions.createRole([
        {
          action: 'delete',
          relation_tuple: {
            namespace,
            relation,
            subject_id: email,
          },
        },
      ])
    );
    await dispatch(actions.getSession());
  };

  deleteIdentity = async () => {
    const {
      user: { identity },
      dispatch,
    } = this.props;

    await dispatch(
      adminActions.deleteIdentity(identity?.id, {
        redirectTo: LOGIN,
        message: {
          onSuccess: 'Account Deleted, You will be logged out now.',
          onError: 'An Error occurred while deleting your account',
        },
      })
    );
  };

  getEmailMetadata = (email) => {
    const { user } = this.props;
    return (
      user?.identity?.verifiable_addresses.find(
        ({ value }) => value === email?.toLowerCase()
      ) || {}
    );
  };

  initiateVerificationFlow = async () => {
    try {
      const { dispatch } = this.props;
      await dispatch(actions.getAccountVerficationFlow());
    } catch (error) {
      console.log(error);
    }
  };

  submitVerificationFlow = async (email) => {
    try {
      const { verificationFlow, dispatch } = this.props;
      const payload = {};
      verificationFlow?.ui?.nodes?.forEach((node) => {
        payload[node?.attributes?.name] = node.attributes?.value || '';
      });
      payload['email'] = email;
      await dispatch(
        actions.submitFlow(verificationFlow, FLOW_TYPES.VERIFY, payload)
      );
    } catch (error) {
      console.log(error);
    }
  };

  sendOtp = async (phone) => {
    try {
      const { dispatch } = this.props;
      dispatch(twilioActions.sendOtp(phone, VERIFY_OTP));
    } catch (error) {
      console.log(error);
    }
  };
  render() {
    const { loading, adminLoading, sendingOtp, t } = this.props;

    if (loading || adminLoading || sendingOtp) {
      return <Spinner loading={true} />;
    }

    if (this.props.location?.state?.fields) {
      const { fields } = this.props.location.state;
      const { title, description, value, text } = fields;
      if (title === 'DeleteAccount') {
        return (
          <div className="relative p-5 md:p-8 lg:p-10 w-full h-full">
            <div className="flex w-fit mx-auto align-middle h-full m-auto justify-center items-center  shadow p-14 border border-gray-200 rounded mt-5">
              <div className="flex flex-col justify-center items-center">
                <div className="w-20 object-cover text -center">
                  {/* <img
                    className="w-full h-full rounded-full"
                    src={user?.identity?.traits?.picture || config.PROFILE}
                    alt=""
                  /> */}
                </div>
                <p className="text-lg my-3">{t('information_show.delete_account.title')}</p>
                <span className="mb-3 text-gray-500">
                {t('information_show.delete_account.text')}
                </span>
                <div className="flex justify-center items-center">
                  <Link to={PERSONAL_INFO}>
                    <button
                      type="button"
                      className="shadow text-black theme-border rounded  mr-5 p-3 px-10 outline-none border focus:outline-none mb-1 ease-linear transition-all duration-150"
                    >
                      {t('common.buttons.cancle')}
                    </button>
                  </Link>
                  <button
                    type="button"
                    className="shadow btn-primary text-white theme-border rounded  mr-5 p-3 px-10 outline-none border focus:outline-none mb-1 ease-linear transition-all duration-150"
                    onClick={this.deleteIdentity}
                    disabled={adminLoading}
                  >
                      {t('common.buttons.delete')}
                   
                  </button>
                </div>
              </div>
            </div>
          </div>
        );
      }
      return (
        <div className="relative p-5 md:p-8 lg:p-10 w-full xl:w-5/6">
          <div className="w-full xl:w-5/6 lg:mx-auto border-gray-200 rounded mt-4">
            <div className="mb-6 items-center">
              <Link
                to={PERSONAL_INFO}
                className={
                  'weight-400 text-lg cursor-pointer letter-spacing line-height24 text-black'
                }
              >
                <i className="fas fa-arrow-left"></i>
              </Link>

              <span className="ml-3 text-2xl font-bold inline-block">
                {text}
              </span>
              <p className="ml-7 mt-2 text-gray-500">{description}</p>
            </div>
            {title === 'Email' ? (
              <div className="xl:w-10/12 w-full justify-start shadow border border-gray-200 rounded mt-8">
                <div className="w-full p-6 px-8">
                  {value.map((email, index) => {
                    const metaData = this.getEmailMetadata(email);
                    return (
                      <div className="md:flex items-center mt-6" key={index}>
                        <div className="w-8/12">
                          <p className="block text-lg">{email}</p>
                        </div>
                        <div className="md:w-3/6 mt-3 md:mt-0">
                          <div className="flex justify-between items-center text-gray-500">
                            {metaData?.verified ? (
                              <p>{t('information_show.email.buttons.verified')}</p>
                            ) : (
                              <button
                                className="block pr-4  hover:bg-slate-100 p-2 rounded-lg"
                                onClick={async () => {
                                  await this.initiateVerificationFlow();
                                  await this.submitVerificationFlow(email);
                                }}
                              >
                              
                               {t('information_show.email.buttons.send_verification')}
                              </button>
                            )}
                            {/* {email?.default ? (
                              <p>Default</p>
                            ) : (
                              <>
                                <button
                                  className="block pr-4  hover:bg-slate-100 p-2 rounded-lg"
                                  onClick={() => {
                                    console.log('Setting default', email);
                                    console.log(this.getEmailMetadata(email));
                                  }}
                                >
                                  Set Default
                                </button> */}
                                {value?.length > 1 && (
                                  <button
                                    className="flex mx-2 justify-center items-center hover:bg-slate-100 p-2 rounded-lg"
                                    onClick={() => {
                                      this.deleteEmail(email);
                                    }}
                                  >
                                       {t('information_show.email.buttons.delete')}
                                  </button>
                                )}
                              {/* </>
                            )} */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <hr />
                <div className="w-full p-4 px-8 text-primary ">
                  <Link
                    className="inline-block"
                    to={{
                      pathname: INFORMATION_EDIT,
                      state: { fields: fields },
                    }}
                  >
                    <button className="flex mx-2 justify-center items-center hover:bg-slate-100 p-2 rounded-lg">
                    {t('information_show.email.buttons.add')}
        
                    </button>
                  </Link>
                </div>
              </div>
            ) : null}
            {title === 'Telegram' ? (
              <div className="xl:w-10/12 w-full justify-start shadow p-6 border border-gray-200 rounded mt-8">
                <div className="w-full">
                  <div className="md:flex items-center">
                    <div className="w-3/12">
                      <p className="block font-semibold">{text}</p>
                    </div>
                    <div className="md:w-9/12 mt-3 md:mt-0">
                      <div className="flex justify-between items-center">
                        <p className="block pr-4">
                          +{value.dial_code} {value.number}
                        </p>
                        <div className="flex mx-2 justify-center items-center">
                          <Link
                            to={{
                              pathname: INFORMATION_EDIT,
                              state: { fields: fields },
                            }}
                          >
                            <i className="text-2xl fas fa-pen cursor-pointer"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {title === 'Phone' ? (
              <div className="xl:w-10/12 w-full justify-start shadow p-6 border border-gray-200 rounded mt-8">
                <div className="w-full">
                  <div className="md:flex items-center">
                    <div className="w-3/12">
                      <p className="block font-semibold">{text}</p>
                    </div>
                    <div className="md:w-9/12 mt-3 md:mt-0">
                      <div className="flex justify-between items-center">
                        <p className="block pr-4">
                          +{value.dial_code} {value.number}
                        </p>
                        {value?.verified ? (
                          <p>Verified</p>
                        ) : (
                          <button
                            className="block pr-4 hover:bg-slate-100 p-2 rounded-lg"
                            onClick={async () => {
                              await this.sendOtp(
                                `+${value.dial_code}${value.number}`
                              );
                            }}
                          >
                            Send Verification
                          </button>
                        )}

                        <div className="flex mx-2 justify-center items-center">
                          <Link
                            to={{
                              pathname: INFORMATION_EDIT,
                              state: { fields: fields },
                            }}
                          >
                            <i className="text-2xl fas fa-pen cursor-pointer"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {title === 'Passwordless' ? (
              <div className="xl:w-10/12 w-full justify-start shadow p-6 border border-gray-200 rounded mt-8">
                <div className="w-full">
                  <div className="md:flex items-center">
                    <div className="w-3/12">
                      <p className="block font-semibold">{text}</p>
                    </div>
                    <div className="md:w-9/12 mt-3 md:mt-0">
                      <div className="flex justify-between items-center">
                        <p className="block pr-4">
                          {value ? 'Enabled' : 'Not enabled'}
                        </p>
                        {/* {!value?.verified ? <p>Verify</p> : <p>Verified</p>} */}
                        {/* <p>Delete</p> */}
                        <div className="flex mx-2 justify-center items-center">
                          <Link
                            to={{
                              pathname: INFORMATION_EDIT,
                              state: { fields: fields },
                            }}
                          >
                            <i className="text-2xl fas fa-pen cursor-pointer"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {title === 'Language' ? (
              <div className="xl:w-10/12 w-full justify-start shadow p-6 border border-gray-200 rounded mt-8">
                <div className="w-full">
                  <div className="md:flex items-center">
                    <div className="w-3/12">
                      <p className="block font-semibold">{text}</p>
                    </div>
                    <div className="md:w-9/12 mt-3 md:mt-0">
                      <div className="flex justify-between items-center">
                        <p className="block pr-4">{value?.value}</p>
                        <div className="flex mx-2 justify-center items-center">
                          <Link
                            to={{
                              pathname: INFORMATION_EDIT,
                              state: { fields: fields },
                            }}
                          >
                            <i className="text-2xl fas fa-pen cursor-pointer"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {title === 'Image' ? (
              <div className="xl:w-10/12 w-full justify-start shadow p-6 border border-gray-200 rounded mt-8">
                <div className="w-full">
                  <div className="md:flex items-center">
                    <div className="w-3/12">
                      <p className="block font-semibold">{text}</p>
                    </div>
                    <div className="md:w-9/12 mt-3 md:mt-0">
                      <div className="flex justify-between items-center">
                        <div className="w-14 h-14 object-cover text -center">
                          <img
                            className="w-full h-full rounded-full"
                            src={value}
                            alt=""
                          />
                        </div>
                        <div className="flex mx-2 justify-center items-center">
                          <Link
                            to={{
                              pathname: INFORMATION_EDIT,
                              state: { fields: fields },
                            }}
                          >
                            <i className="text-2xl fas fa-pen cursor-pointer"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
            {title === 'Language' ||
            title === 'Email' ||
            title === 'Phone' ||
            title === 'Telegram' ||
            title === 'Passwordless' ||
            title === 'Image' ? null : (
              <div className="xl:w-9/12 w-full justify-start shadow p-6 border border-gray-200 rounded mt-8">
                <div className="w-full">
                  <div className="md:flex items-center">
                    <div className="w-3/12">
                      <p className="block font-semibold">{text}</p>
                    </div>
                    <div className="md:w-9/12 mt-3 md:mt-0">
                      <div className="flex justify-between items-center">
                        <p className="block pr-4">{value}</p>
                        <div className="flex mx-2 justify-center items-center">
                          <Link
                            to={{
                              pathname: INFORMATION_EDIT,
                              state: { fields: fields },
                            }}
                          >
                            <i className="text-2xl fas fa-pen cursor-pointer"></i>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    } else {
      return <Redirect to={PERSONAL_INFO} />;
    }
  }
}

const mapStateToProps = (state) => ({
  loading: state.auth.loading,
  user: state.auth.user,
  adminLoading: state.oryAdmin.loading,
  verificationFlow: state.auth.verificationFlow,
  sendingOtp: state.twilio.loading,
});

export default withTranslation()(withRouter(connect(mapStateToProps)(InformationShow)));

