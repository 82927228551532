import actions from '../actions/keto-actions';

const initialState = {
  loading: false,
  roles: [],
  role: undefined,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.CREATE_ROLE_STARTED:
      return {
        ...state,
        loading: true,
        role: undefined
      }
    case actions.CREATE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        role: payload
      }
    case actions.CREATE_ROLE_ERROR:
      return {
        ...state,
        loading: false,
        role: undefined
      }
    case actions.GET_ROLES_STARTED:
      return {
        ...state,
        loading: true,
        roles: []
      }
    case actions.GET_ROLES_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: payload
      }
    case actions.GET_ROLES_ERROR:
      return {
        ...state,
        loading: false,
        roles: []
      }
    default:
      return state
  }
}