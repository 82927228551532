export default {
  KETO_READ_API_URL:
    process.env.REACT_APP_KETO_READ_API_URL ||
    process.env.REACT_APP_ORY_API_URL,
  KETO_WRITE_API_URL:
    process.env.REACT_APP_KETO_WRITE_API_URL ||
    process.env.REACT_APP_ORY_API_URL,
  COMMUNITY_SITE_ID: process.env.REACT_APP_HYDRA_COMMUNITY_CLIENT,
};

