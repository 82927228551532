import { Component } from 'react';
import { connect } from 'react-redux';
import actions from '../../actions/auth-actions';
import Node from './node';
export class flow extends Component {


  initialState = () => {
    const nodes = this.props?.flow?.ui?.nodes;
    const values = {};
    nodes.forEach(node => {
      values[node.attributes.name] = '';
    });
    return values;
  };

  state = {
    values: this.initialState(),
    stateUpdate: false
  };

  setValue = (value, key) => {
    this.setState({ values: { ...this.state.values, [key]: value } });
  }

  initialValues = () => {
    const nodes = this.props?.flow?.ui?.nodes;
    const formData = {};
    if (nodes && nodes[0]) {
      nodes.forEach(node => {
        formData[node.attributes.name] = node.attributes.value;
      });
    }
    this.setState({ values: formData });
  }

  componentDidMount() {
    if (!this.state.values.csrf_token) {
      this.initialValues();
    }
  }

  extractNodes({ groups = [], nodes = [], types = [] }, include = true) {
    const { flow } = this.props;
    let extractedNodes = [];
    if (flow && flow?.ui?.nodes) {
      const filterNodes = flow.ui.nodes.filter(node => (groups.includes(node.group)) === include || (nodes.includes(node?.attributes?.name)) === include || (types.includes(node?.attributes?.type) && node?.group !== 'oidc'));
      extractedNodes = filterNodes;
    }
    return extractedNodes;
  }

  sortNodes(nodeList = [], sortList = []) {
    let sortedNodes = [];
    sortList.forEach(listItem => {
      const node = nodeList.find(nodeItem => nodeItem?.attributes?.name === listItem);
      if (node) {
        sortedNodes.push(node);
      }
    })
    sortedNodes = [...new Set([...sortedNodes, ...nodeList])];
    return sortedNodes;
  }

  isEmpty = (validator, value) => {
    if (!validator.required || validator?.skip) {
      return true;
    }
    if (value == '' || value?.length == 0 ||
      value === null || value === undefined ||
      validator?.nullIdentifier?.toLowerCase() === value?.toLowerCase()) {
      return `${validator.label} is required!`
    }

    return true;
  }

  compare = (validator, value, formData = {}) => {
    if (!validator?.compareWith?.field || validator?.skip) {
      return true;
    }
    if (value?.length > 0 && formData[validator.compareWith.field] && formData[validator.compareWith.field] !== value) {
      return `${validator.compareWith.title} and ${validator.label} did not match!`;
    }
    return true;
  }

  isWithinRange = (validator, value) => {
    let error = '';
    const label = validator.label;

    if (validator?.skip) {
      return true;
    }
    if (validator.length) {
      if (value?.length > 0 && value?.length !== validator.length) {
        error = `${label} should be exactly ${validator['length']} characters long!`
      }
    }
    if (value?.length > 0 && validator.maxLength && !validator.minLength) {
      if (value?.length > validator.maxLength) {
        error = `${label} should not be more than ${validator['maxLength']} characters long!`
      }
    }
    if (value?.length > 0 && !validator.maxLength && validator.minLength) {
      if (value?.length < validator.minLength) {
        error = `${label} should be atleast ${validator['minLength']} characters long!`
      }
    }
    if (value?.length > 0 && validator.minLength && validator.maxLength) {
      if (value?.length > validator.maxLength || value?.length < validator.minLength) {
        error = `${label} should be ${validator['minLength']} to ${validator['maxLength']} characters long`
      }
    }

    return error || true;
  }

  getValidationStatus = (validator, value, formData) => {
    let response = [];
    response[0] = this.isEmpty(validator, value);
    response[1] = this.isWithinRange(validator, value);
    response[2] = this.compare(validator, value, formData);
    return response;
  }

  initiateValidation = (formData) => {
    const { validationSchema } = this.props || {};
    const validationResponse = {};
    if (validationSchema) {
      Object.keys(validationSchema).forEach(schema => {
        const validator = validationSchema[schema];
        const value = formData[schema];
        validationResponse[schema] = this.getValidationStatus(validator, value, formData);
      });
    }
    return validationResponse;
  }

  setErrors = (values) => {
    const response = this.initiateValidation(values);
    const { flow } = this.props;
    let invalidNodesCount = 0;
    flow?.ui?.nodes.forEach(node => {
      const { name } = node.attributes;
      if (response[name] && response[name][0]) {
        node.messages = response[name].map((text, id) => {
          if (text !== true) {
            invalidNodesCount += 1;
          }
          return {
            id, text, type: 'error'
          }
        });
      }
    })

    this.setState((prevState) => {
      return {
        ...prevState,
        stateUpdate: !prevState.stateUpdate
      }
    })

    return invalidNodesCount;
  }

  handleSubmit = async e => {
    e.preventDefault();
    e.stopPropagation();
    const { flow, type, dispatch, validationSchema } = this.props;
    const nodeList = Object.keys(validationSchema);
    const nodes = this.extractNodes({ nodes: [...nodeList, 'csrf_token'], types: ['submit'] });
    const form = e.target;
    const values = {};
    nodes.forEach(node => {
      const { name } = node.attributes;
      const { value } = form[name] || '';
      values[name] = value;
    });

    const isValid = !this.setErrors(values);
    if (isValid) {
      if (values['traits.confirm_password']) {
        delete values['traits.confirm_password'];
      }
      if (values['method']) {
        values['method'] = 'password'
      }
      dispatch(actions.submitFlow(flow, type, values))
    }

  }

  render() {
    const { flow, type, validationSchema = {} } = this.props;
    const { action, method } = flow.ui;
    const nodeList = Object.keys(validationSchema);
    const OAuthNodes = this.extractNodes({ groups: ['oidc'] });
    const otherNodes = this.sortNodes(this.extractNodes({ nodes: [...nodeList, 'csrf_token'], types: ['submit'] }), nodeList);
    return (
      <>
        <form action={action} method={method} onSubmit={this.handleSubmit}>
          {otherNodes.map((node, key) => {
            const initialValues = this.state.values[node?.attributes?.name];
            const validator = validationSchema[node?.attributes?.name] || {};
            return (<Node node={node} key={key} onChange={this.setValue} value={initialValues} validator={validator} flowType={type} />);
          })}
        </form>
        <form action={action} method={method}>
          {OAuthNodes.map((node, key) => {
            const initialValues = this.state.values[node.attributes.name];
            return (<Node node={node} key={key} onChange={this.setValue} value={initialValues} />);
          })}
        </form>
      </>
    )
  }
}

const mapStateToProps = state => ({
  user: state.auth.user,
  loading: state.auth.loading
});

export default connect(
  mapStateToProps,
)(flow);