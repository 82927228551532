export const FLOW_TYPES = {
  LOGIN: 'login',
  REGISTER: 'register',
  BUSINESS_REGISTER: 'business-register',
  FORGOT_PASSWORD: 'forgot-password',
  ERROR: 'error',
  SETTINGS: 'settings',
  CHANGE_PASSWORD: 'change-password',
  LOGOUT: 'logout',
  VERIFY: 'verify',
  CREATE_BBA_ACCOUNT: 'create-bba-account',
  PERSONAL_INFORMATION : 'personal-information'
};

export const buttonText = {
  [FLOW_TYPES.LOGIN]: 'Login',
  [FLOW_TYPES.REGISTER]: 'Register Now',
  [FLOW_TYPES.FORGOT_PASSWORD]: 'Submit',
  [FLOW_TYPES.FORGOT_PASSWORD]: 'Submit',
  [FLOW_TYPES.SETTINGS]: 'Save',
  [FLOW_TYPES.CHANGE_PASSWORD]: 'Reset Password',
  [FLOW_TYPES.VERIFY]: 'Verify your account',
};

export const defaultProps = {
  className: 'btn-primary shadow uppercase text-white py-2 px-4 rounded w-full hover:shadow-lg p-2 px-8 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150',
};
export const buttonProps = {
  [FLOW_TYPES.SETTINGS]: {
    className: 'btn-primary shadow uppercase text-white py-2 px-4 rounded hover:shadow-lg p-2 px-8 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150',
  },
  [FLOW_TYPES.VERIFY]: {
    ...defaultProps
  },
  [FLOW_TYPES.CHANGE_PASSWORD]: {
    ...defaultProps
  },
  [FLOW_TYPES.LOGIN]: {
    ...defaultProps
  },
  [FLOW_TYPES.REGISTER]: {
    ...defaultProps
  },
  [FLOW_TYPES.ERROR]: {
    ...defaultProps
  },
  [FLOW_TYPES.FORGOT_PASSWORD]: {
    ...defaultProps
  },
  [FLOW_TYPES.LOGOUT]: {
    ...defaultProps
  },
};
