import {
  ACCOUNT_LIST,
  HOME,
  LOGOUT,
  PERSONAL_INFO,
  ADMIN_HOME,
  IDENTITIES_LIST,
  CLIENT_LIST,
} from '../../constants/routes';


export const sidebarMenu = [
  {
    title: 'Home',
    icon: 'home',
    link: HOME,
    submenu: [],
    roles: ['user'],
    i18nkey:'sidebar.home'
  },
  {
    title: ' Personal Info',
    icon: 'user',
    link: PERSONAL_INFO,
    roles: ['user'],
    submenu: [],
    i18nkey:'sidebar.personal_info'

    // submenu: [
    //   {
    //     title: 'Edit Information',
    //     link: PERSONAL_INFO_VERIFICATION
    //   },
    // ],
  },
  // {
  //   title: 'Security',
  //   icon: 'lock',
  //   link: STEP_VERIFICATION,
  //   submenu: [
  //     {
  //       title: 'Password',
  //       link: RESET_PASSWORD,
  //     },
  //     {
  //       title: '2FA',
  //       link: STEP_VERIFICATION,
  //     },
  //   ],
  //   roles: ['user'],
  // },
  {
    title: 'Admin',
    icon: 'user-cog',
    link: ADMIN_HOME,
    i18nkey:'sidebar.admin.title',
    submenu: [
      {
        title: 'Identities',
        link: IDENTITIES_LIST,
        i18nkey:'sidebar.admin.identities'

      },
      {
        title: 'Clients',
        link: CLIENT_LIST,
        i18nkey:'sidebar.admin.clients'

      },
      {
        title: 'Accounts',
        link: ACCOUNT_LIST,
        i18nkey:'sidebar.admin.accounts'

      },
    ],
    roles: ['admin'],
  },
  {
    title: ' Logout',
    icon: 'sign-out-alt',
    link: LOGOUT,
    submenu: [],
    roles: ['user'],
    i18nkey:'sidebar.logout'
  },
];

