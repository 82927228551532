import React from 'react';
class Column extends React.Component {
  render() {
    return (
      <td className=" align-middle border-l-0 border-r-0 text-xs whitespace-nowrap py-6 px-4">
        <div className="flex items-center">
          <span
            className={
              'weight-400 font-16 letter-spacing line-height24 text-black'
            }
          >
            {this.props.children}
          </span>
        </div>
      </td>
    );
  }
}
export default Column;

