import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import actions from '../../actions/auth-actions';
import { config } from '../../constants/config';
import Spinner from '../utils/spinner';
import { withTranslation } from 'react-i18next';
import bbaActions from '../../actions/bba-actions';
class Home extends Component {
  componentDidMount = async () => {
    const { dispatch, user, networkConfig } = this.props;

    if (!networkConfig?.general_client) {
      dispatch(bbaActions.getNetworkConfig());
    }

    if (user) {
      return;
    }
    
    dispatch(actions.getSession());
  };

  redirect = (link) => {
    window.open(link, '_blank');
  }

  render() {
    const { user, loading, bbaLoading, t, networkConfig } = this.props;
    const { picture } = user?.identity?.traits || {
      display_name: '',
      picture: null,
    }
    if (loading || bbaLoading) {
      return <Spinner loading={true} />;
    } else {
      return (

        <div className="relative ">
          <div className="w-24 h-24 object-cover text-center mx-auto mt-8 mb-4">
            <img
              className="w-full h-full rounded-full"
              src={picture || config.PROFILE}
              alt=""
            />
          </div>
          <div>
            <h1 className="text-2xl text-center text-black mb-8">
              {t('home.title')}
            </h1>
          </div>
          <div className="flex w-11/12 md:w-10/12 lg:w-8/12 xl:w-5/12 mx-auto  flex-wrap items-center justify-center xl:justify-center">
            {/* <div className="w-full">
              <button
                type="button"
                className="btn-primary shadow  text-white px-4 rounded-lg w-full hover:shadow-lg p-2 py-3 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
              >
                {t('home.button.main')}
              </button>
            </div> */}
            <div className="md:flex items-center justify-center w-full">
              {
                networkConfig?.android_link && (
                  <button
                    type="button"
                    className="w-full flex items-center justify-center md:w-3/6 mt-5 btn-primary shadow  text-white py-3 px-4 rounded-lg hover:shadow-lg p-2 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
                    onClick={() => this.redirect(networkConfig.android_link)}
                  >
                    {/* <a target="_blank" href="https://icons8.com/icon/22982/google-play">Google Play</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a>  */}

                    <img
                      className="w-5 inline-block mr-3"
                      src={config.PLAYSTORE}
                      alt=""
                    />
                    <span>{t('home.button.playstore')}</span>
                  </button>
                )
              }
              {
                networkConfig?.apple_link && (
                  <button
                    type="button"
                    className="w-full flex items-center justify-center  md:w-3/6 mt-5 btn-primary shadow  text-white py-3 px-4 rounded-lg hover:shadow-lg p-2 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
                    onClick={() => this.redirect(networkConfig.apple_link)}
                  >
                    <img
                      className="w-5 inline-block mr-3"
                      src={config.APPSTORE}
                      alt=""
                    />
                    <span>{t('home.button.appstore')}</span>
                  </button>
                )
              }

            </div>
          </div>
          {/* <div className="flex flex-wrap items-center justify-center overflow-x-auto home-container xl:justify-start">
            <div className="md:w-3/12 w-10/12  border rounded mr-8 home-card">
              <div className="p-6 flex items-center"> 
                <div className="w-9/12 mr-3">
                  <h3 className="block text-2xl text-black mb-2">
                  Test01 146
                  </h3>
                  <p className="font-14">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc eget eros sed lacus maximus tempor at vel eros. Donec
                    vulputate semper scelerisque.
                  </p>
                </div>
                <div className="w-3/12 ml-6">
                  <div className='w-24 h-24 bg-slate-300 shadow rounded-full'></div>
                </div>
              </div>
              <hr />
              <a href={'https://test01.146.coop/'} target='blank'  className='underline'>
                <div>
                  <p className="px-5 my-4 text-primary text-sm">
                  Go to Test01.146.coop
                  </p>
                </div>
              </a>
            </div>
            <div className="md:w-3/12 w-10/12 border rounded mr-8 home-card">
              <div className="p-6 flex items-center">
                <div className="w-9/12 mr-3">
                  <h3 className="block text-2xl text-black mb-2 font-medium">
                  Test02 146
                  </h3>
                  <p className="font-14">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc eget eros sed lacus maximus tempor at vel eros. Donec
                    vulputate semper scelerisque.
                  </p>
                </div>
                <div className="w-3/12 ml-6">
                <div className='w-24 h-24 bg-slate-300 shadow rounded-full'></div>
                </div>
              </div>
              <hr />
              <a href={'https://test02.146.coop/'} target='blank'  className='underline'>
                <div>
                  <p className="px-5 my-4 text-primary text-sm">
                  Go to Test02.146.coop
                  </p>
                </div>
              </a>
            </div>
            <div className="md:w-3/12 w-10/12 border rounded mr-8 home-card">
              <div className="p-6 flex items-center">
                <div className="w-9/12 mr-3">
                  <h3 className="block text-2xl text-black mb-2 font-medium">
                  Test03 146
                  </h3>
                  <p className="font-14">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc eget eros sed lacus maximus tempor at vel eros. Donec
                    vulputate semper scelerisque.
                  </p>
                </div>
                <div className="w-3/12 ml-6">
                <div className='w-24 h-24 bg-slate-300 shadow rounded-full'></div>
                </div>
              </div>
              <hr />
              <a href={'https://test03.146.coop/'} target='blank'  className='underline'>
                <div>
                  <p className="px-5 my-4 text-primary text-sm">
                  Go to Test03.146.coop
                  </p>
                </div>
              </a>
            </div>
            <div className="md:w-3/12 w-10/12 border rounded mr-8 home-card">
              <div className="p-6 flex items-center">
                <div className="w-9/12 mr-3">
                  <h3 className="block text-2xl text-black mb-2 font-medium">
                  Test04 146
                  </h3>
                  <p className="font-14">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                    Nunc eget eros sed lacus maximus tempor at vel eros. Donec
                    vulputate semper scelerisque.
                  </p>
                </div>
                <div className="w-3/12 ml-6">
                <div className='w-24 h-24 bg-slate-300 shadow rounded-full'></div>
                </div>
              </div>
              <hr />
              <a href={'https://test04.146.coop/'} target='blank' className='underline'>
                <div>
                  <p className="px-5 my-4 text-primary text-sm">
                  Go to Test04.146.coop
                  </p>
                </div>
              </a>
            </div>
          </div> */}
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.auth.loading,
  bbaLoading: state.bba.loading,
  networkConfig: state.bba.networkConfig
  // errors: state.errors
});

export default withTranslation()(withRouter(connect(mapStateToProps)(Home)));

