import actions from '../actions/twilio-actions';

const initialState = {
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.SEND_OTP_STARTED:
      return {
        ...state,
        loading: true,
      };
    case actions.SEND_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.SEND_OTP_ERROR:
      return {
        ...state,
        loading: false,
      };
    case actions.VERIFY_OTP_STARTED:
      return {
        ...state,
        loading: true,
      };
    case actions.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case actions.VERIFY_OTP_ERROR:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

