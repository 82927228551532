import axios from 'axios';
import env from './env';
import routes from './routes';
export default (url) =>
  axios.create({
    baseURL: url,
  });

export const bbaAuthClient = async (url) => {
  // let response = fetchToken(url);
  let response = undefined;
  if (!response) {
    const tokenRequestOptions = {
      method: 'POST',
      baseURL: url,
      url: routes.GENERATE_TOKEN,
      data: {
        username: env.BBA_USER,
        password: env.BBA_PASSWORD,
      },
    };

    const { data: tokenResponse } = await axios(tokenRequestOptions);
    response = tokenResponse?.data;
    // saveToken(url, response?.token);
  }

  return axios.create({
    baseURL: url,
    headers: { Authorization: 'Bearer ' + response?.token },
  });
};

