import { Form, Formik } from 'formik';
import React from 'react';
import { connect } from 'react-redux';
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import { withRouter } from 'react-router';
import bbaActions from '../../actions/bba-actions';
import schema, { fieldList } from '../../validation/add-bba-account';
import FieldWrapper from '../form/field-wrapper';
import Spinner from '../utils/spinner';
class AddBBAAccount extends React.Component {
  initialValues = () => {
    const nodes = {};
    fieldList.forEach(field => {
      nodes[field] = ''
    })
    const isBusinessUser = schema.fields.isBusinessAccount.spec?.default;
    nodes['isBusinessAccount'] = !!isBusinessUser;
    return nodes;
  };

  handleSubmit = async (values) => {
    console.log(values)
    const { identity, dispatch, selectedSite } = this.props;
    const wpUser = JSON.parse(values.wpUser);
    const payload = {
      id: identity?.id,
    };
    const traits = {
      is_business: values.isBusinessAccount,
      email: wpUser.email,
      username: wpUser.username,
      display_name: values.displayName
    }
    payload.traits = traits;
    await dispatch(bbaActions.registerBusiness(payload, selectedSite?.client_uri))
    await dispatch(bbaActions.getConnectedUsers(identity?.id, selectedSite?.client_uri))
  }

  style = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  }

  render() {
    const { open, closePopup, selectedSite, bbaLoading, bbaAccounts } = this.props;
    if (bbaLoading) {
      closePopup()
      return <Spinner loading={true} />
    }

    return (<Modal open={open} onClose={closePopup} center>
      {bbaAccounts?.length > 0 ?
        (<><h2 className="p-5">Add BBA Account on Site <a className="link hover:link visited:link" target="_blank" rel="noreferrer" href={selectedSite?.client_uri}>{selectedSite?.client_uri || selectedSite?.client_name}</a></h2>
          <hr />
          <div className="flex-auto px-4 py-9 pt-2">
            <Formik
              initialValues={this.initialValues()}
              onSubmit={this.handleSubmit}
              validationSchema={schema}
              validateOnBlur={false}
              validateOnChange={false}
            >
              {({ errors, values, handleChange }) => {
                if (values) {
                  return (
                    <Form>

                      <label className="text-sm font-medium text-gray-700 flex mb-3">
                        <input
                          type="checkbox"
                          name="isBusinessAccount"
                          className="mr-3 align-text-bottom focus:radio-checked h-4 w-4 text-primary border-gray-300"
                          onChange={handleChange}
                          value={Boolean(values.isBusinessAccount)}
                          checked={Boolean(values.isBusinessAccount)}
                        />
                        <div>
                          <p className="block text-black font-14">is business account</p>
                        </div>
                      </label>
                      <FieldWrapper
                        error={errors.displayName}
                        required={true}
                        title="Display Name"
                        element="displayName"
                      >
                        <input
                          value={values.displayName}
                          name="displayName"
                          type="text"
                          onChange={handleChange}
                          className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        />
                      </FieldWrapper>
                      <FieldWrapper
                        error={errors.wpUser}
                        title="Wordpress User"
                        element="wpUser"
                        required
                      >
                        <select
                          name="wpUser"
                          onChange={handleChange}
                          value={values.wpUser}
                          className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          <option value="default" hidden>Select Wordpress User</option>
                          {
                            bbaAccounts?.map((account, key) => (
                              <option key={key} value={JSON.stringify({ username: account.username, email: account.user_email })} >
                                {account.username}
                              </option>
                            ))
                          }
                        </select>
                      </FieldWrapper>
                      <FieldWrapper
                        error={errors.membership}
                        title="Membership level"
                        element="membership"
                      >
                        <select
                          name="membership"
                          onChange={handleChange}
                          value={values.membership}
                          className="appearance-none block w-full bg-white text-gray-700 border border-gray-200 rounded-lg  py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        >
                          <option value="default" hidden>Select Membership level</option>
                          <option value="Member" >Member</option>
                          <option value="Business" >Business</option>
                        </select>
                      </FieldWrapper>

                      <FieldWrapper>
                        <div className="flex">
                          <button
                            onClick={closePopup}
                            type="button"
                            className=" btn-gray shadow uppercase text-white py-2 px-4 rounded w-full hover:shadow-lg p-2 px-8 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
                          >
                            Cancel
                          </button>
                          <button
                            type="submit"
                            className=" btn-primary shadow uppercase text-white py-2 px-4 rounded w-full hover:shadow-lg p-2 px-8 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
                          >
                            Add
                          </button>
                        </div>
                      </FieldWrapper>
                    </Form>
                  );
                }
              }}
            </Formik>
          </div></>)
        :
        (<div>
          <p className="p-5 break-words">
            No Wordpress User exist for {selectedSite?.client_uri},
            please create new wordpress user first
          </p>
          <FieldWrapper>
            <div >
              <button
                onClick={closePopup}
                type="button"
                className=" btn-gray shadow uppercase text-white py-2 px-4 rounded hover:shadow-lg p-2 px-8 mr-4 outline-none focus:outline-none mb-1 btn-primary ease-linear transition-all duration-150"
              >
                Okay
              </button>
            </div>
          </FieldWrapper>
        </div>)
      }
    </Modal >
    );
  }
}

const mapStateToProps = state => ({
  loading: state.oryAdmin.loading,
  identity: state.oryAdmin.identity,
  bbaLoading: state.bba.loading,
  bbaAccounts: state.bba.users,

});

export default withRouter(connect(mapStateToProps)(AddBBAAccount));
