import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import actions from '../../../actions/ory-admin-actions';
import { IDENTITIES_INFORMATION } from '../../../constants/routes';
import Spinner from '../../utils/spinner';
import Column from 'components/shared/column';
import ConformationPopup from './conformation-popup';
import { withTranslation } from 'react-i18next';
import AddFilter from '../add-filter';
import Pager from 'components/utils/pager';
// const headers = [
//   'Display Name',
//   'Email',
//   'Phone',
//   'vault',
//   'Status',
//   'Registration Date',
//   '',
// ];

class IdentitiesList extends Component {
  // constructor(props) {
  //   super(props);
  // }
  popoverRef = [];

  state = {
    showConfirmationPopUp: false,
    identityId: undefined,
    isOwnAccount: false,
    pageNo: 1,
    itemsPerPage: 10,
    searchValue: '',
    sortValue: 'default',
  };

  componentDidMount = async () => {
    await this.getIdentities(this.state.pageNo, this.state.itemsPerPage);
  };

  componentDidUpdate = (_, prevState) => {
    if (this.state.pageNo !== prevState.pageNo) {
      this.getIdentities(this.state.pageNo, this.state.itemsPerPage);
    }
  };

  /* eslint-disable*/
  getIdentities = async (pageNo = 1, itemsPerPage) => {
    const { dispatch } = this.props;
    dispatch(
      actions.getIdentitiesList({
        page: pageNo,
        per_page: itemsPerPage,
      })
    );
    // dispatch(actions.getIdentitiesList());
  };

  getIdentityFields = (identities) => {
    return identities.map((identity) => ({
      id: identity?.id,
      username: identity?.traits?.username,
      email: identity?.traits?.email,
      phone: identity?.traits?.phone?.number,
      // birth_date: identity?.traits?.birth_date,
      status: 'Approved',
      registration_date: new Date(identity?.created_at).toLocaleDateString(),
      state: identity,
    }));
  };

  setPopoverRef = (ref, index) => {
    this.popoverRef[index] = ref;
    return true;
  };

  showPopover = (refKey) => {
    if (this.popoverRef.length) {
      this.popoverRef.forEach((ref, refIndex) => {
        if (ref) {
          if (refKey === refIndex && ref.style.display !== 'flex') {
            ref.style.display = 'flex';
            return;
          }
          ref.style.display = 'none';
        }
      });
    }
    return true;
  };

  openConfirmationPopUp = (id) => {
    const {
      user: { identity },
    } = this.props;
    this.setState({
      showConfirmationPopUp: true,
      identityId: id,
      isOwnAccount: !!(id === identity?.id),
    });
  };
  closeConfirmationPopUp = () => {
    this.setState({ showConfirmationPopUp: false });
  };
  filterIdentity = (word) => {
    const { dispatch } = this.props;
    this.setState({ searchValue: word, sortValue: 'default' });
    if (word.trim().length) {
      dispatch(actions.filterIdentity(word));
      return;
    }
    this.state.pageNo = 1;
    this.getIdentities(this.state.pageNo, this.state.itemsPerPage);
  };

  sortIdentity = (sortBy) => {
    const { dispatch } = this.props;
    this.setState({ sortValue: sortBy, searchValue: '' });
    dispatch(actions.sortIdentity(sortBy));
  };
  render() {
    const { loading, identities, count, t } = this.props;

    if (loading) {
      return <Spinner loading={loading} />;
    }
    const headers = [
      t('admin.identities.fields.display_name'),
      t('admin.identities.fields.email'),
      t('admin.identities.fields.phone'),
      t('admin.identities.fields.vault'),
      t('admin.identities.fields.status'),
      t('admin.identities.fields.registration_date'),
      '',
    ];
    let identityList;
    if (identities?.length > 0) {
      identityList = this.getIdentityFields(identities);
    }
    return (
      <div className="relative p-5 ">
        <div className="my-2 mb-5">
          <span className="font-24 font-bold">
            {t('admin.identities.title')}
          </span>
        </div>
        <AddFilter
          onSearch={(value) => {
            this.filterIdentity(value);
          }}
          sortBy={(value) => {
            this.sortIdentity(value);
          }}
          searchValue={this.state.searchValue}
          sortValue={this.state.sortValue}
          reset={() => {
            this.state.pageNo = 1;
            this.state.searchValue = '';
            this.state.sortValue = 'default';
            this.getIdentities(this.state.pageNo, this.state.itemsPerPage);
          }}
        />
        {identities?.length > 0 ? (
          <div className="global-wrapper-div mx-auto w-full">
            <div className="block w-full overflow-x-auto">
              <table className="items-center w-full bg-transparent border-collapse table-borderd">
                <thead>
                  <tr>
                    {headers.map((prop, uniqueKey) => (
                      <th
                        key={uniqueKey}
                        className="align-middle border-l-0 border-r-0 whitespace-nowrap p-4"
                      >
                        <div className="flex items-center">
                          <span
                            className={
                              'weight-600 font-14 letter-spacing line-height24 text-gray'
                            }
                          >
                            {prop}
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {identityList.map((items, tableKey) => {
                    return (
                      <tr className="border-r-0 border-l-0" key={items?.id}>
                        <Column>{items.username}</Column>
                        <Column>{items.email[0] || items.email}</Column>
                        <Column>{items.phone}</Column>
                        <Column>
                          <label className="text-sm font-medium text-gray-700 flex mb-3">
                            <input
                              type="checkbox"
                              name="smsNotification"
                              className="mr-3 align-text-bottom focus:radio-checked h-4 w-4 text-primary border-gray-300"
                              onChange={() => {}}
                              // value={Boolean(true)}
                              checked
                              readOnly
                            />
                          </label>
                        </Column>
                        <Column>{items.status}</Column>
                        <Column>{items.registration_date}</Column>
                        <Column>
                          <button
                            onClick={() => this.showPopover(tableKey)}
                            className="relative h-10 w-10 p-4 rounded-full flex justify-center items-center text-center  hover:bg-slate-200  mr-4 outline-none focus:outline-none mb-1 ease-linear transition-all duration-150"
                          >
                            {/* <Link
                              to={{
                                pathname: `${IDENTITIES_INFORMATION}/${items?.id}`,
                              }}
                              className={
                                'weight-400 font-16 letter-spacing line-height24 text-black'
                              }
                            > */}
                            <i className="fas fa-ellipsis-v"></i>
                            {/* </Link> */}
                            <div
                              className="popover shadow flex-col justify-center text-left  text-gray-500"
                              id={items.id}
                              ref={(e) => this.setPopoverRef(e, tableKey)}
                            >
                              <Link
                                to={{
                                  pathname: `${IDENTITIES_INFORMATION}/${items?.id}`,
                                }}
                              >
                                <div className="hover:bg-slate-100 p-2">
                                  <i className="fas fa-search px-2"></i>
                                  <span>{t('common.buttons.view')}</span>
                                </div>
                              </Link>
                              <div
                                className="hover:bg-slate-100 p-2"
                                onClick={() =>
                                  this.openConfirmationPopUp(items.id)
                                }
                              >
                                <i className="fas fa-trash-alt px-2"></i>
                                <span>{t('common.buttons.delete')}</span>
                               
                              </div>
                            </div>
                          </button>
                        </Column>
                      </tr>
                    );
                  })}

                  <ConformationPopup
                    open={this.state.showConfirmationPopUp}
                    closePopup={this.closeConfirmationPopUp}
                    identityId={this.state.identityId}
                    isOwnAccount={this.state.isOwnAccount}
                  />
                </tbody>
              </table>
              <Pager
                initialPageNo={this.state.pageNo}
                itemsPerPage={this.state.itemsPerPage}
                itemsCount={count}
                onPageChange={(pageNo, itemsPerPage) => {
                  this.setState({ pageNo: pageNo, itemsPerPage: itemsPerPage });
                }}
              />
            </div>
          </div>
        ) : (
          <div className="text-center pt-20">
            <i className="fas fa-search px-2"></i>
            <h3>No Data Found</h3>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  loading: state.oryAdmin.loading,
  identities: state.oryAdmin.identities,
  count: state.oryAdmin.count,
});

export default withTranslation()(
  withRouter(connect(mapStateToProps)(IdentitiesList))
);
